import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { Page52Service } from './page52.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { LoginService } from '../../authentication/login/login.service';
import { GuidanceNoteFormComponent } from '../guidance-note-form/guidance-note-form.component';
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { PageHeadings, Pages } from '../application';
import { DateConvertor } from 'src/app/shared/date-convertor';
import { environment } from '../../../environments/environment';
import { PaymentFormComponent } from '../payment-form/payment-form.component';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../shared/resource.service';
import { PageReference } from '../../shared/constants';
declare var saveAs: any;

@Component({
  selector: 'app-page52',
  templateUrl: './page52.component.html'
})
export class Page52Component implements OnInit {
  @ViewChild('page52Form') form! :any;
  applicationId!: string;
  heading: string = PageHeadings.page52;
  pageNo!: number;
  pages: number[] = Pages;
  data: any = {};
  payments: any[] = [];
  paymentAmounts: any[] = [];
  isSaving: boolean = false;
  dateConvertor!: DateConvertor;
  url: string = '/application/page/';
  showMessage: boolean = false;
  worldPay: any = {};
  currentUser: any = JSON.parse(localStorage.getItem('currentUser')!);
  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private Page52Service: Page52Service,
    private loginService: LoginService,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private resourceService: ResourceService,
    private confirmationDialogService: ConfirmationDialogService,
    private config: NgbDatepickerConfig) {
    config.minDate = { year: 1900, month: 1, day: 1 };
    config.maxDate = { year: 2099, month: 12, day: 31 };
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.showMessage = currentUser && currentUser.showMessage ? true : false;
    this.worldPay = environment.worldPay;
  }

  ngOnInit() {
    let that = this;
    that.showMessage = that.currentUser && that.currentUser.showMessage ? true : false;
    that.pageNo = parseInt(that.route.snapshot.url[0].path);
    that.applicationId = that.route.snapshot.data['applicationId'];
    that.setBreadcrumbs();
    that.Page52Service
      .getData(that.applicationId)
      .subscribe((res: any) => {
        that.data = res;
        that.payments = that.data.LookupPaymentTypeApplications;
        that.paymentAmounts = that.data.LookupPaymentAmounts;
      });
  }

  OnSignin() {
    let that = this;
    let today = new Date();
    that.data.Signature = that.data.FirstName + ' ' + that.data.LastName;
    that.data.SignatureDate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
  }

  onPageChange(event: any, dirty: any) {
    let page = event.target.value;
    event.srcElement.blur();
    event.preventDefault();
    let that = this;
    if (dirty) {
      that.confirm(page);
    }
    else {
      that.router.navigate([that.url + page]);
    }
  }

  goToPrevious(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo - 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo - 1)]);
    }
  }

  confirmSubmission() {
    let that = this;
    that.confirmationDialogService
      .confirm('Warning - Message',
        'Please confirm you want to submit your application.',
        'Yes',
        'No')
      .then((confirmed) => {
        if (confirmed === true) {
          if (that.data.vw_ApplicationCompleteCheckPt3.IsComplete) {
            that.data.ApplicationPaymentTypeId = 2706;
            that.isSaving = true;
            that.Page52Service
              .checkSp(that.applicationId)
              .subscribe((res: any) => {
                //checking sp and showing error from sp.
                if (res.IsSuccess === false) {
                  that.isSaving = false;
                  //that.toastr.success(res.OutputMessage, 'Error!',
                  //  {
                  //  positionClass: 'toast-top-full-width',
                  //  disableTimeOut: true,
                  //  closeButton: true
                  //});
                  that.confirmationDialogService
                    .confirm('Please Confirm', res.OutputMessage, 'Submit Application', 'Return to Application')
                    .then((confirmed) => {
                      if (confirmed === false) {
                        return false;
                      } else {
                        that.data.IsComplete = that.form.valid;
                        that.data.PageNo = that.pageNo;
                        that.Page52Service
                          .updateData(that.applicationId, that.data)
                          .subscribe((res: any) => {
                            that.isSaving = false;
                            that.toastr.success('Data has been added successfully!', 'Success!');
                            that.data = {};
                            that.Page52Service
                              .getData(that.applicationId)
                              .subscribe((data: any) => {
                                that.data = data;
                                that.payments = that.data.LookupPaymentTypeApplications;
                                that.paymentAmounts = that.data.LookupPaymentAmounts;
                                that.submitApplication();
                              });
                          });
                      }
                    });
                } else {
                  that.data.IsComplete = that.form.valid;
                  that.data.PageNo = that.pageNo;
                  that.Page52Service
                    .updateData(that.applicationId, that.data)
                    .subscribe((res: any) => {
                      that.isSaving = false;
                      that.toastr.success('Data has been added successfully!', 'Success!');
                      that.data = {};
                      that.Page52Service
                        .getData(that.applicationId)
                        .subscribe((data: any) => {
                          that.data = data;
                          that.payments = that.data.LookupPaymentTypeApplications;
                          that.paymentAmounts = that.data.LookupPaymentAmounts;
                          that.submitApplication();
                        });
                    });
                }
              });

          }
          else {
            that.failure();
          }
        }
      });
  }

  submitApplication() {
    let that = this;
    that.Page52Service
      .submitAppplication(that.applicationId)
      .subscribe((res: any) => {
        that.paymentForm();
      }, error => {
        that.isSaving = false;
      });
  }

  paymentForm() {
    let that = this;
    const modalRef = that.modalService.open(PaymentFormComponent, { size: 'lg', backdrop: 'static', keyboard: false});
    modalRef.componentInstance.applicationPaymentTypeId = that.data.ApplicationPaymentTypeId;
    modalRef.componentInstance.paymentAmount = that.data.PaymentAmount;
    modalRef.result.then((result) => {
      if (result === 'Pay click') {
        that.pay();
      }
      that.loginService.logout();
    });
    that.deleteUser();
  }

  pay() {
    let that = this;
    let param = {
      testMode: that.worldPay.testMode,
      instId: that.worldPay.instId,
      cartId: that.applicationId,
      amount: that.data.PaymentAmount,
      currency: that.worldPay.currency
    };
    that.Page52Service.postPayment(param, that.worldPay.url);
  }

  failure() {
    let that = this;
    that.updateLocalStorage(true);
    that.confirmationDialogService
      .confirm('Warning - Message',
        '<p>Page(s) ' + that.data.vw_ApplicationCompleteCheckPt5.PageNoList + ' are incomplete.</p><p>Please review and complete all required fields.</p>',
        'OK',
        '')
      .then((confirmed) => { });
  }

  confirm(page: any) {
    let that = this;
    that.confirmationDialogService
      .confirm('Warning - Unsaved changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Leave Page')
      .then((confirmed) => {
        if (confirmed === true) {
          that.router.navigate([that.url + page]);
        }
        else {
          that.pageNo = parseInt(that.route.snapshot.url[0].path);
        }
      });
  }

  updateLocalStorage(submiited: boolean) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    currentUser.showMessage = submiited;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  openGuidanceNotes() {
    let that = this;
    const modalRef = this.modalService.open(GuidanceNoteFormComponent, { size: 'lg' });
    modalRef.componentInstance.groupRefId = 20;
    modalRef.componentInstance.groupRefId = PageReference.Page52;
  }
  downloadApplicationForm() {
      let that = this;
      that.resourceService
          .get("applications/DownloadApplicationForm", that.applicationId)
          .subscribe(response => {
              var blob = this.resourceService.b64toBlob(response.Content, response.ContentType);
              saveAs(blob, response.FileName);
              that.toastr.success('File has been created successfully!', 'Success!');
          },
          (error: any) => {
              that.toastr.error('Something went wrong! Please try again.', 'Oops!');
          });
  }

  reloadForm() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  deleteUser() {
    let that = this;
    that.Page52Service
      .logout()
      .subscribe((result) => {
        localStorage.removeItem('currentUser');
      });
  }

  setBreadcrumbs() {
    let that = this;
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: that.heading, url: '' });
    that.breadcrumbService.set(breadcrumbs);
  }
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { Page14Service } from './page14.service';
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { GuidanceNoteFormComponent } from '../guidance-note-form/guidance-note-form.component';
import { ApplicatonPracticeFormComponent } from '../application-practice-form/application-practice-form.component';
import { PageHeadings, Pages } from '../application';
import { ResourceService } from '../../shared/resource.service';
import { PageReference } from '../../shared/constants';
declare var saveAs: any;

@Component({
  selector: 'application-page-14',
  templateUrl: './page14.html'
})
export class Page14Component implements OnInit {
  @ViewChild('page14Form') form! :any;
  applicationId!: string;
  heading: string = PageHeadings.page14;
  pageNo!: number;
  pages: number[] = Pages;
  data: any = {};
  isShow: any = [];
  url: string = '/application/page/';
  roles: any[] = [];
  isSaving: boolean = false;
  showMessage: boolean = false;
  dlgOptions: NgbModalOptions = {
      keyboard: false,
      size: 'lg',
      backdrop: 'static'};
  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private page14Service: Page14Service,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private resourceService: ResourceService,
    private confirmationDialogService: ConfirmationDialogService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.showMessage = currentUser && currentUser.showMessage ? true : false;
  }

  ngOnInit() {
    let that = this;
    that.pageNo = parseInt(that.route.snapshot.url[0].path);
    that.applicationId = that.route.snapshot.data['applicationId'];
    that.setBreadcrumbs();
    that.page14Service
      .getData(that.applicationId)
      .subscribe((res: any) => {
        that.data = res;
        that.isShow = res.ApplicationPractices.filter((isShow: { IsActive: any; }) => isShow.IsActive == 0);
      });
  }

  save() {
    let that = this;
    that.isSaving = true;
    that.data.IsComplete = this.form.valid;
    that.data.PageNo = that.pageNo;
    that.page14Service
      .updateData(that.applicationId, that.data)
      .subscribe((res: any) => {
        that.isSaving = false;
        that.toastr.success('Data has been added successfully!', 'Success!');
        that.reloadForm();
      }, error => {
        that.isSaving = false;
      });
  }

  onActiveChange($event: boolean, applicationPracticeId: string, index: number) {
    let that = this;
    if ($event == false) {
      that.confirmationDialogService
        .confirm('Warning',
          '<p>Are you sure you want to remove this case, all information will be deleted from this case.</p>',
          'Confirm', 'Cancel')
        .then((confirmed) => {
          if (confirmed === true) {
            that.page14Service
              .callSp(applicationPracticeId, $event)
              .subscribe((res: any) => {
                if (res == true) {
                  that.toastr.success('Data has been updated successfully!', 'Success!');
                  that.data = {};
                  that.reloadForm();
                }
              });
          }
          else {
            that.data.ApplicationPractices[index].IsActive = true;
          }
        });
    }
    else {
      that.page14Service
        .callSp(applicationPracticeId, $event)
        .subscribe((res: any) => {
          if (res == true) {
            that.toastr.success('Data has been updated successfully!', 'Success!');
            that.data = {};
            that.reloadForm();
          }
        });
    }
    
  }

  onPageChange(event: any, dirty: any) {
    let page = event.target.value;
    event.srcElement.blur();
    event.preventDefault();
    let that = this;
    if (dirty) {
      that.confirm(page);
    }
    else {
      that.router.navigate([that.url + page]);
    }
  }

  confirm(page: any) {
    let that = this;
    that.confirmationDialogService
      .confirm('Warning - Unsaved changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Leave Page')
      .then((confirmed) => {
        if (confirmed === true) {
          that.router.navigate([that.url + page]);
        }
        else {
          that.pageNo = parseInt(that.route.snapshot.url[0].path);
        }
      });
  }

  goToPrevious(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo - 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo - 1)]);
    }
  }

  goToNext(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo + 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo + 1)]);
    }
  }

  openApplicationPractice(applicationPracticeId: string) {
    let that = this;
    const modalRef = that.modalService.open(ApplicatonPracticeFormComponent, that.dlgOptions);
    modalRef.componentInstance.applicationPracticeId = applicationPracticeId;
    modalRef.result.then((result) => {
      if (result === 'Save click') {
        that.reloadForm();
      }
    });
  }

  openGuidanceNotes() {
    let that = this;
    const modalRef = that.modalService.open(GuidanceNoteFormComponent, that.dlgOptions);
    modalRef.componentInstance.groupRefId = PageReference.Page14;
  }

  downloadApplicationForm() {
      let that = this;
      that.resourceService
          .get("applications/DownloadApplicationForm", that.applicationId)
          .subscribe(response => {
              var blob = this.resourceService.b64toBlob(response.Content, response.ContentType);
              saveAs(blob, response.FileName);
              that.toastr.success('File has been created successfully!', 'Success!');
          },
          (error: any) => {
              that.toastr.error('Something went wrong! Please try again.', 'Oops!');
          });
  }

  reloadForm() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  setBreadcrumbs() {
    let that = this;
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: that.heading, url: '' });
    that.breadcrumbService.set(breadcrumbs);
  }
}

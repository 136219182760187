import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class Page16Service {
  constructor(private http: HttpClient, ) {
  }

  //get Data
  getData(id: string, orderNo: number, assessorReference: string, assessorTypeId: number): Observable<any> {
    return this
      .http
      .get('applications/assessors_assessor/' + id + '?orderNo=' + orderNo + '&assessorReference=' + assessorReference + '&assessorTypeId=' + assessorTypeId)
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }

  //get Data
  getDataById(id: number): Observable<any> {
    return this
      .http
      .get('applications/assessors_assessorbyid/' + id)
      .flatMap((res: any) => {
        return Observable.of(res)
        .catch(error => error);
      })
  }

  //Call sp
  callSp(id: number, isAdditionalDisplay: boolean): Observable<any> {
    return this
      .http
      .post('applications/assessors_assessorisadditionaldisplay/' + id + '?isAdditionalDisplay=' + isAdditionalDisplay, null)
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }

  //get data
  getJudge(id: string, assessorTypeId: number, nominatedId: number, applicationPracticeId: number): Observable<any> {
    return this
      .http
      .get('applications/assessors_assessorjudge/' + id + '/' + assessorTypeId + '?nominatedId=' + nominatedId + '&applicationPracticeId=' + applicationPracticeId)
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }

  //update Category
  updateData(id: string, data: any): Observable<any> {
    return this
      .http
      .put('applications/assessors_assessor/' + id, data)
      .flatMap((res: any) => {
        return Observable.of(res);
      });
  }
} 

<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page13Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body form-card">

      <div class="row">
        <div class="col-md-12">
          <div class="form-check mb-5 tooltip-right">
            <label>
              Is there any other further information you wish to provide about your personal circumstances which is relevant to your application?<span class="required">&nbsp;*</span>
            </label>
            <pb-switch class="custom-switch mt-2" required [(ngModel)]="data.IsOtherNarrative" #IsOtherNarrative="ngModel" name="IsOtherNarrative" id="IsOtherNarrative" labelOn="Yes" labelOff="No">
            </pb-switch>
            <div class="invalid-tooltip float-right" *ngIf="IsOtherNarrative?.errors?.required && showMessage">This field is required.</div>
          </div>

          <div class="form-group mb-0">
            <small class="mb-2">
              (This section should not be used to provide additional information relating to your self-assessment or Summary Description of Practice)
            </small>
          </div>
        </div>
      </div>

      <div class="form-group textarea-form" *ngIf="data.IsOtherNarrative">
        <textarea [required]="data.IsOtherNarrative" maxlength="2750" autosize [minRows]="15" class="form-control input-md mb-0" #OtherNarrative="ngModel" id="OtherNarrative" name="OtherNarrative" type="text" [(ngModel)]="data.OtherNarrative"  [ngClass]="{'is-invalid': data.IsOtherNarrative?.invalid && showMessage}"></textarea>
        <span class="float-right label label-default" id="counter">{{data.OtherNarrative ? data.OtherNarrative.length : 0}} / 2750</span>
        <div class="invalid-tooltip textarea-tooltip" *ngIf="OtherNarrative?.errors?.required && showMessage">This field is required.</div>
        <small class="label-inline">
          Please note there is a limit of 2750 characters for this box.
        </small>
      </div>

    </div>
  </div>

  <div class="card-footer form-card">
    <div class="row">
      <div class="col-md-12 pl-0">
        <p class="required">* denotes required fields </p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-sm-6 col-12">
        <div class="form-group">
          <div class="row">
            <div class="col-md-2">
              <label for="ID">ID:</label>
            </div>

            <div class="col-md-10">
              <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-12">
        <div class="form-group">
          <div class="row">
            <div class="col-md-3">
              <label for="Surname">Surname:</label>
            </div>

            <div class="col-md-9">
              <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName"  [maxLength]="50">
            </div>
          </div>
        </div>
      </div>

      <div class="form-group col-auto pr-0 cardPagin">
        <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page13Form.dirty)">
          <i class="fas fa-chevron-left" style="font-size: 16px;"></i>
        </button>
        <label class="pagination-label" for="Surname">Page</label>
        <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page13Form.dirty)">
          <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
        </select>
        <p>
          of
          <span>{{pages.length}}</span>
        </p>
        <button type="button" (click)="goToNext(page13Form.dirty)" class="btn btn-primary float-left">
          <i class="fas fa-chevron-right" style="font-size: 16px;"></i>
        </button>
      </div>

      <div class="col-auto text-right ml-auto pl-0">
        <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
        <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
        <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
      </div>
    </div>
  </div>
</form>

import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { ApplicatonPracticeFormService } from "./application-practice-form.service";
import { LookupService } from "../../shared/lookup.service";

@Component({
  selector: 'app-guidance-note-form',
  templateUrl: './application-practice-form.component.html'
})
export class ApplicatonPracticeFormComponent implements OnInit {
  data: any = {};
  roles: any[] = [];
  isSaving: boolean = false;
  showMessage: boolean = false;
  @Input() applicationPracticeId!: string;

  constructor(public activeModal: NgbActiveModal,
    public toastr: ToastrService,
    public applicatonPracticeFormService: ApplicatonPracticeFormService,
    public lookupService: LookupService) {
  }

  ngOnInit() {
    let that = this;
    let data = that.applicatonPracticeFormService.getData(that.applicationPracticeId);
    let roles = that.lookupService.getRoles();
    forkJoin([data, roles])
      .subscribe(results => {
        that.data = results[0];
        that.roles = results[1];
      });
  }

  save() {
    let that = this;
    that.isSaving = true;
    that.applicatonPracticeFormService
      .updateData(that.applicationPracticeId, that.data)
      .subscribe((res: any) => {
        that.isSaving = false;
        that.toastr.success('Data has been added successfully!', 'Success!');
        that.activeModal.close('Save click');
      });
  }
  close() {
      let that = this;
      that.activeModal.close();
  }
}

<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page17Form="ngForm" (ngSubmit)="save()">
  <!--First Form-->
  <div class="card custom-card alert-bg">
    <div class="card-body form-card">
      <div *ngIf="data.ApplicationPractice && data.ApplicationAssessor">
        <h2 class="float-left">{{data.vw_ApplicationCaseReference.CaseReference}}</h2>

        <div class="table-responsive-md custom-table">
          <table *ngIf="data.ApplicationPractice" class="table table-bordered primary-table">
            <colgroup>
              <col>
              <col style="width: 50%;">
              <col style="width: 30%">
              <col style="width: 10%;">
              <col style="width: 10%;">
            </colgroup>
            <thead>
              <tr>
                <th rowspan="2">#</th>
                <th rowspan="2">Name of case</th>
                <th rowspan="2">Role as Advocate</th>
                <th colspan="2" scope="colgroup" class="text-center">Dates (mm/yy)</th>
                <th rowspan="2" class="text-center">Are you naming a <br />Client(s) for this case?</th>
              </tr>
              <tr>
                <th scope="colgroup" class="text-center">Start</th>
                <th scope="colgroup" class="text-center">End</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{data.ApplicationPractice.OrderNo}}</td>
                <td>{{data.ApplicationPractice.NameOfCase}}</td>
                <td>{{data.ApplicationPractice.Role}}</td>
                <td class="text-center">{{data.ApplicationPractice.StartDate}}</td>
                <td class="text-center">{{data.ApplicationPractice.EndDate}}</td>
                <td class="text-center">
                  <pb-switch  class="required-denote custom-switch" [(ngModel)]="data.ApplicationPractice.IsAssessorActive" name="IsAssessorActive" id="IsAssessorActive" labelOn="Yes" labelOff="No"></pb-switch>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mb-15">
          <label class="mb-0">Brief description of case</label>
          <textarea autosize [minRows]="4" tabindex="1" readonly id="BriefOfCase" name="BriefOfCase" [(ngModel)]="data.ApplicationPractice.BriefOfCase" class="form-control"></textarea>
        </div>

        <p *ngIf="data.ApplicationPractice.IsAssessorActive" class="control-label mb-0">
          Please very briefly describe your role in the case, give approximate dates of the main hearing(s), and state how much of your oral advocacy the assessor has seen.<span>&nbsp;*</span>
        </p>

        <p *ngIf="!data.ApplicationPractice.IsAssessorActive" class="control-label mb-0">
          Please provide a reason for marking this assessor as not applicable<span>&nbsp;*</span>
        </p>

        <div class="form-group textarea-form">
          <textarea maxlength="1000" autosize [minRows]="12" tabindex="2" class="form-control input-md mb-0" required #CaseDetails="ngModel" id="CaseDetails" name="CaseDetails" type="text" [(ngModel)]="data.ApplicationAssessor.CaseDetails" [ngClass]="{'is-invalid': CaseDetails?.invalid && showMessage}"></textarea>
          <span class="float-right label label-default" id="counter">{{data.ApplicationAssessor.CaseDetails ? data.ApplicationAssessor.CaseDetails.length : 0}} / 1000</span>
          <div class="invalid-tooltip textarea-tooltip" *ngIf="CaseDetails?.errors?.required && showMessage">This field is required.</div>
          <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
        </div>

        <div *ngIf="data.ApplicationPractice.IsAssessorActive">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="label-inline control-label">Use a Client that you've previously entered</label>
                  </div>

                  <div class="col-md-6">
                    <ng-select [items]="data.vw_LookupAssessorForms" tabindex="3" id="ApplicationAssessorId" name="ApplicationAssessorId" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="applicationAssessorId" (change)="onApplicationAssessorChange()">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="label-inline control-label">Title<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-md-6">
                    <ng-select [items]="titles" id="TitleId" name="TitleId" tabindex="4" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.ApplicationAssessor.TitleId" #TitleId="ngModel" required [ngClass]="{'is-invalid': TitleId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="data.ApplicationAssessor.TitleId == 493">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label" for="TitleOther">Title Other<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-md-6">
                    <input class="form-control input-md" id="TitleOther" tabindex="5" name="TitleOther" [maxLength]="100" type="text" [(ngModel)]="data.ApplicationAssessor.TitleOther" #TitleOther="ngModel" required [ngClass]="{'is-invalid': TitleOther?.invalid && showMessage}">
                    <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label">Surname<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-md-6">
                    <input type="text" class="form-control" id="LastName" tabindex="6" name="LastName" [maxLength]="50" [(ngModel)]="data.ApplicationAssessor.LastName" #LastName="ngModel" required [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label">Forename<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-md-6">
                    <input type="text" class="form-control" id="FirstName" tabindex="7" name="FirstName" [maxLength]="100" [(ngModel)]="data.ApplicationAssessor.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label">Phone<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-md-6">
                    <input type="text" class="form-control" id="Telephone" tabindex="8" name="Telephone" [maxLength]="50" [(ngModel)]="data.ApplicationAssessor.Telephone" #Telephone="ngModel" required [ngClass]="{'is-invalid': Telephone?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Telephone?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label label-inline">Email<span>&nbsp;*</span></label>
                  </div>
                  <div class="col-md-6">
                    <input type="email" email class="form-control" tabindex="9" #Email="ngModel" id="Email" name="Email" [maxLength]="500" [(ngModel)]="data.ApplicationAssessor.Email" required [ngClass]="{'is-invalid': Email?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.email">Email is not a valid format</div>
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-xl-3 col-lg-5 pr-0 lg-w-33">
                    <label class="control-label">Full professional address<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-xl-9 col-lg-7 lg-w-67">
                    <textarea autosize [minRows]="3" class="form-control mh-208" id="Address1" tabindex="11" [maxLength]="200" name="Address1" [(ngModel)]="data.ApplicationAssessor.Address1" #Address1="ngModel" required [ngClass]="{'is-invalid': Address1?.invalid && showMessage}"></textarea>
                    <div class="invalid-tooltip" *ngIf="Address1?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>

              <div class="col-xl-9 offset-xl-3 lg-ml-0 lg-w-100">
                <div class="form-group">
                  <div class="row">
                    <div class="col-xl-4 col-lg-5 col-md-4">
                      <label class="control-label">Post code<span>&nbsp;*</span></label>
                    </div>

                    <div class="col-md-8 col-lg-7 offset-xl-2 col-xl-6">
                      <input type="text" class="form-control" id="Postcode" name="Postcode" tabindex="12" [maxLength]="20" [(ngModel)]="data.ApplicationAssessor.Postcode" #Postcode="ngModel" required [ngClass]="{'is-invalid': Postcode?.invalid && showMessage}" />
                      <div class="invalid-tooltip" *ngIf="Postcode?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-5 offset-xl-0 offset-lg-5 offset-md-4 col-xl-4 pr-0 mb-15">
                    <div class="form-check mb-0">
                      <label class="control-label">
                        UK<span>&nbsp;*</span>
                      </label>
                      <pb-switch class="required-denote custom-switch" id="IsUK" name="IsUK" [(ngModel)]="data.ApplicationAssessor.IsUK" labelOn="Yes" labelOff="No" #IsUK="ngModel" required [ngClass]="{'is-invalid': IsUK?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsUK?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12 col-xl-8" *ngIf="data.ApplicationAssessor.IsUK === false">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4 col-lg-5 col-xl-3">
                          <label class="control-label mb-0">
                            Other<small class="mb-0">(Please state)</small><span>&nbsp;*</span>
                          </label>
                        </div>
                        <div class="col-md-8 col-lg-7 col-xl-9">
                          <input type="text" class="form-control" id="Country" tabindex="14" name="Country" [maxLength]="50" [(ngModel)]="data.ApplicationAssessor.Country" #Country="ngModel" [required]="!data.ApplicationAssessor.IsUK" [ngClass]="{'is-invalid': Country?.invalid && showMessage}" />
                          <div class="invalid-tooltip" *ngIf="Country?.errors?.required && showMessage">This field is required.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label mb-0">
                      Is this assessor one of your two nominated Client assessors?<span>&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-md-6">
                    <ng-select [items]="nominated" id="NominatedId" name="NominatedId" tabindex="10" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.ApplicationAssessor.NominatedId" (change)="onNominatedChange()" #NominatedId="ngModel" required [ngClass]="{'is-invalid': NominatedId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="NominatedId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-check float-right mb-0">
                <label class="control-label">
                  If you would like to add an additional Client assessor for this Case {{data.ApplicationPractice.OrderNo}}, please select Yes?<span>&nbsp;*</span>
                </label>
                <pb-switch   class="required-denote custom-switch" (change)="onAdditionalDisplayChange($event, data.ApplicationAssessor.ApplicationAssessorId)" id="IsAdditionalDisplay" name="IsAdditionalDisplay" [(ngModel)]="data.ApplicationAssessor.IsAdditionalDisplay" labelOn="Yes" labelOff="No" #IsAdditionalDisplay="ngModel" required [ngClass]="{'is-invalid': IsAdditionalDisplay?.invalid && showMessage}">
                </pb-switch>
                <div class="invalid-tooltip" *ngIf="IsAdditionalDisplay?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--First Form-->
  <!--Second Form-->
  <div  id="secondForm" class="card custom-card alert-bg" *ngIf="data.ApplicationPractice2 && data.ApplicationAssessor2 && data.ApplicationAssessor.IsAdditionalDisplay">
    <div class="card-body form-card">
      <h2 class="float-left">{{data.vw_ApplicationCaseReference2.CaseReference}}</h2>

      <div class="table-responsive-md custom-table">
        <table *ngIf="data.ApplicationPractice2" class="table table-bordered primary-table">
          <colgroup>
            <col>
            <col style="width: 50%;">
            <col style="width: 30%">
            <col style="width: 10%;">
            <col style="width: 10%;">
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">#</th>
              <th rowspan="2">Name of case</th>
              <th rowspan="2">Role as Advocate</th>
              <th colspan="2" scope="colgroup" class="text-center">Dates (mm/yy)</th>
              <th rowspan="2" class="text-center">Are you naming a <br />Client(s) for this case?</th>
            </tr>
            <tr>
              <th scope="colgroup" class="text-center">Start</th>
              <th scope="colgroup" class="text-center">End</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{data.ApplicationPractice2.OrderNo}}</td>
              <td>{{data.ApplicationPractice2.NameOfCase}}</td>
              <td>{{data.ApplicationPractice2.Role}}</td>
              <td class="text-center">{{data.ApplicationPractice2.StartDate}}</td>
              <td class="text-center">{{data.ApplicationPractice2.EndDate}}</td>
              <td class="text-center">
                <pb-switch  class="required-denote custom-switch" [(ngModel)]="data.ApplicationPractice2.IsAssessorActive" name="IsAssessorActive2" id="IsAssessorActive2" labelOn="Yes" labelOff="No"></pb-switch>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mb-15">
        <label class="mb-0">Brief description of case</label>
        <textarea autosize [minRows]="4" readonly id="BriefOfCase2" tabindex="16" name="BriefOfCase2" [(ngModel)]="data.ApplicationPractice2.BriefOfCase" class="form-control"></textarea>
      </div>

      <p *ngIf="data.ApplicationPractice2.IsAssessorActive" class="control-label mb-0">
        Please very briefly describe your role in the case, give approximate dates of the main hearing(s), and state how much of your oral advocacy the assessor has seen.<span>&nbsp;*</span>
      </p>

      <p *ngIf="!data.ApplicationPractice2.IsAssessorActive" class="control-label mb-0">
        Please provide a reason for marking this assessor as not applicable<span>&nbsp;*</span>
      </p>

      <div class="form-group textarea-form">
        <textarea maxlength="1000" autosize [minRows]="12" tabindex="17" class="form-control mb-0 input-md mb-0" required #CaseDetails2="ngModel" id="CaseDetails2" name="CaseDetails2" type="text" [(ngModel)]="data.ApplicationAssessor2.CaseDetails" [ngClass]="{'is-invalid': CaseDetails2?.invalid && showMessage}"></textarea>
        <div class="invalid-tooltip textarea-tooltip" *ngIf="CaseDetails2?.errors?.required && showMessage">This field is required.</div>
        <span class="float-right label label-default" id="counter">{{data.ApplicationAssessor2.CaseDetails ? data.ApplicationAssessor2.CaseDetails.length : 0}} / 1000</span>
        <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
      </div>

      <div *ngIf="data.ApplicationPractice2.IsAssessorActive">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label class="label-inline control-label">Use a Client that you've previously entered</label>
                </div>

                <div class="col-md-6">
                  <ng-select [items]="data.vw_LookupAssessorForms" tabindex="18" id="ApplicationAssessorId2" name="ApplicationAssessorId2" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="applicationAssessorId2" (change)="onApplicationAssessor2Change()">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label class="label-inline control-label">Title<span>&nbsp;*</span></label>
                </div>

                <div class="col-md-6">
                  <ng-select [items]="titles" id="TitleId2" tabindex="19" name="TitleId2" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.ApplicationAssessor2.TitleId" #TitleId2="ngModel" required [ngClass]="{'is-invalid': TitleId2?.invalid && showMessage}">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="TitleId2?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="data.ApplicationAssessor2.TitleId == 493">
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label" for="TitleOther2">Title Other<span>&nbsp;*</span></label>
                </div>

                <div class="col-md-6">
                  <input class="form-control input-md" id="TitleOther2" tabindex="20" name="TitleOther2" [maxLength]="100" type="text" [(ngModel)]="data.ApplicationAssessor2.TitleOther" #TitleOther2="ngModel" required [ngClass]="{'is-invalid': TitleOther2?.invalid && showMessage}">
                  <div class="invalid-tooltip" *ngIf="TitleOther2?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label">Surname<span>&nbsp;*</span></label>
                </div>

                <div class="col-md-6">
                  <input type="text" class="form-control" id="LastName2" tabindex="21" name="LastName2" [maxLength]="50" [(ngModel)]="data.ApplicationAssessor2.LastName" #LastName2="ngModel" required [ngClass]="{'is-invalid': LastName2?.invalid && showMessage}" />
                  <div class="invalid-tooltip" *ngIf="LastName2?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label">Forename<span>&nbsp;*</span></label>
                </div>

                <div class="col-md-6">
                  <input type="text" class="form-control" id="FirstName2" tabindex="22" name="FirstName2" [maxLength]="100" [(ngModel)]="data.ApplicationAssessor2.FirstName" #FirstName2="ngModel" required [ngClass]="{'is-invalid': FirstName2?.invalid && showMessage}" />
                  <div class="invalid-tooltip" *ngIf="FirstName2?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label">Phone<span>&nbsp;*</span></label>
                </div>

                <div class="col-md-6">
                  <input type="text" class="form-control" id="Telephone2" tabindex="23" name="Telephone2" [maxLength]="50" [(ngModel)]="data.ApplicationAssessor2.Telephone" #Telephone2="ngModel" required [ngClass]="{'is-invalid': Telephone2?.invalid && showMessage}" />
                  <div class="invalid-tooltip" *ngIf="Telephone2?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label label-inline">Email<span>&nbsp;*</span></label>
                </div>
                <div class="col-md-6">
                  <input type="email" email class="form-control" #Email2="ngModel" tabindex="24" id="Email2" name="Email2" [maxLength]="500" [(ngModel)]="data.ApplicationAssessor2.Email" required [ngClass]="{'is-invalid': Email2?.invalid && showMessage}" />
                  <div class="invalid-tooltip" *ngIf="Email2?.errors?.email">Email is not a valid format</div>
                  <div class="invalid-tooltip" *ngIf="Email2?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label mb-0">
                    Is this assessor one of your two nominated Client assessors?<span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-md-6">
                  <ng-select [items]="nominated" id="NominatedId2" name="NominatedId2" tabindex="25" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.ApplicationAssessor2.NominatedId" (change)="onNominated2Change()" #NominatedId2="ngModel" required [ngClass]="{'is-invalid': NominatedId2?.invalid && showMessage}">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="NominatedId2?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <div class="row">
                <div class="col-xl-3 col-lg-5 pr-0 lg-w-33">
                  <label class="control-label">Full professional address<span>&nbsp;*</span></label>
                </div>

                <div class="col-xl-9 col-lg-7 lg-w-67">
                  <textarea autosize [minRows]="3" class="form-control mh-208" id="Address12" tabindex="26" name="Address12" [maxLength]="200" [(ngModel)]="data.ApplicationAssessor2.Address1" #Address12="ngModel" required [ngClass]="{'is-invalid': Address12?.invalid && showMessage}"></textarea>
                  <div class="invalid-tooltip" *ngIf="Address12?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-xl-9 offset-xl-3 lg-ml-0 lg-w-100">
              <div class="form-group">
                <div class="row">
                  <div class="col-xl-4 col-lg-5 col-md-4">
                    <label class="control-label">Post code<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-md-8 col-lg-7 offset-xl-2 col-xl-6">
                    <input type="text" class="form-control" id="Postcode2" name="Postcode2" tabindex="27" [maxLength]="20" [(ngModel)]="data.ApplicationAssessor2.Postcode" #Postcode2="ngModel" required [ngClass]="{'is-invalid': Postcode2?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Postcode2?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-5 offset-xl-0 offset-lg-5 offset-md-4 col-xl-4 pr-0 mb-15">
                  <div class="form-check mb-0">
                    <label class="control-label">
                      UK<span>&nbsp;*</span>
                    </label>
                    <pb-switch class="required-denote custom-switch" id="IsUK2" name="IsUK2" [(ngModel)]="data.ApplicationAssessor2.IsUK" labelOn="Yes" labelOff="No" #IsUK2="ngModel" required [ngClass]="{'is-invalid': IsUK2?.invalid && showMessage}">
                    </pb-switch>
                    <div class="invalid-tooltip" *ngIf="IsUK2?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>

                <div class="col-md-12 col-lg-12 col-xl-8" *ngIf="data.ApplicationAssessor2.IsUK === false">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-4 col-lg-5 col-xl-3">
                        <label class="control-label mb-0">
                          Other<small class="mb-0">(Please state)</small><span>&nbsp;*</span>
                        </label>
                      </div>
                      <div class="col-md-8 col-lg-7 col-xl-9">
                        <input type="text" class="form-control" id="Country2" tabindex="29" name="Country2" [maxLength]="50" [(ngModel)]="data.ApplicationAssessor2.Country" #Country2="ngModel" [required]="!data.ApplicationAssessor2.IsUK" [ngClass]="{'is-invalid': Country2?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="Country2?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Second Form-->
  <div class="card-footer form-card">
    <div class="row">
      <div class="col-md-12 pl-0">
        <p class="required">* denotes required fields </p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-sm-6 col-12">
        <div class="form-group">
          <div class="row">
            <div class="col-md-2">
              <label for="ID">ID:</label>
            </div>

            <div class="col-md-10">
              <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-12">
        <div class="form-group">
          <div class="row">
            <div class="col-md-3">
              <label for="Surname">Surname:</label>
            </div>

            <div class="col-md-9">
              <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
            </div>
          </div>
        </div>
      </div>

      <div class="form-group col-auto pr-0 cardPagin">
        <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page17Form.dirty)">
          <i class="fas fa-chevron-left" style="font-size: 17px;"></i>
        </button>
        <label class="pagination-label" for="Surname">Page</label>
        <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page17Form.dirty)">
          <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
        </select>
        <p>
          of
          <span>{{pages.length}}</span>
        </p>
        <button type="button" class="btn btn-primary float-left" (click)="goToNext(page17Form.dirty)">
          <i class="fas fa-chevron-right" style="font-size: 17px;"></i>
        </button>
      </div>

      <div class="col-auto text-right ml-auto pl-0">
        <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
        <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
        <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
      </div>
    </div>
  </div>
</form>

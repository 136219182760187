<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page4Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-0">
            <label for="IsEmployed" class="mb-0">
              A. Have you ever been subject to, or are you now facing, any proceedings or other circumstances which led or may lead, either in England and Wales or in another jurisdiction, to:
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class='col-xs-12 col-sm-12'>
          <ul class="list-group page-4-list mb-30">
            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsCriminalOffence">
                  1. A conviction for a criminal offence (other than a minor road traffic offence)? <small class="required">*</small>
                  <br />
                  <small>(You do not need to include convictions which are “spent” by virtue of the Rehabilitation of Offenders Act 1974)</small>
                </label>
                <pb-switch required class="required-denote custom-switch flot-right" #IsCriminalOffence="ngModel" id="IsCriminalOffence" [(ngModel)]="data.IsCriminalOffence" name="IsCriminalOffence" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsCriminalOffence?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsProfessionalNegligence">2. A finding of professional negligence? <small class="required">*</small></label>
                <pb-switch required class="required-denote custom-switch float-right" #IsProfessionalNegligence="ngModel" id="IsProfessionalNegligence" [(ngModel)]="data.IsProfessionalNegligence" name="IsProfessionalNegligence" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsProfessionalNegligence?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsProfessionalDisciplinary">3. A finding of professional disciplinary fault (including inadequate professional service)? <small class="required">*</small></label>
                <pb-switch required class="required-denote custom-switch float-right" #IsProfessionalDisciplinary="ngModel" id="IsProfessionalDisciplinary" [(ngModel)]="data.IsProfessionalDisciplinary" name="IsProfessionalDisciplinary" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsProfessionalDisciplinary?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsWastedCosts">4. A wasted costs order? <small class="required">*</small></label>
                <pb-switch required class="required-denote custom-switch float-right" #IsWastedCosts="ngModel" id="IsWastedCosts" [(ngModel)]="data.IsWastedCosts" name="IsWastedCosts" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsWastedCosts?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsIntervention">5. An intervention by the Solicitors Regulation Authority? <small class="required">*</small></label>
                <pb-switch required class="required-denote custom-switch float-right" #IsIntervention="ngModel" id="IsIntervention" [(ngModel)]="data.IsIntervention" name="IsIntervention" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsIntervention?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsComplaint">6. A complaint which has been referred to the Legal Ombudsman? <small class="required">*</small></label>
                <pb-switch required class="required-denote custom-switch float-right" #IsComplaint="ngModel" id="IsComplaint" [(ngModel)]="data.IsComplaint" name="IsComplaint" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsComplaint?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsBankruptcy">7. A bankruptcy order, debt relief order or an individual or partnership voluntary arrangement? <small class="required">*</small></label>
                <pb-switch required class="required-denote custom-switch float-right" #IsBankruptcy="ngModel" id="IsBankruptcy" [(ngModel)]="data.IsBankruptcy" name="IsBankruptcy" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsBankruptcy?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsDirectorDisqualification">8. A director’s disqualification order? <small class="required">*</small></label>
                <pb-switch required class="required-denote custom-switch float-right" #IsDirectorDisqualification="ngModel" id="IsDirectorDisqualification" [(ngModel)]="data.IsDirectorDisqualification" name="IsDirectorDisqualification" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsDirectorDisqualification?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsOtherOrder">9. Any other form of order or proceeding which may be material to the Selection panel or the Lord Chancellor in considering your suitability to be appointed King's Counsel? <small class="required">*</small></label>
                <pb-switch required class="required-denote custom-switch float-right" #IsOtherOrder="ngModel" id="IsOtherOrder" [(ngModel)]="data.IsOtherOrder" name="IsOtherOrder" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsOtherOrder?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>

            <li>
              <div class="list-group-item d-flex justify-content-between align-items-center">
                <label class="control-label" for="IsAnyother">
                  B. Is there anything else in your personal or professional background which could affect your suitability for appointment or bring the legal profession or King's Counsel into disrepute? <small class="required">*</small>
                </label>
                <pb-switch required class="required-denote custom-switch float-right" #IsAnyOther="ngModel" id="IsAnyOther" [(ngModel)]="data.IsAnyOther" name="IsAnyOther" labelOn="Yes" labelOff="No"></pb-switch>
                <div class="invalid-tooltip textarea-tooltip" *ngIf="IsAnyOther?.errors?.required && showMessage">This field is required.</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class='col-xs-12 col-sm-12'>
          <div class="form-group mb-0">
            <label for="OrderNarrative" class="mb-0">
              If you answered “yes” to any of the above please provide details below.<span class="required" *ngIf="(data.IsOtherOrder || data.IsProfessionalDisciplinary || data.IsAnyOther || data.IsBankruptcy || data.IsComplaint || data.IsIntervention || data.IsWastedCosts ||  data.IsProfessionalNegligence || data.IsCriminalOffence || data.IsDirectorDisqualification)">&nbsp;*</span>
              <small class="mb-0">
                (Please include your complaint reference number, case number, or similar.)
              </small>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class='col-xs-12 col-sm-12'>
          <div class="form-group textarea-form">
            <textarea [required]="data.IsOtherOrder || data.IsProfessionalDisciplinary || data.IsAnyOther || data.IsBankruptcy || data.IsComplaint || data.IsIntervention || data.IsWastedCosts ||  data.IsProfessionalNegligence || data.IsCriminalOffence || data.IsDirectorDisqualification" class="form-control input-md mb-0" #OrderNarrative="ngModel" id="OrderNarrative" name="OrderNarrative" [(ngModel)]="data.OrderNarrative" maxlength="700" autosize [minRows]="7"></textarea>
            <span class="float-right label label-default" id="counter">{{data.OrderNarrative ? data.OrderNarrative.length : 0}} / 700</span>
            <div class="invalid-tooltip" *ngIf="OrderNarrative?.errors?.required && showMessage">This field is required.</div>
            <small class="label-inline">Please note there is a limit of 700 characters for this box.</small>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer form-card">
      <div class="row">
        <div class="col-md-12 pl-0">
          <p class="required">* denotes required fields </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-2">
                <label for="ID">ID:</label>
              </div>

              <div class="col-md-10">
                <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" [maxLength]="9">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="Surname">Surname:</label>
              </div>

              <div class="col-md-9">
                <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName"[maxLength]="50">
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-auto pr-0 cardPagin">
          <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page4Form.dirty)"><i class="fas fa-chevron-left" style="font-size: 16px;"></i></button>
          <label class="pagination-label" for="Surname">Page</label>
          <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page4Form.dirty)">
            <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
          </select>
          <p>of <span>{{pages.length}}</span></p>
          <button type="button" class="btn btn-primary float-left" (click)="goToNext(page4Form.dirty)"><i class="fas fa-chevron-right" style="font-size: 16px;"></i></button>
        </div>

        <div class="col-auto text-right ml-auto pl-0">
          <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
          <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
          <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
        </div>
      </div>
    </div>
  </div>
</form>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { WorldPayComponent } from './worldpay.component';
import { WorldPayRoutes } from './worldpay.routing';
import { FinalPaymentComponent } from './final-payment/final-payment.component';  

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule.forChild(WorldPayRoutes),
    NgbModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.doubleBounce,
      backdropBorderRadius: '4px',
      primaryColour: '#d7e7db',
      secondaryColour: '#57a170'
    }),
  ],
  declarations: [
    WorldPayComponent,
    FinalPaymentComponent
  ]
})
export class WorldPayModule {}

<form>
  <div class="custom-card">
    <div class="card-header bg-primary">
      <h3 class="card-title text-white text-center">Payment</h3>
    </div>
    <div class="card-body">
      <p>Thank you for completing the 2024 KC Appointments Competition, your application has been received. If you have any questions please email <a href = "mailto: applications@kcappointments.org">applications@kcappointments.org</a> or call 0207 831 0020.</p>
      <br />
      <br />
      <p>To pay for your application please use electronic banking transfer by using the details below, adding your surname and Bar Membership or Law Society number you have used in your application to the payment reference.</p>
      <span style="margin-top: 3px;display: inline-block">Amount £2,520 (or £1,260 if claiming the concessionary fee)</span>
      <br />
      <br />
      <div class="mb-30">
        <label>Bank Name: Child & Co.</label><br />
        <label>Account Name: KC Appointments</label><br />
        <label>Sort Code: 15-80-00</label><br />
        <label class="float-left">Account number: 10578135</label>
      </div>
    </div>
    <div class="text-right card-footer">
      <button type="button" class="btn m-0" (click)="activeModal.close('Close click')">Close</button>
    </div>
  </div>
</form>
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BreadcrumbService } from '../shared/breadcrumb/breadcrumb.service';
import { Breadcrumb } from '../shared/breadcrumb/breadcrumb.model';
import { WorldPayService } from './worldpay.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-worldpay',
  templateUrl: './worldpay.component.html',
})
export class WorldPayComponent implements OnInit {
  applicationId!: string;
  data: any = {};
  worldPay: any = {};
    applicationFinalPayment: boolean;
    submitted: boolean;
    constructor(private breadcrumbService: BreadcrumbService,
        public router: Router,
    private wordPayService: WorldPayService) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
        this.submitted = currentUser && (currentUser.applicationFinalPayment === 'True');
        this.applicationFinalPayment = currentUser && (currentUser.applicationFinalPayment === 'True');
    this.applicationId = currentUser && currentUser.applicationId;
    this.worldPay = environment.worldPay;
  }

  ngOnInit() {
      let that = this;
      this.submitted ? this.applicationFinalPayment ? this.router.navigate(['/worldpay/final-payment']) : this.router.navigate(['/worldpay']) : "Do nothing";
    that.setBreadcrumbs();
    that.wordPayService
      .getData(that.applicationId)
      .subscribe((res: any) => {
        that.data = res;
      });
  }

  pay() {
    let that = this;
    let param = {
      testMode: that.worldPay.testMode,
      instId: that.worldPay.instId,
      cartId: that.applicationId,
      amount: that.data.PaymentAmount,
      currency: that.worldPay.currency
    };
    that.wordPayService.postPayment(param, that.worldPay.url);
  }

  setBreadcrumbs() {
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: 'Payment', url: '' });
    this.breadcrumbService.set(breadcrumbs);
  }
}

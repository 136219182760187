<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page12Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-check mb-5 tooltip-right">
            <label>
              Are there any matters relating to integrity on which you wish to provide KC Appointments with further information?<span class="required ">&nbsp;*</span>
            </label>
            <pb-switch class="custom-switch" required [(ngModel)]="data.IsCompetencyE" #IsCompetencyE="ngModel" name="IsCompetencyE" id="IsCompetencyE"></pb-switch>
            <div class="invalid-tooltip float-right" *ngIf="IsCompetencyE?.errors?.required && showMessage">This field is required.</div>
          </div>
        </div>
      </div>

      <div class="form-group textarea-form" *ngIf="data.IsCompetencyE">
        <textarea [required]="data.IsCompetencyE" maxlength="3000" autosize [minRows]="15" class="form-control input-md mb-0" #CompetencyE="ngModel" id="CompetencyE" name="CompetencyE" type="text" [(ngModel)]="data.CompetencyE"   [ngClass]="{'is-invalid': CompetencyE?.invalid && showMessage}"></textarea>
        <span class="float-right label label-default" id="counter">{{data.CompetencyE ? data.CompetencyE.length : 0}} / 3000</span>
        <small class="label-inline"> Please note there is a limit of 3000 characters for this box. </small>
        <div class="invalid-tooltip textarea-tooltip" *ngIf="CompetencyE?.errors?.required && showMessage">This field is required.</div>
      </div>

    </div>
    <div class="card-footer form-card">
      <div class="row">
        <div class="col-md-12 pl-0">
          <p class="required">* denotes required fields </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-2">
                <label for="ID">ID:</label>
              </div>

              <div class="col-md-10">
                <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="Surname">Surname:</label>
              </div>

              <div class="col-md-9">
                <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName"  [maxLength]="50">
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-auto pr-0 cardPagin">
          <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page12Form.dirty)">
            <i class="fas fa-chevron-left" style="font-size: 16px;"></i>
          </button>
          <label class="pagination-label" for="Surname">Page</label>
          <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page12Form.dirty)">
            <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
          </select>
          <p>
            of
            <span>{{pages.length}}</span>
          </p>
          <button type="button" class="btn btn-primary float-left" (click)="goToNext(page12Form.dirty)">
            <i class="fas fa-chevron-right" style="font-size: 16px;"></i>
          </button>
        </div>

        <div class="col-auto text-right ml-auto pl-0">
          <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
          <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
          <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
        </div>
      </div>
    </div>
  </div>
</form>

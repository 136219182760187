<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form *ngIf="(data | json) != '{}'">
  <div class="card custom-card">
    <div class="card-body form-card">
      <div class="mb-20">
        <label class=" label-inline mb-0">Congratulations on your appointment as KC</label>
      </div>

      <div class="mb-20">
        <label class=" label-inline mb-0">To pay the fee due on appointment with a debit card (credit cards are not accepted), please click the Worldpay link below.</label>
      </div>

      <div class="mb-20">
        <label>Alternatively, you can pay by electronic transfer to:</label><br />
        <label>Bank name: Child and Co</label><br />
        <label>Account name: KC Appointments</label><br />
        <label>Sort Code: 15-80-00</label><br />
        <label>Account number 10578135</label>
      </div>

      <div class="mb-20">
        <label>If paying by electronic transfer please ensure the payment gives details of your professional surname and forenames in full.</label><br />
        <label>Thank you</label>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Professional surname</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.LastName}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Forenames in full</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.FirstName}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Name of chambers, firm or employer</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.Chambers}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Your Law Society roll number/Bar Council membership number</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.Reference}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Payment method</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.vw_LookupPaymentTypeApplication.PaymentType}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Payment amount</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="£3,864" />
              </div>
            </div>
          </div>

          <div *ngIf="data.PaymentAmount" class="form-group">
            <input type="submit" class="float-right btn btn-primary" value="Pay via WorldPay" (click)="pay()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

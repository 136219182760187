import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../authentication/login/login.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  submitted = false;
  applicationFinalPayment = false;
  public sidebarnavItems!: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private loginService: LoginService,
    private route: ActivatedRoute) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.submitted = currentUser && (currentUser.applicationSubmitted === 'True');
    this.applicationFinalPayment = currentUser && (currentUser.applicationFinalPayment === 'True');
  }

  // End open close
  ngOnInit() {
    let that = this;
      if (that.submitted && that.applicationFinalPayment) {
        that.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem.title === 'Final Payment');
    } else {
        that.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem.submitted == that.submitted);
    }
  }


  logout(): void {
      this.loginService.logout();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  lookupApiUrl: string = "Lookup/";// Web API URL  
  constructor(private http: HttpClient) {
  }

  //Fill lookup
  titles!: Observable<any[]>;
  applicationsJudgeTitles!: Observable<any[]>;
  applicationTitles!: Observable<any[]>;
  professions!: Observable<any[]>;
  InnCourts!: Observable<any[]>;
  regions!: Observable<any[]>;
  judicialOffices!: Observable<any[]>;
  roles!: Observable<any[]>;
  nominated!: Observable<any[]>;
  ApplicationId!: Observable<any>;
 
  //Variables
  defaultCurrencyId: any = 4;
  TPARole: any = 'TPA';
  TPARoleId: any = 2;
  userAccessGroupId: any = 3463;
  defaultMessageTypeId: any = 3259;
  defaultLockoutEnabled: any = false;

  //Services
  getTitlesApplication() {
    if (!this.applicationTitles) {
      this.applicationTitles = this.http.get(this.lookupApiUrl + "vw_LookupTitleApplication")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.applicationTitles;
  }

  getTitles() {
    if (!this.titles) {
      this.titles = this.http.get(this.lookupApiUrl + "vw_lookuptitle")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.titles;
  }

  getApplicationJudgeTitles() {
    if (!this.applicationsJudgeTitles) {
      this.applicationsJudgeTitles = this.http.get("lookup/vw_LookupApplicationTitleJudge")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.applicationsJudgeTitles;
  }

  getProfession() {
    if (!this.professions) {
      this.professions = this.http.get(this.lookupApiUrl + "vw_lookupprofession")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.professions;
  }

  getInnCourts() {
    if (!this.InnCourts) {
      this.InnCourts = this.http.get(this.lookupApiUrl + "vw_lookupinnofcourt")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.InnCourts;
  }

  getRegions() {
    if (!this.regions) {
      this.regions = this.http.get(this.lookupApiUrl + "vw_lookupregion")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.regions;
  }

  getJudicialOffices() {
    if (!this.judicialOffices) {
      this.judicialOffices = this.http.get(this.lookupApiUrl + "vw_lookupjudicialoffice")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.judicialOffices;
  }

  getRoles() {
    if (!this.roles) {
      this.roles = this.http.get(this.lookupApiUrl + "vw_LookupRole2")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.roles;
  }

  getNominated() {
    if (!this.nominated) {
      this.nominated = this.http.get(this.lookupApiUrl + "vw_LookupNominated")
        .map((res: any) => <any[]>res)
        .publishReplay(1)
        .refCount();
    }
    return this.nominated;
  }

  getApplicationId() {
    if (!this.ApplicationId) {
      this.ApplicationId = this.http.get(this.lookupApiUrl + "applicationSubmittedCheck")
        .map((res: any) => <any>res)
        .publishReplay(1)
        .refCount();
    }
    return this.ApplicationId;
  }

  getBooleanOptions() {
    var options = [
      { LookupId: 'true', LookupValue: 'Yes' },
      { LookupId: 'false', LookupValue: 'No' }
    ];
    return options;
  }
} 

<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb">
  <div class="align-self-center">
    <div class="d-flex align-items-center row">
      <div class="col-md-9">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last="last">
              <li class="breadcrumb-item" *ngIf="!last" [routerLink]="breadcrumb.url">
                <a href='javascript:void(0)'>{{breadcrumb.label}}</a>
              </li>
              <li class="breadcrumb-item active" *ngIf="last"><span>{{breadcrumb.label}}</span></li>
            </ng-template>
          </ol>
        </nav>
      </div>
      <div *ngIf="page != null" class="col-md-3">
        <span class="float-right">
          Page Complete?&nbsp;&nbsp;
          <button *ngIf="page.IsComplete === true" type="button" class="btn btn-primary pageComplete">Yes</button>
          <button *ngIf="page.IsComplete === false" type="button" class="btn btn-primary pageNotComplete">No</button>
        </span>
      </div>
    </div>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->

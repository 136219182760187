<ngx-loading [show]="(data | json) == '{}' || titles.length == 0"></ngx-loading>
<form [hidden]="(data | json) == '{}' || titles.length == 0" #page1Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body form-card">

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <label class="control-label" for="TitleId">Title<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-4 col-md-6">
                <ng-select [items]="titles" id="TitleId" required name="TitleId" #TitleId="ngModel" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.TitleId">
                </ng-select>
                <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="(data.TitleId == 493 && TitleId.dirty) || data.TitleId == 493">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <label class="control-label" for="TitleOther">Title other<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-8 col-md-6">
                <input class="form-control input-md" #TitleOther="ngModel" required id="TitleOther" name="TitleOther" type="text" [(ngModel)]="data.TitleOther" [maxlength]="100">
                <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <label class="control-label" for="FirstName">Forenames in full<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-8 col-md-6">
                <input class="form-control input-md" #FirstName="ngModel" required id="FirstName" name="FirstName" type="text" [(ngModel)]="data.FirstName" [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" [maxlength]="100">
                <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6 pr-0">
                <label class="control-label" for="LastName">Professional surname<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-8 col-md-6">
                <input class="form-control input-md" #LastName="ngModel" required id="LastName" name="LastName" type="text" [(ngModel)]="data.LastName" [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" [maxlength]="50">
                <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>



          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <label class="control-label" for="Reference">Your Law Society roll number/Bar Council membership number<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-4 col-md-6">
                <input class="form-control input-sm" #Reference="ngModel" ng-pattern="/^-?\d*$/" required id="Reference" name="Reference" type="text" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46' [(ngModel)]="data.Reference" [ngClass]="{'is-invalid': Reference?.invalid && showMessage}" number [min]="0" [maxLength]="9">
                <div class="invalid-tooltip" *ngIf="Reference?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <label class="control-label" for="ProfessionalEmail">Professional email address (verified)<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-8 col-md-6">
                <input class="form-control input-md" readonly="readonly" #ProfessionalEmail="ngModel" id="ProfessionalEmail" name="ProfessionalEmail" type="email" [(ngModel)]="data.ProfessionalEmail">
                <div class="invalid-tooltip" *ngIf="ProfessionalAddress1?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>


          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6 pr-0">
                <label class="control-label" for="MobilePhoneNumber">Mobile number (verified)<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-8 col-md-6">
                <input 
                  readonly 
                  class="form-control input-md" 
                  id="MobilePhoneNumber" 
                  name="MobilePhoneNumber" 
                  type="text" 
                  [(ngModel)]="user.PhoneNumber" 
                >
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6 pr-0">
                <label class="control-label" for="MobileTelephone">Other telephone or mobile number</label>
              </div>

              <div class="col-lg-8 col-md-6">
                <input 
                  class="form-control input-md" 
                  #MobileTelephone="ngModel" 
                  id="MobileTelephone" 
                  name="MobileTelephone"
                  type="text"
                  [(ngModel)]="data.MobileTelephone" 
                  [ngClass]="{'is-invalid': MobileTelephone?.invalid && showMessage}" 
                  [maxLength]="50"
                >
                <div class="invalid-tooltip" *ngIf="MobileTelephone?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-4 col-md-12 pr-0">
              <div class="form-group mb-0">
                <label class="mb-5">Preferred Interview Location <span>&nbsp;*</span></label>
              </div>
            </div>

            <div class="col-xl-8 col-md-12">
              <div class="form-check mr-0 mb-0">
                <label>London </label>
                <pb-switch class="required-denote custom-switch mr-15" [(ngModel)]="data.IsLondonInterview" name="IsLondonInterview" id="IsLondonInterview" labelOn="Yes" labelOff="No">
                </pb-switch>
              </div>

              <div class="form-check float-right mr-0 mb-0">
                <label>Manchester</label>
                <pb-switch class="required-denote custom-switch" [(ngModel)]="data.IsManchesterInterview" name="IsManchesterInterview" id="IsManchesterInterview" labelOn="Yes" labelOff="No">
                </pb-switch>
              </div>
            </div>

            <div class="col-lg-8 col-md-12 offset-lg-4">
              <div class="required" style="text-align:center"><small>Please select a minimum one location for the interview.</small></div>
            </div>

          </div>

          <div class="form-group">
            <small for="isLondonInterview" class="label-inline">(Please select to indicate your preferred location(s) should the Selection Panel invite you to an interview)</small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group ">
            <div class="row">
              <div class="col-lg-4 col-md-6 pr-0">
                <label class="control-label mb-0" for="Chambers">Name of chambers, firm or employer<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-8 col-md-6">
                <input class="form-control input-md" #Chambers="ngModel" required id="Chambers" name="Chambers" type="text" [(ngModel)]="data.Chambers" [ngClass]="{'is-invalid': Chambers?.invalid && showMessage}" [maxLength]="200">
                <div class="invalid-tooltip" *ngIf="Chambers?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6 md-mb-15">
                <label class="control-label mb-0" for="address">Address</label>
              </div>

              <div class="col-xl-8 col-md-6">
                <input class="form-control input-md" #ProfessionalAddress1="ngModel" id="ProfessionalAddress1" name="ProfessionalAddress1" type="text" [(ngModel)]="data.ProfessionalAddress1" [maxLength]="200">
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-lg-8 col-md-6  offset-lg-4 offset-md-6">
                <input class="form-control input-md" #ProfessionalAddress2="ngModel" id="ProfessionalAddress2" name="ProfessionalAddress2" type="text" [(ngModel)]="data.ProfessionalAddress2" [maxLength]="200">
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <label class="control-label" for="ProfessionalAddress4">Post town<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-4 col-md-6">
                <input class="form-control input-md" #ProfessionalAddress4="ngModel" required id="ProfessionalAddress4" name="ProfessionalAddress4" type="text" [(ngModel)]="data.ProfessionalAddress4" [ngClass]="{'is-invalid': ProfessionalAddress4?.invalid && showMessage}" [maxLength]="200">
                <div class="invalid-tooltip" *ngIf="ProfessionalAddress4?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <label class="control-label" for="ProfessionalPostcode">Post code<span>&nbsp;*</span></label>
              </div>

              <div class="col-lg-4 col-md-6">
                <input class="form-control input-md" #ProfessionalPostcode="ngModel" required id="ProfessionalPostcode" name="ProfessionalPostcode" type="text" [(ngModel)]="data.ProfessionalPostcode" [ngClass]="{'is-invalid': ProfessionalPostcode?.invalid && showMessage}" [maxLength]="20">
                <div class="invalid-tooltip" *ngIf="ProfessionalPostcode?.errors?.required && showMessage">This field is required.</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-8 offset-lx-4 col-md-12 mh-58 offset-xl-4">
              <div class="row">
                <div class="col-lg-4 pr-0">
                  <div class="form-check mb-0 mr-0">
                    <label class="control-label" for="IsUK">UK<span>&nbsp;*</span></label>
                    <pb-switch class="required-denote custom-switch" #IsUK="ngModel" required id="IsUK" [(ngModel)]="data.IsUK" name="IsUK" labelOn="Yes" labelOff="No">
                    </pb-switch>
                    <div class="invalid-tooltip" *ngIf="IsUK?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>

                <div class="col-lg-8 " *ngIf="data.IsUK === false">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-5 md-pl-15 pl-0 pr-0">
                        <label class="control-label" for="ProfessionalCountry">Other<span>&nbsp;*</span><small class="mb-0">(please state)</small></label>
                      </div>

                      <div class="col-md-7">
                        <input [required]="!data.IsUK" class="form-control input-md" #ProfessionalCountry="ngModel" id="ProfessionalCountry" name="ProfessionalCountry" type="text" [(ngModel)]="data.ProfessionalCountry" [maxLength]="50">
                        <div class="invalid-tooltip" *ngIf="ProfessionalCountry?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="form-group">
        <label for="UnavailableDate" class="mb-0">
          Please supply dates, such as holiday commitments, that mean you could not attend an interview during 30 September to 18 October 2024.
          <small class="mb-0" style="color: red !important;">
            (Please do NOT include court, hearing dates etc, as judges, adjudications etc normally allow time out for interview)
          </small>
        </label>
        <textarea maxlength="500" class="form-control input-md mb-0" #UnavailableDate="ngModel" id="UnavailableDate" name="UnavailableDate" [(ngModel)]="data.UnavailableDate" [ngClass]="{'is-invalid': UnavailableDate?.invalid && showMessage}" autosize [minRows]="5"></textarea>
        <span class="float-right label label-default" id="counter">{{data.UnavailableDate ? data.UnavailableDate.length : 0}} / 500</span>
        <small class="label-inline">Please note there is a limit of 500 characters for this box</small>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-md-12 pl-0">
            <p class="required">* denotes required fields </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-2 col-sm-6 col-12">
            <div class="form-group">
              <div class="row">
                <div class="col-md-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-md-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6 col-12">
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-md-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-auto pr-0 cardPagin">
            <button type="button" disabled="disabled" class="btn btn-primary float-left"><i class="fas fa-chevron-left" style="font-size: 16px;"></i></button>
            <label class="pagination-label" for="Surname">Page</label>
            <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page1Form.dirty)">
              <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
            </select>
            <p>of <span>{{pages.length}}</span></p>
            <button type="button" class="btn btn-primary float-left" (click)="goToNext(page1Form.dirty)"><i class="fas fa-chevron-right" style="font-size: 16px;"></i></button>
          </div>

          <div class="col-auto text-right ml-auto pl-0">
            <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
            <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
            <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

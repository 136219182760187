export const environment = {
  production: true,
  dataApiUrl: "https://qccms-api-uat.azurewebsites.net/api/",
  worldPay: {
    url: "https://secure-test.worldpay.com/wcc/purchase",
    testMode: 100,
    instId: 316710,
    currency: "GBP"
  },
  userIdle: { idle: 1800, timeout: 5, ping: 0 }
};

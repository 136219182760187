import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { httpInterceptorProviders } from './shared/http-Interceptors/index';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LaddaModule } from 'angular2-ladda';
import { NgbModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { UserIdleModule } from 'angular-user-idle';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';

import { Approutes, ClosedRoute } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LoginService } from './authentication/login/login.service';
import { ConfirmationDialogService } from './shared/confirmation-dialog/confirmation-dialog.service';
import { BreadcrumbService } from './shared/breadcrumb/breadcrumb.service';
import { WorldPayModule } from './worldpay/worldpay.module';
import { SearchPipe } from './shared/pipe/serach.pipe';
import { PasteBlockDirective } from './shared/pasteBlock.directive';
import { environment } from '../environments/environment';
import { ClosedComponent } from '../app/closed/closed.component';
import { ValidationMessageToasterComponent } from './shared/http-Interceptors/validation-message-toaster/validation-message-toaster.component';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ApplicationModule } from './application/application.module';


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    ConfirmationDialogComponent,
    SearchPipe,
    PasteBlockDirective,
    ClosedComponent,
    ValidationMessageToasterComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    NgScrollbarModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgbDatepickerModule,
    
    // Open and Close Competition Start
    //RouterModule.forRoot(ClosedRoute, { useHash: false }),
    RouterModule.forRoot(Approutes, { useHash: false }),
    // Open and Close Competition End
    
    ToastrModule.forRoot({ toastComponent: ValidationMessageToasterComponent }),
    //NgxMaskModule.forRoot(),
    NgxMaskDirective,
    LoadingBarHttpClientModule,
    LaddaModule,
    ApplicationModule,
    UserIdleModule.forRoot(environment.userIdle),
    WorldPayModule,
    LoggerModule.forRoot({
      serverLoggingUrl: 'errorlog',
      level: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: true
    })
  ],
  providers: [
    LoginService,
    BreadcrumbService,
    ConfirmationDialogService,
    httpInterceptorProviders,
    provideNgxMask({ validation: false }),
    
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    }
  ],
  bootstrap: [AppComponent],
  exports: [ValidationMessageToasterComponent]
})

export class AppModule { }

<div class="text-center">
  <!-- ============================================================== -->
  <!-- toggle and nav items -->
  <!-- ============================================================== -->
  <ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block">
      <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
        <i class="mdi mdi-menu font-24"></i>
      </a>
    </li>
    <!-- Search -->
    <!-- ============================================================== -->
    <li class="nav-item search-box">
      <!--<a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
          <i class="ti-search"></i>
      </a>-->
      <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
        <input type="text" class="form-control" placeholder="Search &amp; enter">
        <a class="srh-btn" (click)="showSearch = !showSearch">
          <i class="ti-close"></i>
        </a>
      </form>
    </li>
  </ul>
  <!-- ============================================================== -->
  <!-- main heading -->
  <!-- ============================================================== -->
  <div class="main-heading">
    <h2 class="text-center">Application Form 2024</h2>
  </div>
  <!-- Right side toggle and nav items -->
  <!-- ============================================================== -->
  <ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <!--<a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="flag-icon flag-icon-us"></i>
      </a>-->
      <div class="dropdown-menu dropdown-menu-right animated bounceInDown" aria-labelledby="navbarDropdown" ngbDropdownMenu>
        <a class="dropdown-item" href="#">
          <i class="flag-icon flag-icon-us"></i> English
        </a>
        <a class="dropdown-item" href="#">
          <i class="flag-icon flag-icon-fr"></i> French
        </a>
        <a class="dropdown-item" href="#">
          <i class="flag-icon flag-icon-es"></i> Spanish
        </a>
        <a class="dropdown-item" href="#">
          <i class="flag-icon flag-icon-de"></i> German
        </a>
      </div>
    </li>
    <!-- ============================================================== -->
    <!-- Comment -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <!--<a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="mdi mdi-bell font-24"></i>
      </a>-->
      <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" ngbDropdownMenu>
        <span class="with-arrow">
          <span class="bg-primary"></span>
        </span>
        <ul class="list-style-none">
          <li>
            <div class="drop-title bg-primary text-white">
              <h4 class="m-b-0 m-t-5">2 New</h4>
              <span class="font-light">Notifications</span>
            </div>
          </li>
          <li>
            <ng-scrollbar class="message-center notifications">
              <!-- Message -->
              <a href="javascript:void(0)" class="message-item" *ngFor="let notification of notifications">
                <span class="btn btn-circle {{notification.btn}}">
                  <i class="{{notification.icon}}"></i>
                </span>
                <span class="mail-contnet">
                  <h5 class="message-title">{{notification.title}}</h5>
                  <span class="mail-desc">{{notification.subject}}</span>
                  <span class="time">{{notification.time}}</span>
                </span>
              </a>
            </ng-scrollbar>
          </li>
          <li>
            <a class="nav-link text-center m-b-5" href="javascript:void(0);">
              <strong>Check all notifications</strong>
              <i class="fa fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <!-- ============================================================== -->
    <!-- End Comment -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Messages -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <!--<a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="font-24 mdi mdi-comment-processing"></i>

      </a>-->
      <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" aria-labelledby="2" ngbDropdownMenu>
        <span class="with-arrow">
          <span class="bg-danger"></span>
        </span>
        <ul class="list-style-none">
          <li>
            <div class="drop-title text-white bg-danger">
              <h4 class="m-b-0 m-t-5">0 New</h4>
              <span class="font-light">Messages</span>
            </div>
          </li>
          <li>
            <ng-scrollbar class="message-center message-body">
              <!-- Message -->
              <a href="javascript:void(0)" class="message-item" *ngFor="let mymessage of mymessages">
                <span class="user-img">
                  <img src="{{mymessage.useravatar}}" alt="user" class="rounded-circle">
                  <span class="profile-status {{mymessage.status}} pull-right"></span>
                </span>
                <span class="mail-contnet">
                  <h5 class="message-title">{{mymessage.from}}</h5>
                  <span class="mail-desc">{{mymessage.subject}}</span>
                  <span class="time">{{mymessage.time}}</span>
                </span>
              </a>
            </ng-scrollbar>
          </li>
          <li>
            <a class="nav-link text-center link" href="javascript:void(0);">
              <b>See all e-Mails</b>
              <i class="fa fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <!-- ============================================================== -->
    <!-- End Messages -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">
      </a>
      <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" ngbDropdownMenu>
        <span class="with-arrow">
          <span class="bg-primary"></span>
        </span>
        <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
          <div class="">
            <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
          </div>
          <div class="m-l-10">
            <!--<h4 class="m-b-0">Steave Jobs</h4>
            <p class=" m-b-0">varun@gmail.com</p>-->
          </div>
        </div>
        <a class="dropdown-item" [routerLink]="['/', 'user' ,'profile']">
          <i class="ti-user m-r-5 m-l-5"></i> My Profile
        </a>

        <!--<a class="dropdown-item" href="javascript:void(0)">
            <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
        <a class="dropdown-item" href="javascript:void(0)">
            <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="javascript:void(0)">
            <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>-->

        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="javascript:;" (click)="logout()">
          <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout
        </a>
        <!--<div class="dropdown-divider"></div>
        <div class="p-l-30 p-10">
            <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
        </div>-->
      </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
  </ul>

</div>

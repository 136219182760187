import { Routes } from '@angular/router';
import { Page1Component } from './page1/page1.component';
import { Page2Component } from './page2/page2.component';
import { Page3Component } from './page3/page3.component';
import { Page4Component } from './page4/page4.component';
import { Page5Component } from './page5/page5.component';
import { Page6Component } from './page6/page6.component';
import { Page7Component } from './page7/page7.component';
import { Page8Component } from './page8/page8.component';
import { Page9Component } from './page9/page9.component';
import { Page10Component } from './page10/page10.component';
import { Page11Component } from './page11/page11.component';
import { Page12Component } from './page12/page12.component';
import { Page13Component } from './page13/page13.component';
import { Page14Component } from './page14/page14.component';
import { Page15Component } from './page15/page15.component';
import { Page16Component } from './page16/page16.component';
import { Page17Component } from './page17/page17.component';
import { Page51Component } from './page51/page51.component';
import { Page52Component } from './page52/page52.component';
import { ApplicationResolver } from './application.resolver';
import { ListOfCases } from './application';

export const ApplicationRoutes: Routes = [
  {
    path: 'application',
    redirectTo: '/application/page/1',
    pathMatch: 'full'
  },
  {
    path: 'application/page',
    redirectTo: '/application/page/1',
    pathMatch: 'full'
  },
  {
    path: 'page',
    resolve: { applicationId: ApplicationResolver },
    children: [
      {
        path: '1',
        component: Page1Component,
      },
      {
        path: '2',
        component: Page2Component
      },
      {
        path: '3',
        component: Page3Component
      },
      {
        path: '4',
        component: Page4Component
      },
      {
        path: '5',
        component: Page5Component
      },
      {
        path: '6',
        component: Page6Component
      },
      {
        path: '7',
        component: Page7Component
      },
      {
        path: '8',
        component: Page8Component
      },
      {
        path: '9',
        component: Page9Component
      },
      {
        path: '10',
        component: Page10Component
      },
      {
        path: '11',
        component: Page11Component
      },
      {
        path: '12',
        component: Page12Component
      },
      {
        path: '13',
        component: Page13Component
      },
      {
        path: '14',
        component: Page14Component
      },
      {
        path: '15',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case1J.AssessorReference,
          orderNo: ListOfCases.Case1J.OrderNo
        }
      },
      {
        path: '16',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case1P.AssessorReference,
          orderNo: ListOfCases.Case1P.OrderNo
        }
      },
      {
        path: '17',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case1C.AssessorReference,
          orderNo: ListOfCases.Case1C.OrderNo
        }
      },
      {
        path: '18',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case2J.AssessorReference,
          orderNo: ListOfCases.Case2J.OrderNo
        }
      },
      {
        path: '19',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case2P.AssessorReference,
          orderNo: ListOfCases.Case2P.OrderNo
        }
      },
      {
        path: '20',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case2C.AssessorReference,
          orderNo: ListOfCases.Case2C.OrderNo
        }
      },
      {
        path: '21',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case3J.AssessorReference,
          orderNo: ListOfCases.Case3J.OrderNo
        }
      },
      {
        path: '22',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case3P.AssessorReference,
          orderNo: ListOfCases.Case3P.OrderNo
        }
      },
      {
        path: '23',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case3C.AssessorReference,
          orderNo: ListOfCases.Case3C.OrderNo
        }
      },
      {
        path: '24',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case4J.AssessorReference,
          orderNo: ListOfCases.Case4J.OrderNo
        }
      },
      {
        path: '25',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case4P.AssessorReference,
          orderNo: ListOfCases.Case4P.OrderNo
        }
      },
      {
        path: '26',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case4C.AssessorReference,
          orderNo: ListOfCases.Case4C.OrderNo
        }
      },
      {
        path: '27',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case5J.AssessorReference,
          orderNo: ListOfCases.Case5J.OrderNo
        }
      },
      {
        path: '28',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case5P.AssessorReference,
          orderNo: ListOfCases.Case5P.OrderNo
        }
      },
      {
        path: '29',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case5C.AssessorReference,
          orderNo: ListOfCases.Case5C.OrderNo
        }
      },
      {
        path: '30',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case6J.AssessorReference,
          orderNo: ListOfCases.Case6J.OrderNo
        }
      },
      {
        path: '31',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case6P.AssessorReference,
          orderNo: ListOfCases.Case6P.OrderNo
        }
      },
      {
        path: '32',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case6C.AssessorReference,
          orderNo: ListOfCases.Case6C.OrderNo
        }
      },
      {
        path: '33',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case7J.AssessorReference,
          orderNo: ListOfCases.Case7J.OrderNo
        }
      },
      {
        path: '34',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case7P.AssessorReference,
          orderNo: ListOfCases.Case7P.OrderNo
        }
      },
      {
        path: '35',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case7C.AssessorReference,
          orderNo: ListOfCases.Case7C.OrderNo
        }
      },
      {
        path: '36',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case8J.AssessorReference,
          orderNo: ListOfCases.Case8J.OrderNo
        }
      },
      {
        path: '37',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case8P.AssessorReference,
          orderNo: ListOfCases.Case8P.OrderNo
        }
      },
      {
        path: '38',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case8C.AssessorReference,
          orderNo: ListOfCases.Case8C.OrderNo
        }
      },
      {
        path: '39',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case9J.AssessorReference,
          orderNo: ListOfCases.Case9J.OrderNo
        }
      },
      {
        path: '40',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case9P.AssessorReference,
          orderNo: ListOfCases.Case9P.OrderNo
        }
      },
      {
        path: '41',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case9C.AssessorReference,
          orderNo: ListOfCases.Case9C.OrderNo
        }
      },
      {
        path: '42',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case10J.AssessorReference,
          orderNo: ListOfCases.Case10J.OrderNo
        }
      },
      {
        path: '43',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case10P.AssessorReference,
          orderNo: ListOfCases.Case10P.OrderNo
        }
      },
      {
        path: '44',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case10C.AssessorReference,
          orderNo: ListOfCases.Case10C.OrderNo
        }
      },
      {
        path: '45',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case11J.AssessorReference,
          orderNo: ListOfCases.Case11J.OrderNo
        }
      },
      {
        path: '46',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case11P.AssessorReference,
          orderNo: ListOfCases.Case11P.OrderNo
        }
      },
      {
        path: '47',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case11C.AssessorReference,
          orderNo: ListOfCases.Case11C.OrderNo
        }
      },
      {
        path: '48',
        component: Page15Component,
        data: {
          assessorReference: ListOfCases.Case12J.AssessorReference,
          orderNo: ListOfCases.Case12J.OrderNo
        }
      },
      {
        path: '49',
        component: Page16Component,
        data: {
          assessorReference: ListOfCases.Case12P.AssessorReference,
          orderNo: ListOfCases.Case12P.OrderNo
        }
      },
      {
        path: '50',
        component: Page17Component,
        data: {
          assessorReference: ListOfCases.Case12C.AssessorReference,
          orderNo: ListOfCases.Case12C.OrderNo
        }
      },
       {
        path: '51',
        component: Page51Component
      },
      {
        path: '52',
        component: Page52Component
      }
    ]
  }
];

import { Component, Input, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const UI_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PBSwitchComponent),
  multi: true
};

@Component({
  selector: 'pb-switch',
  template: `
  <div class="btn btn-group btn-block" >
  <div [class.btn-on]="checked===true"  [class.btn-null]="checked===null||checked===false" (click)="onYes()" class="btn border-radius-left">Yes</div>
  <div [class.btn-off]="checked===false" [class.btn-null]="checked===null||checked===true" (click)="onNo()" class="btn border-radius-right">No</div>
  </div>
  `,
  styles: [`
    .btn-null {
      background: #d9d9d9;
      color: #4a4c4e;
      border: 1px solid #a7a2a2;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      border-radius: 0px;
      font-size: 14px;
      padding: .375rem .75rem;
      line-height: 1.5;
      transition: color 0.50s ease-in-out,background-color 0.50s ease-in-out,border-color 0.50s ease-in-out,box-shadow 0.50s ease-in-out;
    }
    .border-radius-left {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
    .border-radius-right {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
    .btn-on {
      background: #57a170;
      border: 1px solid #a7a2a2;
      color: white;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      font-size: 14px;
      padding: .375rem .75rem;
      line-height: 1.5;
    }
    .btn-off {
      background: #e52e2d;
      border: 1px solid #a7a2a2;
      color: white;
      text-align: center;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      font-size: 14px;
      padding: .375rem .75rem;
      line-height: 1.5;
    }
    `],
  providers: [UI_SWITCH_CONTROL_VALUE_ACCESSOR]
})
export class PBSwitchComponent implements ControlValueAccessor {
  private onTouchedCallback = (v: any) => {
  };
  private onChangeCallback = (v: any) => {
  };
  private _checked: boolean | any;
  private _disabled: boolean | any;
  private _isNullable: any;


  @Input() set checked(v: boolean) {
    this._checked = v;
  }

  get checked() {
    return this._checked;
  }

  @Input() set isNullable(v: boolean) {
    this._isNullable = (v = "true" ? true : false);
  }

  get isNullable() {
    return this._isNullable;
  }

  @Input() set disabled(v: boolean) {
    this._disabled = v;
  };

  get disabled() {
    return this._disabled;
  }

  @Output() change = new EventEmitter<boolean>();

  onYes() {
    let that = this;
    if (that._checked === true) {
      if (that._isNullable === true) {
        that._checked = true;
      } else {
        that._checked = null;
      }
    }
    else {
      that._checked = true;
    }
    this.change.emit(this.checked);
    this.onChangeCallback(this.checked);
    this.onTouchedCallback(this.checked);
  }

  onNo() {
    let that = this;
    if (that._isNullable === true) {
      that._checked = false;
    } else {
      if (that._checked === false) {
        that._checked = null;
      }
      else {
        that._checked = false;
      }
    }
    this.change.emit(this.checked);
    this.onChangeCallback(this.checked);
    this.onTouchedCallback(this.checked);
  }

  writeValue(obj: any): void {
    if (obj !== this.checked) {
      this.checked = obj;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}

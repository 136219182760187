import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentFormService } from "./payment-form.service";

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent {
  @Input() applicationPaymentTypeId: any;
  @Input() paymentAmount: any;
  constructor(public activeModal: NgbActiveModal,
    public paymentFormService: PaymentFormService) {
  }

  pay() {
    let that = this;
    that.activeModal.close('Pay click');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class Page52Service {
  constructor(private http: HttpClient, ) {
  }

  //post payment
  postPayment(obj :any, url :any) {
    var mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST";
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  //get Data
  getData(id: string): Observable<any> {
    return this
      .http
      .get('lookup/page52Data')
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }


  //Check SP
  checkSp(id: string): Observable<any> {
    return this
      .http
      .get('lookup/sp_ApplicationNominationCheck/'+ id)
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }

  //submit Application
  submitAppplication(id: string): Observable<any> {
    return this
      .http
      .post('lookup/page52Data/' + id, null)
      .flatMap((res: any) => {
        return Observable.of(res)
        .catch(error => error);
      });
  }

  //update Data
  updateData(id: string, data: any): Observable<any> {
    return this
      .http
      .put('lookup/page52Data/' + id, data)
      .flatMap((res: any) => {
        return Observable.of(res)
        .catch(error => error);
      });
  }

  //logout
  logout(): Observable<any> {
    return this
      .http
      .post('account/logout', null)
      .flatMap((res: any) => {
        return Observable.of(res)
        .catch(error => error);
      });
  }
}

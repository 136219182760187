import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class AllApplicationService {

  constructor(private http: HttpClient, ) {
  }

  //get Data
  getData(id: number): Observable<any> {
    return this
      .http
      .get('applications/applicationform_application_all/' + id)
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }
} 

<form>
  <div class="custom-card">
    <div class="card-header bg-primary">
      <h3 class="card-title text-white text-center">{{ title }}</h3>
      <button *ngIf="!isButtonShow" type="button" class="close confirm-close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body" [innerHTML]="message">
    </div>
    <div *ngIf="isButtonShow" class="text-right card-footer">
      <button *ngIf="btnCancelText != ''" type="button" class="btn btn-primary btn-danger m-0" (click)="decline()">{{ btnCancelText }}</button>
      <button *ngIf="btnOkText != ''" type="button" class="btn btn-primary saveDial m-0" (click)="accept()">{{ btnOkText }}</button>
    </div>
  </div>
</form>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { WorldPayService } from '../worldpay.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-final-payment',
  templateUrl: './final-payment.component.html',
  styles: []
})
export class FinalPaymentComponent implements OnInit {

    applicationId!: string;
    applicationFinalPayment: boolean;
    data: any = {};
    worldPay: any = {};
    constructor(private breadcrumbService: BreadcrumbService,
        public router: Router,
        private wordPayService: WorldPayService) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
        this.applicationFinalPayment = currentUser && (currentUser.applicationFinalPayment === 'True');
        this.applicationId = currentUser && currentUser.applicationId;
        this.worldPay = environment.worldPay;
    }

    ngOnInit() {
        let that = this;
        !this.applicationFinalPayment ? this.router.navigate(['/worldpay/info']): //foo;
        that.setBreadcrumbs();
        that.wordPayService
            .getData(that.applicationId)
            .subscribe((res: any) => {
                that.data = res;
            });
    }

    pay() {
        let that = this;
        let param = {
            testMode: that.worldPay.testMode,
            instId: that.worldPay.instId,
            cartId: that.applicationId,
            amount: 3864.0000,
            currency: that.worldPay.currency
        };
        that.wordPayService.postPayment(param, that.worldPay.url);
    }

    setBreadcrumbs() {
        let breadcrumbs: Breadcrumb[] = [];
        breadcrumbs.push({ label: 'Final Payment', url: '' });
        this.breadcrumbService.set(breadcrumbs);
    }
}


<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page6Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body">
      <div class="form-group mb-0">
        <label class="label-inline mb-0">
          Please give a description of your practice in the space below. This information will help the Selection Panel
          understand the nature of your practice.<span class="required">&nbsp;*</span>
        </label>
      </div>

      <div class="form-group textarea-form">
        <textarea maxlength="5000" autosize [minRows]="15" class="form-control input-md mb-0" #PracticeNarrative="ngModel" required id="PracticeNarrative" name="PracticeNarrative" [(ngModel)]="data.PracticeNarrative"  ></textarea>
        <span class="float-right label label-default" id="counter">{{data.PracticeNarrative ? data.PracticeNarrative.length : 0}} / 5000</span>
        <div class="invalid-tooltip textarea-tooltip" *ngIf="PracticeNarrative?.errors?.required && showMessage">This field is required.</div>
        <small class="label-inline"> Please note there is a limit of 5000 characters for this box and note that spaces, paragraphs and bullets points are included in that character count. </small>

      </div>
    </div>

    <div class="card-footer form-card">
      <div class="row">
        <div class="col-md-12 pl-0">
          <p class="required">* denotes required fields </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-2">
                <label for="ID">ID:</label>
              </div>

              <div class="col-md-10">
                <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="Surname">Surname:</label>
              </div>

              <div class="col-md-9">
                <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-auto pr-0 cardPagin">
          <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page6Form.dirty)">
            <i class="fas fa-chevron-left" style="font-size: 16px;"></i>
          </button>
          <label class="pagination-label" for="Surname">Page</label>
          <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event,  page6Form.dirty)">
            <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
          </select>
          <p>
            of
            <span>{{pages.length}}</span>
          </p>
          <button type="button" class="btn btn-primary float-left" (click)="goToNext(page6Form.dirty)">
            <i class="fas fa-chevron-right" style="font-size: 16px;"></i>
          </button>
        </div>

        <div class="col-auto text-right ml-auto pl-0">
          <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
          <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
          <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
        </div>
      </div>
    </div>
  </div>
</form>

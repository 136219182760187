<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form *ngIf="(data | json) != '{}'">
  <div class="card custom-card">
    <div class="card-body form-card">
      <div class="form-group">
        <label class=" label-inline mb-0">Your application has been submitted, if you have any questions please email <a href = "mailto: applications@kcappointments.org">applications@kcappointments.org</a> or call 0207 831 0020.</label>
      </div>

      <div class="form-group">
        <label class=" label-inline mb-0">
          To pay for your application please use electronic banking transfer by using the details below, adding your surname and Bar Membership or Law Society number you have used in your application to the payment reference.
          <br />
          <br />
          Amount £2,520 (or £1,260 if claiming the concessionary fee)
        </label>
      </div>

      <div class="mb-30">
        <label>Bank Name: Child & Co.</label><br />
        <label>Account Name: KC  Appointments</label><br />
        <label>Sort Code: 15-80-00</label><br />
        <label>Account number: 10578135</label>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Professional surname</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.LastName}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Forenames in full</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.FirstName}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Name of chambers, firm or employer</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.Chambers}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Your Law Society roll number/Bar Council membership number</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.Reference}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Payment method</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.vw_LookupPaymentTypeApplication.PaymentType}}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Payment amount</label>
              </div>
              <div class="col-md-4 offset-md-2">
                <input type="text" class="form-control" readonly value="{{data.vw_LookupPaymentAmount?.PaymentAmount}}" />
              </div>
            </div>
          </div>

          <!--<div *ngIf="data.PaymentAmount" class="form-group">
            <input type="submit" class="float-right btn btn-primary" value="Pay via WorldPay" (click)="pay()" />
          </div>-->
        </div>
      </div>
    </div>
  </div>
</form>

import { Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { LookupService } from '../../shared/lookup.service';
import { Page2Service } from './page2.service';
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { GuidanceNoteFormComponent } from '../guidance-note-form/guidance-note-form.component';
import { DateConvertor } from '../../shared/date-convertor';
import { Page2 } from './page2';
import { PageHeadings, Pages } from '../application';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../shared/resource.service';
import { PageReference } from '../../shared/constants';
declare var saveAs: any;

@Component({
  selector: 'application-page-2',
  templateUrl: './page2.html'
})
export class Page2Component implements OnInit {
  @ViewChild('page2Form') form! :any;
  applicationId!: string;
  heading: string = PageHeadings.page2;
  pageNo!: number;
  pages: number[] = Pages;
  data: any = {};
  professions: any[] = [];
  InnCourts: any[] = [];
  url: string = '/application/page/';
  isSaving: boolean = false;
  dateConvertor!: DateConvertor;
  BarDateField: any;
  PreviousBarDateField: any;
  HighCourtCivilDateField: any;
  HighCourtCriminalDateField: any;
  HighCourtAllDateField: any;
  invalidChars1: any = [
    "0",
    "1",
    "2",
    "3",
    "4",
  ];


  showMessage: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private Page2Service: Page2Service,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private lookupService: LookupService,
    private resourceService: ResourceService,
    private confirmationDialogService: ConfirmationDialogService,
    private modalService: NgbModal,
    private config: NgbDatepickerConfig) {
    config.minDate = { year: 1900, month: 1, day: 1 };
    config.maxDate = { year: 2099, month: 12, day: 31 };
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.showMessage = currentUser && currentUser.showMessage ? true : false;
  }

  ngOnInit() {
    let that = this;
    that.dateConvertor = new DateConvertor();
    that.pageNo = parseInt(that.route.snapshot.url[0].path);
    that.applicationId = that.route.snapshot.data['applicationId'];
    that.setBreadcrumbs();
    let data = that.Page2Service.getData(that.applicationId);
    let professions = that.lookupService.getProfession();
    let InnCourts = that.lookupService.getInnCourts();
    forkJoin([data, professions, InnCourts])
      .subscribe(results => {
        that.data = results[0];
        that.professions = results[1];
        that.InnCourts = results[2];
        that.BarDateField = that.dateConvertor.fromModel(results[0].BarDate);
        that.PreviousBarDateField = that.dateConvertor.fromModel(results[0].PreviousBarDate);
        that.HighCourtCivilDateField = that.dateConvertor.fromModel(results[0].HighCourtCivilDate);
        that.HighCourtCriminalDateField = that.dateConvertor.fromModel(results[0].HighCourtCriminalDate);
        that.HighCourtAllDateField = that.dateConvertor.fromModel(results[0].HighCourtAllDate);
        that.sumTotalRegion();
      });
  }

  sumTotalRegion(): any {
    let that = this;
    that.data.TotalRegion = (that.data.PracticeAreaLondon ? that.data.PracticeAreaLondon : 0)
      + (that.data.PracticeAreaNorthWest ? that.data.PracticeAreaNorthWest : 0)
      + (that.data.PracticeAreaSouthEast ? that.data.PracticeAreaSouthEast : 0)
      + (that.data.PracticeAreaSouthWest ? that.data.PracticeAreaSouthWest : 0)
      + (that.data.PracticeAreaMidlands ? that.data.PracticeAreaMidlands : 0)
      + (that.data.PracticeAreaWales ? that.data.PracticeAreaWales : 0)
      + (that.data.PracticeAreaNorthEast ? that.data.PracticeAreaNorthEast : 0)
      + (that.data.PracticeAreaEurope ? that.data.PracticeAreaEurope : 0);
  }

  save() {
    let that = this;
    that.isSaving = true;
    that.data.IsComplete = this.form.valid;
    that.data.PreviousBarDate = that.dateConvertor.toModel(that.PreviousBarDateField);
    that.data.PageNo = that.pageNo;
    that.data.BarDate = that.dateConvertor.toModel(that.BarDateField);
    that.data.HighCourtCivilDate = that.dateConvertor.toModel(that.HighCourtCivilDateField);
    that.data.HighCourtCriminalDate = that.dateConvertor.toModel(that.HighCourtCriminalDateField);
    that.data.HighCourtAllDate = that.dateConvertor.toModel(that.HighCourtAllDateField);
    that.Page2Service
      .updateData(that.applicationId, that.data)
      .subscribe((res: any) => {
        that.isSaving = false;
        that.toastr.success('Data has been added successfully!', 'Success!');
        that.reloadForm();
         }, error => {
        that.isSaving = false;
      });
  }
  //model: NgbDateStruct;
  //date: { year: number, month: number };

  minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };

  onPageChange(event: any, dirty: any) {
    let page = event.target.value;
    event.srcElement.blur();
    event.preventDefault();
    let that = this;
    if (dirty) {
      that.confirm(page);
    }
    else {
      that.router.navigate([that.url + page]);
    }
  }

  onKeyup(val :any, labelName :any, fieldName :any) {
    let that = this;
    if (val.target.value != null && val.target.value != "") {
      if (val.target.value < 101 && val.target.value > 4) {
      } else {
        val.preventDefault();
        val.target.value = '';
        that.data[fieldName] = null;
        that.sumTotalRegion();
        that.toastr.warning('The field ( ' + labelName + ' ) must be in between 5 and 100');
      }
    }
    else {
    }
    
  }
  

  goToPrevious(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo - 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo - 1)]);
    }
  }

  goToNext(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo + 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo + 1)]);
    }
  }
  downloadApplicationForm() {
      let that = this;
      that.resourceService
          .get("applications/DownloadApplicationForm", that.applicationId)
          .subscribe(response => {
              var blob = this.resourceService.b64toBlob(response.Content, response.ContentType);
              saveAs(blob, response.FileName);
              that.toastr.success('File has been created successfully!', 'Success!');
          },
          (error: any) => {
              that.toastr.error('Something went wrong! Please try again.', 'Oops!');
          });
  }
  reloadForm() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }


  openGuidanceNotes() {
    let that = this;
    const modalRef = this.modalService.open(GuidanceNoteFormComponent, { size: 'lg' });
    modalRef.componentInstance.groupRefId = PageReference.Page2;
  }

  confirm(page: any) {
    let that = this;
    that.confirmationDialogService
      .confirm('Warning - Unsaved changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Leave Page')
      .then((confirmed) => {
        if (confirmed === true) {
          that.router.navigate([that.url + page]);
        }
        else {
          that.pageNo = parseInt(that.route.snapshot.url[0].path);
        }
      });
  }

  setBreadcrumbs() {
    let that = this;
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: that.heading, url: '' });
    that.breadcrumbService.set(breadcrumbs);
  }
}

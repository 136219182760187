import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { Page3Service } from './page3.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { Page3 } from './page3';
import { GuidanceNoteFormComponent } from '../guidance-note-form/guidance-note-form.component';
import { PageHeadings, Pages } from '../application';
import { ResourceService } from '../../shared/resource.service';

declare var saveAs: any;

export interface ApplicationSpecialism {
  FieldOfPractice: string;
  FieldOfPracticeId: number;
  SpecialismPercent: number | null;
}

interface Data {
  ApplicationSpecialisms: ApplicationSpecialism[];
  Total?: number;
  OtherSpecialism?: string;
  IsComplete?: boolean;
  PageNo?: number;
  Reference?: string;
  LastName?: string;
}

@Component({
  selector: 'application-page-3',
  templateUrl: './page3.html'
})
export class Page3Component implements OnInit {
  @ViewChild('page3Form') form: any;
  applicationId!: string;
  heading: string = PageHeadings.page3;
  pageNo!: number;
  pages: number[] = Pages;
  data: Data = { ApplicationSpecialisms: [] };
  url: string = '/application/page/';
  isSaving: boolean = false;
  breakCount: number = 0;
  showMessage: boolean = false;
  showSpecialisms: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private resourceService: ResourceService,
    private page3Service: Page3Service,
    private modalService: NgbModal
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.showMessage = currentUser && currentUser.showMessage ? true : false;
  }

  ngOnInit() {
    this.pageNo = parseInt(this.route.snapshot.url[0].path, 10);
    this.applicationId = this.route.snapshot.data['applicationId'];
    this.setBreadcrumbs();
    this.page3Service.getData(this.applicationId).subscribe((res: Data) => {
      this.data = res;
      this.breakCount = Math.ceil(this.data.ApplicationSpecialisms.length ? this.data.ApplicationSpecialisms.length / 4 : 0);
      this.calculateTotal();
    });
  }

  save() {
    this.isSaving = true;
    this.data.IsComplete = this.form.valid;
    this.data.PageNo = this.pageNo;
    this.page3Service.updateData(this.applicationId, this.data).subscribe(
      () => {
        this.isSaving = false;
        this.toastr.success('Data has been added successfully!', 'Success!');
        this.reloadForm();
      },
      () => {
        this.isSaving = false;
      }
    );
  }

  onPageChange(event: any, dirty: boolean | any) {
    const page = event.target.value;
    event.srcElement.blur();
    event.preventDefault();
    if (dirty) {
      this.confirm(page);
    } else {
      this.router.navigate([this.url + page]);
    }
  }

  changeValueEvent(val: any, fieldId: number) {
    if (val.target.value != null && val.target.value !== '') {
      if (val.target.value < 101 && val.target.value > 4) {
        this.calculateTotal();
      } else {
        const specialism = this.data.ApplicationSpecialisms.find((x) => x.FieldOfPracticeId === fieldId);
        if (specialism) {
          specialism.SpecialismPercent = null;
          this.toastr.warning(`The field ( ${specialism.FieldOfPractice} ) must be between 5 and 100`);
          this.calculateTotal();
        }
      }
    } else {
      this.calculateTotal();
    }
  }

  calculateTotal() {
    let total = 0;
    let otherVal = 0;
    for (const spplicationSpecialism of this.data.ApplicationSpecialisms) {
      if (spplicationSpecialism.SpecialismPercent) {
        total += spplicationSpecialism.SpecialismPercent;
        if (spplicationSpecialism.SpecialismPercent >= 5 && spplicationSpecialism.FieldOfPractice === 'Other') {
          this.showSpecialisms = true;
          otherVal = 1;
        }
      }
    }
    if (otherVal === 0) {
      this.showSpecialisms = false;
    }
    this.data.Total = total;
  }

  showOtherSpecialism() {
    this.calculateTotal();
  }

  goToPrevious(dirty: boolean | any) {
    if (dirty) {
      this.confirm(this.pageNo - 1);
    } else {
      this.router.navigate([this.url + (this.pageNo - 1)]);
    }
  }

  goToNext(dirty: boolean | any) {
    if (dirty) {
      this.confirm(this.pageNo + 1);
    } else {
      this.router.navigate([this.url + (this.pageNo + 1)]);
    }
  }

  openGuidanceNotes() {
    const modalRef = this.modalService.open(GuidanceNoteFormComponent, { size: 'lg' });
    modalRef.componentInstance.groupRefId = this.pageNo;
  }

  confirm(page: number) {
    this.confirmationDialogService
      .confirm(
        'Warning - Unsaved changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Leave Page'
      )
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate([this.url + page]);
        } else {
          this.pageNo = parseInt(this.route.snapshot.url[0].path, 10);
        }
      });
  }

  downloadApplicationForm() {
    this.resourceService.get('applications/DownloadApplicationForm', this.applicationId).subscribe(
      (response) => {
        const blob = this.resourceService.b64toBlob(response.Content, response.ContentType);
        saveAs(blob, response.FileName);
        this.toastr.success('File has been created successfully!', 'Success!');
      },
      () => {
        this.toastr.error('Something went wrong! Please try again.', 'Oops!');
      }
    );
  }

  reloadForm() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  setBreadcrumbs() {
    const breadcrumbs: Breadcrumb[] = [{ label: this.heading, url: '' }];
    this.breadcrumbService.set(breadcrumbs);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BreadcrumbService } from './breadcrumb.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { Breadcrumb } from './breadcrumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs!: Breadcrumb[];
  page: any;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private titleService: Title,
    private router: Router,
    private dashboardService: DashboardService) {
    this.breadcrumbService.breadcrumbItem
      .subscribe((data: Breadcrumb[]) => {
        if (data.length > 0) {
          this.breadcrumbs = data;
          let crumb: Breadcrumb = this.breadcrumbs[data.length - 1];
          this.titleService.setTitle(crumb.label);
        }
      });
  }

  ngOnInit() {
    let urlParts = this.router.url.split('/');
    let pageNo = urlParts.length > 3 ? parseInt(urlParts[3]) : 0;
    if (pageNo != 0) {
      this.dashboardService
        .getPage(pageNo)
        .subscribe((res: any) => {
          this.page = res;
        });
    }
    this.breadcrumbs = [];
    this.titleService.setTitle('Please wait...');
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AllApplicationService } from './all-application.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { DateConvertor } from '../../shared/date-convertor';
import { PageHeadings } from '../application';
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';

@Component({
  selector: 'application-print',
  templateUrl: './all-application.html',
  styleUrls: ['./all-application.scss']
})
export class AllApplicationComponent implements OnInit {
  applicationId!: number;
  heading = PageHeadings;
  data: any = {};
  dateConvertor!: DateConvertor;
  breakCount: number = 0;
  breakCount1: number = 0;
  showSpecialisms: boolean = false;
  BarDateField: any;
  PreviousBarDateField: any;
  HighCourtCivilDateField: any;
  HighCourtCriminalDateField: any;
  HighCourtAllDateField: any;
  isAnyApplicant: boolean = false;
  DoBDate: any;
  isShow: any = [];
  showMessage: boolean = false;
  page15_50: any = [];
  constructor(private router: Router,
    private route: ActivatedRoute,
    private allApplicationService: AllApplicationService,
    private breadcrumbService: BreadcrumbService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit() {
    let that = this;
    that.applicationId = that.route.snapshot.data['applicationId'];
    that.dateConvertor = new DateConvertor();
    that.allApplicationService
      .getData(that.applicationId)
      .subscribe((res: any) => {
        that.data = res;
        that.BarDateField = that.dateConvertor.fromModel(that.data.BarDate);
        that.PreviousBarDateField = that.dateConvertor.fromModel(that.data.PreviousBarDate);
        that.HighCourtCivilDateField = that.dateConvertor.fromModel(that.data.HighCourtCivilDate);
        that.HighCourtCriminalDateField = that.dateConvertor.fromModel(that.data.HighCourtCriminalDate);
        that.HighCourtAllDateField = that.dateConvertor.fromModel(that.data.HighCourtAllDate);
        that.sumTotalRegion();
        that.breakCount = Math.ceil(that.data.ApplicationSpecialisms.length ? that.data.ApplicationSpecialisms.length / 3 : 0);
        that.calculateTotal();
        that.breakCount1 = Math.ceil(that.data.ApplicationRecusals.length ? that.data.ApplicationRecusals.length / 2 : 0);
        that.isAnyApplicant = that.anyApplicant(that.data.ApplicationRecusals);
        that.DoBDate = that.dateConvertor.fromModel(that.data.DoB);
        that.isShow = that.data.ApplicationPracticess.filter((x: { IsActive: number; }) => x.IsActive == 0);
        that.generateData();
        that.print();
      });
    that.setBreadcrumbs();
  }

  print(): void {
    setTimeout(function () {
      window.print();
    }, 1000);
  }

  sumTotalRegion(): any {
    let that = this;
    that.data.TotalRegion = (that.data.PracticeAreaLondon ? that.data.PracticeAreaLondon : 0)
      + (that.data.PracticeAreaNorthWest ? that.data.PracticeAreaNorthWest : 0)
      + (that.data.PracticeAreaSouthEast ? that.data.PracticeAreaSouthEast : 0)
      + (that.data.PracticeAreaSouthWest ? that.data.PracticeAreaSouthWest : 0)
      + (that.data.PracticeAreaMidlands ? that.data.PracticeAreaMidlands : 0)
      + (that.data.PracticeAreaWales ? that.data.PracticeAreaWales : 0)
      + (that.data.PracticeAreaNorthEast ? that.data.PracticeAreaNorthEast : 0)
      + (that.data.PracticeAreaEurope ? that.data.PracticeAreaEurope : 0);
  }

  calculateTotal(): any {
    let that = this;
    let total = 0;
    let otherVal = 0;
    for (let spplicationSpecialism of that.data.ApplicationSpecialisms) {
      if (spplicationSpecialism.SpecialismPercent) {
        total += spplicationSpecialism.SpecialismPercent;
        if (spplicationSpecialism.SpecialismPercent >= 5 && spplicationSpecialism.FieldOfPractice == "Other") {
          that.showSpecialisms = true;
          otherVal = 1;
        }
      }
    }
    if (otherVal == 0) {
      that.showSpecialisms = false;
    }
    that.data.Total = total;
  }

  anyApplicant(obj: { [x: string]: { IsApplicant: boolean; }; }): any {
    for (var o in obj)
      if (obj[o].IsApplicant == true) return true;

    return false;
  }

  generateData(): any {
    let that = this;
    let pageNo = 15;
    that.data.ApplicationPracticess.forEach(function (applicationPractice :any) {
      let ref = applicationPractice.OrderNo > 9 ? applicationPractice.OrderNo : '0' + applicationPractice.OrderNo;
      that.page15_50.push({
        Judge: {
          CaseReferenceJ: that.data.vw_ApplicationCaseReferences.filter((x: { ApplicationAssessorId: any; }) => x.ApplicationAssessorId == (that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('J' + ref) && x.OrderNo == applicationPractice.OrderNo)[0].ApplicationAssessorId))[0],
          CaseReferenceJA: that.data.vw_ApplicationCaseReferences.filter((x: { ApplicationAssessorId: any; }) => x.ApplicationAssessorId == (that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('J' + ref) && x.OrderNo == applicationPractice.OrderNo)[0].ApplicationAssessorId))[0],
          ApplicationAssessorFormJ: that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('J' + ref) && x.OrderNo == applicationPractice.OrderNo)[0],
          ApplicationAssessorFormJA: that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('J' + ref) && x.OrderNo == applicationPractice.OrderNo)[0],
          AssessorJ: that.data.ApplicationAssessors.filter((x: { IsAdditional: number; AssessorReference: string; ApplicationPracticeId: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('J' + ref) && x.ApplicationPracticeId == applicationPractice.ApplicationPracticeId)[0],
          AssessorJA: that.data.ApplicationAssessors.filter((x: { IsAdditional: number; AssessorReference: string; ApplicationPracticeId: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('J' + ref) && x.ApplicationPracticeId == applicationPractice.ApplicationPracticeId)[0],
          PageNo: pageNo,
        },
        Practitioner: {
          CaseReferenceP: that.data.vw_ApplicationCaseReferences.filter((x: { ApplicationAssessorId: any; }) => x.ApplicationAssessorId == (that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('P' + ref) && x.OrderNo == applicationPractice.OrderNo)[0].ApplicationAssessorId))[0],
          CaseReferencePA: that.data.vw_ApplicationCaseReferences.filter((x: { ApplicationAssessorId: any; }) => x.ApplicationAssessorId == (that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('P' + ref) && x.OrderNo == applicationPractice.OrderNo)[0].ApplicationAssessorId))[0],
          ApplicationAssessorFormP: that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('P' + ref) && x.OrderNo == applicationPractice.OrderNo)[0],
          ApplicationAssessorFormPA: that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('P' + ref) && x.OrderNo == applicationPractice.OrderNo)[0],
          AssessorP: that.data.ApplicationAssessors.filter((x: { IsAdditional: number; AssessorReference: string; ApplicationPracticeId: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('P' + ref) && x.ApplicationPracticeId == applicationPractice.ApplicationPracticeId)[0],
          AssessorPA: that.data.ApplicationAssessors.filter((x: { IsAdditional: number; AssessorReference: string; ApplicationPracticeId: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('P' + ref) && x.ApplicationPracticeId == applicationPractice.ApplicationPracticeId)[0],
          PageNo: pageNo + 1
        },
        Client: {
          CaseReferenceC: that.data.vw_ApplicationCaseReferences.filter((x: { ApplicationAssessorId: any; }) => x.ApplicationAssessorId == (that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('C' + ref) && x.OrderNo == applicationPractice.OrderNo)[0].ApplicationAssessorId))[0],
          CaseReferenceCA: that.data.vw_ApplicationCaseReferences.filter((x: { ApplicationAssessorId: any; }) => x.ApplicationAssessorId == (that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('C' + ref) && x.OrderNo == applicationPractice.OrderNo)[0].ApplicationAssessorId))[0],
          ApplicationAssessorFormC: that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('C' + ref) && x.OrderNo == applicationPractice.OrderNo)[0],
          ApplicationAssessorFormCA: that.data.vw_ApplicationAssessorForms.filter((x: { IsAdditional: number; AssessorReference: string; OrderNo: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('C' + ref) && x.OrderNo == applicationPractice.OrderNo)[0],
          AssessorC: that.data.ApplicationAssessors.filter((x: { IsAdditional: number; AssessorReference: string; ApplicationPracticeId: any; }) => x.IsAdditional == 0 && x.AssessorReference == ('C' + ref) && x.ApplicationPracticeId == applicationPractice.ApplicationPracticeId)[0],
          AssessorCA: that.data.ApplicationAssessors.filter((x: { IsAdditional: number; AssessorReference: string; ApplicationPracticeId: any; }) => x.IsAdditional == 1 && x.AssessorReference == ('C' + ref) && x.ApplicationPracticeId == applicationPractice.ApplicationPracticeId)[0],
          PageNo: pageNo + 2
        }
      });
      pageNo = pageNo + 3;
    });
  }

  setBreadcrumbs() {
    let that = this;
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: 'Print View', url: '' });
    that.breadcrumbService.set(breadcrumbs);
  }
}

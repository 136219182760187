import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { Page1Service } from './page1.service';
import { LookupService } from '../../shared/lookup.service';
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { GuidanceNoteFormComponent } from '../guidance-note-form/guidance-note-form.component';
import { Page1 } from './page1';
import { PageHeadings, Pages } from '../application';
import { PageReference } from '../../shared/constants';

import 'rxjs/add/operator/catch';
import { ResourceService } from '../../shared/resource.service';
declare var saveAs: any;

@Component({
  selector: 'application-page-1',
  templateUrl: './page1.html'
})
export class Page1Component implements OnInit {
  @ViewChild('page1Form') form! :any;
  applicationId!: string;
  heading: string = PageHeadings.page1;
  pageNo!: number;
  pages: number[] = Pages;
  data: any = {};
  user: any = {};
  url: string = '/application/page/';
  titles: any[] = [];
  log: string[] = [];
  titleOther!: string;
  isSaving: boolean = false;
  showMessage: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private Page1Service: Page1Service,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private modalService: NgbModal,
    private resourceService: ResourceService,
    private lookupService: LookupService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.showMessage = currentUser && currentUser.showMessage ? true : false;
  }

  ngOnInit() {
    let that = this;
    that.pageNo = parseInt(that.route.snapshot.url[0].path);
    that.applicationId = that.route.snapshot.data['applicationId'];
    that.setBreadcrumbs();
    that.Page1Service
      .getData(that.applicationId)
      .subscribe((res: any) => {
        that.data = res;
      });
    that.Page1Service
      .getUserData()
      .subscribe((resp: any) => {
        that.user = resp;
      });
    that.lookupService
      .getTitlesApplication()
      .subscribe((res: any) => {
        that.titles = res
      });
  }

  save() {
    let that = this;
    that.isSaving = true;
    that.data.IsComplete = this.form.valid;
    that.data.PageNo = that.pageNo;
    if(that.data.PageNo==1){
      that.Page1Service.updateUserName({"FirstName":that.data.FirstName,"LastName":that.data.LastName})
      .subscribe((res: any) => {
      }, error => {
      });
    }
    that.Page1Service
      .updateData(that.applicationId, that.data)
      .subscribe((res: any) => {
        that.isSaving = false;
        that.toastr.success('Data has been added successfully!', 'Success!');
        that.reloadForm();
      }, error => {
        that.isSaving = false;
      });
  }

  onPageChange(event: any, dirty: any) {
    let page = event.target.value;
    event.srcElement.blur();
    event.preventDefault();
    let that = this;
    if (dirty) {
      that.confirm(page);
    }
    else {
      that.router.navigate([that.url + page]);
    }
  }

  goToNext(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo + 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo + 1)]);
    }
  }

  confirm(page: any) {
    let that = this;
    that.confirmationDialogService
      .confirm('Warning - Unsaved changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Leave Page')
      .then((confirmed) => {
        if (confirmed === true) {
          that.router.navigate([that.url + page]);
        }
        else {
          that.pageNo = parseInt(that.route.snapshot.url[0].path);
        }
      });
  }

  openGuidanceNotes() {
    let that = this;
    const modalRef = this.modalService.open(GuidanceNoteFormComponent, { size: 'lg' });
    modalRef.componentInstance.groupRefId = PageReference.Page1;
  }

  downloadApplicationForm() {
      let that = this;
      that.resourceService
          .get("applications/DownloadApplicationForm", that.applicationId)
          .subscribe(response => {
              var blob = this.resourceService.b64toBlob(response.Content, response.ContentType);
              saveAs(blob, response.FileName);
              that.toastr.success('File has been created successfully!', 'Success!');
          },
          (error: any) => {
              that.toastr.error('Something went wrong! Please try again.', 'Oops!');
          });
  }

  reloadForm() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  setBreadcrumbs() {
    let that = this;
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: that.heading, url: '' });
    that.breadcrumbService.set(breadcrumbs);
  }
}

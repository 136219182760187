import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map'
import { Router } from '@angular/router';
import { Login } from './login';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { User, UserInfo, VerifyEmail, VerifyPhone } from '../register-user/User';

@Injectable()
export class LoginService {
  private token: string;
  private resResult: any;
  private isloggedIn: boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient
  ) {
    // set token if saved in local storage
    let currentUser = JSON.parse(localStorage.getItem("currentUser")!);
    this.token = currentUser && currentUser.token;
  }

  login(login: Login): Observable<{ success: boolean; phoneNumber?: string, email?: string }> {
    let url = "token";
    let body =
      "username=" +
      login.UserName +
      "&password=" +
      encodeURIComponent(login.Password) +
      "&grant_type=password" +
      "&Scope=" +
      (login.IsRegistered? "registered":
        login.Code && login.Provider ? login.Code + "," + login.Provider : "");
    return this.http
      .post(url, body)
      .map((response: any) => {
        if (response && response.isNumberNotConfirmed == "true") {
          this.toastr.error("Please complete your registraion process", "");
          return { success: false, email: response.email };
        }
        if (response && response.verification_code) {
          // this.toastr.success(response.verification_code, "");
          localStorage.setItem(
            "code",
            JSON.stringify({
              username: response.userName,
              codeSent: true,
            })
          );
          return { success: false, phoneNumber: response.phoneNumber };
        }
        let token = response && response.access_token;
        if (token) {
          // set token property
          this.token = token;
          this.isloggedIn = true;
          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(
            "currentUser",
            JSON.stringify({
              username: response.userName,
              token: token,
              applicationId: response.applicationId,
              applicationSubmitted: response.applicationSubmitted,
              applicationFinalPayment: response.applicationFinalPayment,
            })
          );
          // return true to indicate successful login
          return { success: true, phoneNumber: response.phoneNumber };
        } else {
          // return false to indicate failed login
          return { success: false, phoneNumber: response.phoneNumber };
        }
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  sendCode(provider :any, username :any): Observable<boolean> {
    let url = "Account/SendCode";
    let body = { UserName: username, Provider: provider };
    return this.http
      .post(url, body)
      .map((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  resendCode(provider :any, username :any, phoneNumber :any, isForgotPassword :any): Observable<boolean> {
    let url = "Account/ReSendCodeConfirmation";
    let body = { UserName: username, Provider: provider, PhoneNumber: phoneNumber, isForgotPassword: isForgotPassword };
    return this.http
      .post(url, body)
      .map((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  verifyEamil(user: VerifyEmail): Observable<string> {
    let url = "Account/VerifyEmail";
    return this.http
      .post(url, user)
      .map((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  confirmEmailCode(user: VerifyEmail): Observable<string> {
    let url = "Account/ConfirmEmail";
    return this.http
      .post(url, user)
      .map((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  createPasswordUser(user: User): Observable<string> {
    let url = "Account/RegisterCreatePassword";
    return this.http
      .post(url, user)
      .map((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  registerUser(user: UserInfo): Observable<boolean> {
    let url = "Account/Register";
    return this.http
      .post(url, user)
      .map((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  verifyPhoneNumber(user: VerifyPhone): Observable<string> {
    let url = "Account/VerifyPhoneNumber";
    return this.http
      .post(url, { Email: user.Email, PhoneNumber: user.PhoneNumber })
      .map((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  confirmPhoneNumber(user: VerifyPhone): Observable<string> {
    let url = "Account/ConfirmPhoneNumber";
    return this.http
      .post(url, user)
      .map((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = null as any;
    this.isloggedIn = false;
    localStorage.removeItem("currentUser");
    localStorage.removeItem("code");
    this.router.navigate(["authentication/login"]);
  }

  reset(): void {
    this.token = null as any;
    this.isloggedIn = false;
    localStorage.removeItem("currentUser");
    localStorage.removeItem("code");
  }
}

import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';

import { LoginService } from '../../authentication/login/login.service';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy {
  yearNow = '';
  private userIdleSubscription!: Subscription;

  constructor(public router: Router,
              private loginService: LoginService,
              private userIdle: UserIdleService) {
    this.yearNow = formatDate(new Date(), 'yyyy', 'en-US');
  }

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  public submitted = false;
  public applicationFinalPayment = false;

  options = {
    theme: 'light',
    dir: 'ltr',
    layout: 'vertical',
    sidebartype: 'full',
    sidebarpos: 'fixed',
    headerpos: 'fixed',
    boxed: 'full',
    navbarbg: 'skin6',
    sidebarbg: 'skin1',
    logobg: 'skin1'
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.submitted = currentUser && (currentUser.applicationSubmitted === 'True');
    this.applicationFinalPayment = currentUser && (currentUser.applicationFinalPayment === 'True');
    if (this.router.url === '/') {
        this.submitted ? this.applicationFinalPayment ? this.router.navigate(['/worldpay/final-payment']) : this.router.navigate(['/worldpay']) : this.router.navigate(['/dashboard']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();

    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdleSubscription = this.userIdle.onTimeout().subscribe(() => {
      this.loginService.logout();
      this.userIdleSubscription.unsubscribe(); // Unsubscribe on timeout
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
    }
  }
}

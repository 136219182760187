import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class ApplicatonPracticeFormService {
  constructor(private http: HttpClient) {
  }

  //get Data
  getData(id: string): Observable<any> {
    return this
      .http
      .get('lookup/applicationpractice/' + id)
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }

  //update Data
  updateData(id: string, data: any): Observable<any> {
    return this
      .http
      .put('lookup/applicationpractice/' + id, data)
      .flatMap((res: any) => {
        return Observable.of(res);
      });
  }
}

<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page5Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-0">
            <label class="mb-0 label-inline" for="IsEmployed">
              If you consider it would be inappropriate for any Panel member(s) to assess your application and/or to interview you, please select 'Yes' below and give your reasons.
            </label>
          </div>
        </div>
      </div>
       
      <div class="row">
        <div class="col-lg-6 order-1">
          <div class="row">
            <div class="col-lg-12">
              <ul class="mb-30 p-0">
                <li class='col-md-12' *ngFor="let ApplicationRecusal of data.ApplicationRecusals | slice:0:breakCount; let i = index">
                  <div class="list-group-item justify-content-between align-items-center">
                    <label>{{ApplicationRecusal.PanelMemberName}}</label>
                    <pb-switch class="required-denote custom-switch" (change)="onChangeToggle()" [(ngModel)]="ApplicationRecusal.IsApplicant" name="col-1-{{i+1}}" id="col-1-{{i+1}}" labelOn="Yes" labelOff="No">
                    </pb-switch>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-6 order-2">
          <div class="row">
            <div class="col-lg-12">
              <ul class="mb-30 p-0">
                <li class='col-md-12' *ngFor="let ApplicationRecusal of data.ApplicationRecusals | slice:breakCount; let i = index">
                  <div class="list-group-item justify-content-between align-items-center">
                    <label>{{ApplicationRecusal.PanelMemberName}}</label>
                    <pb-switch class="required-denote custom-switch" [(ngModel)]="ApplicationRecusal.IsApplicant" (change)="onChangeToggle()" name="col-2-{{i+1}}" id="col-2-{{i+1}}" labelOn="Yes" labelOff="No">
                    </pb-switch>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group textarea-form">
        <label class="control-label mb-0" for="IsEmployed">
          If you have marked any of the above names, please give reasons below:<span class="required" *ngIf="isAnyApplicant == true">&nbsp;*</span>
        </label>
        <textarea maxlength="700" class="form-control input-md mb-0" #RecusalNarrative="ngModel" id="RecusalNarrative" name="RecusalNarrative" [(ngModel)]="data.RecusalNarrative" autosize [required]="isAnyApplicant" [minRows]="5" [maxlength]="700"></textarea>
        <span class="float-right label label-default mb-0" id="counter">{{data.RecusalNarrative ? data.RecusalNarrative.length : 0}} / 700</span>
        <div class="invalid-tooltip textarea-tooltip" *ngIf="RecusalNarrative?.errors?.required && showMessage">This field is required.</div>
        <small class="label-inline"> Please note there is a limit of 700 characters for this box.</small>
      </div>

    </div>
    <div class="card-footer form-card">
      <div class="row">
        <div class="col-md-12 pl-0">
          <p class="required">* denotes required fields </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-2">
                <label for="ID">ID:</label>
              </div>

              <div class="col-md-10">
                <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="Surname">Surname:</label>
              </div>

              <div class="col-md-9">
                <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName"  [maxLength]="50">
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-auto pr-0 cardPagin">
          <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page5Form.dirty)"><i class="fas fa-chevron-left" style="font-size: 16px;"></i></button>
          <label class="pagination-label" for="Surname">Page</label>
          <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page5Form.dirty)">
            <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
          </select>
          <p>of <span>{{pages.length}}</span></p>
          <button type="button" class="btn btn-primary float-left" (click)="goToNext(page5Form.dirty)"><i class="fas fa-chevron-right" style="font-size: 16px;"></i></button>
        </div>
        <div class="col-auto text-right ml-auto pl-0">
          <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
          <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
          <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
        </div>
      </div>
    </div>
  </div>
</form>

export class DateConvertor {
  fromModel(data: any): any {
    if (data == null) {
      return data;
    }
    let date;
    if (typeof (data) === "string") {
      let separators: string[] = ["/", "\\s","T"];
      let dateParts: string[] = data.split(new RegExp(separators.join('|'), 'g'));
      date = { year: +dateParts[2], month: +dateParts[1], day: +dateParts[0] };
    }
    else {
      date = { year: data.year, month: data.month, day: data.day };
    }
    return date;
  }

  toModel(data: any): string {
    if (data == null) {
      return null as any;
    }
    if (typeof data == "string") {
      return null as any;
    }

    let date: string = data.day + "/" + (data.month < 10 ? ("0" + data.month) : data.month) + "/" + data.year;
    return date;
  }

  compareDates(date1: any, date2: any): any {
    let fromModel = this.fromModel(date1);
    let d1: Date = new Date(fromModel.date.year, fromModel.date.month, fromModel.date.day);
    fromModel = this.fromModel(date2);
    let d2: Date = new Date(fromModel.date.year, fromModel.date.month, fromModel.date.day);

    if (d1 < d2) {
      return true;
    }
    return false;
  }
}

import { inject } from '@angular/core';
import { Router, CanMatchFn, Route, UrlSegment } from '@angular/router';

export const SubmittedGuard: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  const router = inject(Router);

  let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
  if (!currentUser) {
    // logged in so return true
    router.navigate(['/authentication/login']);
    return false;
  }
  let submitted = currentUser && (currentUser.applicationSubmitted === 'True');

  if (submitted === route.data?.applicationSubmitted) {
    return true;
  }

  router.navigate(['/authentication/404']);
  return false;
};

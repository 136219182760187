import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service'; 
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { Page11Service } from './page11.service';
import { GuidanceNoteFormComponent } from '../guidance-note-form/guidance-note-form.component';
import { Page11 } from './page11';
import { PageHeadings, Pages } from '../application';
import { ResourceService } from '../../shared/resource.service';
import { PageReference } from '../../shared/constants';
declare var saveAs: any;

@Component({
  selector: 'application-page-11',
  templateUrl: './page11.html'
})
export class Page11Component implements OnInit {
  @ViewChild('page11Form') form! :any;
  applicationId!: string;
  heading: string = PageHeadings.page11;
  pageNo!: number;
  pages: number[] = Pages;
  data: any = {};
  url: string = '/application/page/';
  titles: any[] = [];
  titleOther!: string;
  isSaving: boolean = false;
  showMessage: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private Page11Service: Page11Service,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private resourceService: ResourceService,
    private modalService: NgbModal) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.showMessage = currentUser && currentUser.showMessage ? true : false;
  }

  ngOnInit() {
    let that = this;
    that.pageNo = parseInt(that.route.snapshot.url[0].path);
    that.applicationId = that.route.snapshot.data['applicationId'];
    that.setBreadcrumbs();
    that.Page11Service
      .getData(that.applicationId)
      .subscribe((res: any) => {
        that.data = res;
      });
  }

  save() {
    let that = this;
    that.isSaving = true;
    that.data.IsComplete = this.form.valid;
    that.data.PageNo = that.pageNo;
    that.Page11Service
      .updateData(that.applicationId, that.data)
      .subscribe((res: any) => {
        that.isSaving = false;
        that.toastr.success('Data has been added successfully!', 'Success!');
        that.reloadForm();
      }, error => {
        that.isSaving = false;
      });
  }

  onPageChange(event: any, dirty: any) {
    let page = event.target.value;
    event.srcElement.blur();
    event.preventDefault();
    let that = this;
    if (dirty) {
      that.confirm(page);
    }
    else {
      that.router.navigate([that.url + page]);
    }
  }

  goToPrevious(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo - 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo - 1)]);
    }
  }

  goToNext(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo + 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo + 1)]);
    }
  }

  confirm(page: any) {
    let that = this;
    that.confirmationDialogService
      .confirm('Warning - Unsaved changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Leave Page')
      .then((confirmed) => {
        if (confirmed === true) {
          that.router.navigate([that.url + page]);
        }
        else {
          that.pageNo = parseInt(that.route.snapshot.url[0].path);
        }
      });
  }

  openGuidanceNotes() {
    let that = this;
    const modalRef = this.modalService.open(GuidanceNoteFormComponent, { size: 'lg' });
    modalRef.componentInstance.groupRefId = PageReference.Page11;
  }

  downloadApplicationForm() {
      let that = this;
      that.resourceService
          .get("applications/DownloadApplicationForm", that.applicationId)
          .subscribe(response => {
              var blob = this.resourceService.b64toBlob(response.Content, response.ContentType);
              saveAs(blob, response.FileName);
              that.toastr.success('File has been created successfully!', 'Success!');
          },
          (error: any) => {
              that.toastr.error('Something went wrong! Please try again.', 'Oops!');
          });
  }

  reloadForm() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }


  setBreadcrumbs() {
    let that = this;
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: that.heading, url: '' });
    that.breadcrumbService.set(breadcrumbs);
  }
}

import { Injectable } from '@angular/core';
//import { InterceptedHttp } from "../../../../shared/http.interceptor";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  api: string = "";// Web API URL
  constructor(private http: HttpClient) {
  }

  getAll(url: string): Observable<any> {
      return this.http.get(this.api + url);
  }

  get(url: string, id: any): Observable<any> {
      return this.http.get(this.api + url + "/" + id);
  }

  update(url: string, entity: any): Observable<any> {
      return this.http.put(this.api + url, entity);
  }


  create(url: string, entity: any): Observable<any> {
      return this.http.post(this.api + url, entity);
  }

  delete(url: string, id: number): Observable<any> {
      return this.http.delete(this.api + url + "/" + id)
  }

  b64toBlob(b64Data :any, contentType :any) {
    var sliceSize = 512;
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}

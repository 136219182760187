import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class Page51Service {
  constructor(private http: HttpClient) {
  }

  //get Data
  getData(): Observable<any> {
    return this
      .http
      .get('lookup/page51Data/')
      .flatMap((res: any) => {
        return Observable.of(res);

      })
  }

  //update Data
  updateData(id: string, data: any): Observable<any> {
    return this
      .http
      .put('lookup/page51Data/' + id, data)
      .flatMap((res: any) => {
        return Observable.of(res)
        .catch(error => error);
      });
  }
}

import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../authentication/login/login.service';
declare var $: any;
interface notification{
  btn: string;
  icon: string;
  title: string;
  subject: string;
  time: string;
}
interface massage{
  status: string;
  icon: string;
  title: string;
  subject: string;
  from:string;
  useravatar:string;
  time: string;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();


  public showSearch = false;
  public currentYear: any = new Date().getFullYear() === 2021 ? new Date().getFullYear() + 1 : new Date().getFullYear();


  constructor(
      private modalService: NgbModal,
    private loginService: LoginService
  ) { }

  // This is for Notifications
  notifications: notification[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    //{
    //  btn: 'btn-info',
    //  icon: 'ti-settings',
    //  title: 'Settings',
    //  subject: 'You can customize this template as you want',
    //  time: '9:08 AM'
    //},
    //{
    //  btn: 'btn-primary',
    //  icon: 'ti-user',
    //  title: 'Pavan kumar',
    //  subject: 'Just see the my admin!',
    //  time: '9:00 AM'
    //}
  ];

  // This is for Mymessages
  mymessages: massage[] = [
    //{
    //  useravatar: 'assets/images/users/1.jpg',
    //  status: 'online',
    //  from: 'Pavan kumar',
    //  subject: 'Just see the my admin!',
    //  time: '9:30 AM'
    //},
    //{
    //  useravatar: 'assets/images/users/2.jpg',
    //  status: 'busy',
    //  from: 'Sonu Nigam',
    //  subject: 'I have sung a song! See you at',
    //  time: '9:10 AM'
    //},
    //{
    //  useravatar: 'assets/images/users/2.jpg',
    //  status: 'away',
    //  from: 'Arijit Sinh',
    //  subject: 'I am a singer!',
    //  time: '9:08 AM'
    //},
    //{
    //  useravatar: 'assets/images/users/4.jpg',
    //  status: 'offline',
    //  from: 'Pavan kumar',
    //  subject: 'Just see the my admin!',
    //  time: '9:00 AM'
    //}
  ];

  ngAfterViewInit() { }

  logout(): void {
      this.loginService.logout();
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { LookupService } from '../../shared/lookup.service';  
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { Page15Service } from './page15.service';
import { GuidanceNoteFormComponent } from '../guidance-note-form/guidance-note-form.component';
import { PageHeadings, Pages } from '../application';
import { ResourceService } from '../../shared/resource.service';
import { PageReference } from '../../shared/constants';
declare var saveAs: any;

@Component({
  selector: 'application-page-15',
  templateUrl: './page15.html'
})
export class Page15Component implements OnInit {
  @ViewChild('page15Form') form! :any;
  applicationId!: string;
  applicationAssessorId!: number;
  applicationAssessorId2!: number;
  assessorReference!: string;
  orderNo!: number
  heading: string = PageHeadings.page15;
  pageNo!: number;
  pages: number[] = Pages;
  data: any = {};
  titles: any[] = [];
  regions: any[] = [];
  judicialOffices: any[] = [];
  nominated: any[] = [];
  url: string = '/application/page/';
  isSaving: boolean = false;
  showMessage: boolean = false;
  currentUser: any = JSON.parse(localStorage.getItem('currentUser')!);
  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private page15Service: Page15Service,
    private lookupService: LookupService,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private resourceService: ResourceService,
    private confirmationDialogService: ConfirmationDialogService,
    private scrollToService: ScrollToService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit() {
    let that = this;
    that.showMessage = that.currentUser && that.currentUser.showMessage ? true : false;
    that.pageNo = parseInt(that.route.snapshot.url[0].path);
    that.applicationId = that.route.snapshot.data['applicationId'];
    that.assessorReference = that.route.snapshot.data['assessorReference'];
    that.orderNo = parseInt(that.route.snapshot.data['orderNo']);
    that.setBreadcrumbs();
    let data = that.page15Service.getData(that.applicationId, that.orderNo, that.assessorReference, 333);
    let titles = that.lookupService.getApplicationJudgeTitles();
    let regions = that.lookupService.getRegions();
    let judicialOffices = that.lookupService.getJudicialOffices();
    let nominated = that.lookupService.getNominated();
    forkJoin([data, titles, regions, judicialOffices, nominated])
      .subscribe(results => {
        that.data = results[0];
        that.titles = results[1];
        that.regions = results[2];
        that.judicialOffices = results[3];
        that.nominated = results[4];
      });

  }

  onApplicationAssessorChange() {
    let that = this;
    if (that.applicationAssessorId) {
      that.page15Service
        .getDataById(that.applicationAssessorId)
        .subscribe((res: any) => {
          if (res) {
            that.data.ApplicationAssessor.TitleId = res.TitleId;
            that.data.ApplicationAssessor.LastName = res.LastName;
            that.data.ApplicationAssessor.TitleOther = res.TitleOther;
            that.data.ApplicationAssessor.JudicialOfficeId = res.JudicialOfficeId;
            that.data.ApplicationAssessor.FirstName = res.FirstName;
            that.data.ApplicationAssessor.JudicialOfficeExtra = res.JudicialOfficeExtra;
            that.data.ApplicationAssessor.RegionId = res.RegionId;
            that.data.ApplicationAssessor.IsJudge = res.IsJudge;
            that.data.ApplicationAssessor.IsQC = res.IsQC;
            that.data.ApplicationAssessor.IsSameChamber = res.IsSameChamber;
            that.data.ApplicationAssessor.Telephone = res.Telephone;
            that.data.ApplicationAssessor.Email = res.Email;
            that.data.ApplicationAssessor.Address1 = res.Address1;
            that.data.ApplicationAssessor.IsUK = res.IsUK;
            that.data.ApplicationAssessor.Country = res.Country;
            that.data.ApplicationAssessor.Postcode = res.Postcode;
            that.data.ApplicationAssessor.NominatedId = null;
          }
        });
    }
  }

  onApplicationAssessor2Change() {
    let that = this;
    if (that.applicationAssessorId2) {
      that.page15Service
        .getDataById(that.applicationAssessorId2)
        .subscribe((res: any) => {
          if (res) {
            that.data.ApplicationAssessor2.TitleId = res.TitleId;
            that.data.ApplicationAssessor2.LastName = res.LastName;
            that.data.ApplicationAssessor2.TitleOther = res.TitleOther;
            that.data.ApplicationAssessor2.JudicialOfficeId = res.JudicialOfficeId;
            that.data.ApplicationAssessor2.FirstName = res.FirstName;
            that.data.ApplicationAssessor2.JudicialOfficeExtra = res.JudicialOfficeExtra;
            that.data.ApplicationAssessor2.RegionId = res.RegionId;
            that.data.ApplicationAssessor2.IsJudge = res.IsJudge;
            that.data.ApplicationAssessor2.IsQC = res.IsQC;
            that.data.ApplicationAssessor2.IsSameChamber = res.IsSameChamber;
            that.data.ApplicationAssessor2.Telephone = res.Telephone;
            that.data.ApplicationAssessor2.Email = res.Email;
            that.data.ApplicationAssessor2.Address1 = res.Address1;
            that.data.ApplicationAssessor2.IsUK = res.IsUK;
            that.data.ApplicationAssessor2.Country = res.Country;
            that.data.ApplicationAssessor2.Postcode = res.Postcode;
            that.data.ApplicationAssessor2.NominatedId = null;
          }
        });
    }
  }

  onNominatedChange() {
    let that = this;
    if (that.data.ApplicationAssessor.NominatedId != 480 && that.data.ApplicationAssessor.NominatedId && that.data.ApplicationAssessor2.NominatedId && that.data.ApplicationAssessor.NominatedId == that.data.ApplicationAssessor2.NominatedId) {
     // that.toastr.error('You have already nominated this judicial assessor in case(A). You cannot assign the same judicial assessor in both cases.');
      that.confirmationDialogService
        .confirm('Warning - Message', 'You have already nominated a judicial assessor for this case.', 'Ok', '')
        .then((confirmed) => {
          if (confirmed === true) {
            that.data.ApplicationAssessor.NominatedId = null;
          }
        });
    } else {
      if (that.data.ApplicationAssessor.NominatedId === 336) {
        that.confirmNominated('first');
      }
      else if (that.data.ApplicationAssessor.NominatedId === 337) {
        that.confirmNominated('second');
      }
    }

  }

  onNominated2Change() {
    let that = this;
    if (that.data.ApplicationAssessor2.NominatedId != 480 && that.data.ApplicationAssessor2.NominatedId && that.data.ApplicationAssessor.NominatedId && that.data.ApplicationAssessor2.NominatedId == that.data.ApplicationAssessor.NominatedId) {
     // that.toastr.error('You have already nominated this judicial assessor in upper case. You cannot assign the same judicial assessor in both cases.');
      that.confirmationDialogService
        .confirm('Warning - Message', 'You have already nominated a judicial assessor for this case.', 'Ok', '')
          .then((confirmed) => {
            if (confirmed === true) {
              that.data.ApplicationAssessor2.NominatedId = null;
            }
          });
    } else {
      if (that.data.ApplicationAssessor2.NominatedId === 336) {
        that.confirmNominated2('first');
      }
      else if (that.data.ApplicationAssessor2.NominatedId === 337) {
        that.confirmNominated2('second');
      }
    }
  }

  onAdditionalDisplayChange($event: boolean) {
    let that = this;
    setTimeout(function () {
      if ($event && that.GetIEVersion() < 11) {
        const config: ScrollToConfigOptions = {
          target: 'secondForm',
          offset: -70,
          duration: 200
        };
        that.scrollToService.scrollTo(config);
      }
    }, 0);
  }

  GetIEVersion() {
    let sAgent = window.navigator.userAgent;
    let Idx = sAgent.indexOf("MSIE");

    // If IE, return version number.
    if (Idx > 0)
      return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));

    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./))
      return 11;

    else
      return 0; //It is not IE
  }

  save() {
    let that = this;
    that.isSaving = true;
    that.data.IsComplete = this.form.valid;
    that.data.PageNo = that.pageNo;
    that.page15Service
      .updateData(that.applicationId, that.data)
      .subscribe((res: any) => {
        that.isSaving = false;
        that.toastr.success('Data has been added successfully!', 'Success!');
        that.reloadForm();
      }, error => {
        that.isSaving = false;
      });
  }

  onPageChange(event: any, dirty: any) {
    let page = event.target.value;
    event.srcElement.blur();
    event.preventDefault();
    let that = this;
    if (dirty) {
      that.confirm(page);
    }
    else {
      that.router.navigate([that.url + page]);
    }
  }

  confirm(page: any) {
    let that = this;
    that.confirmationDialogService
      .confirm('Warning - Unsaved changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Leave Page')
      .then((confirmed) => {
        if (confirmed === true) {
          that.router.navigate([that.url + page]);
        }
        else {
          that.pageNo = parseInt(that.route.snapshot.url[0].path);
        }
      });
  }

  confirmNominated(name:any) {
    let that = this;
    that.page15Service
      .getJudge(that.applicationId, 333, that.data.ApplicationAssessor.NominatedId, that.data.ApplicationPractice.ApplicationPracticeId)
      .subscribe((res: any) => {
        if (res) {
          that.confirmationDialogService
            .confirm('Warning - Message', 'You have previously chosen Judge ' + res + ' as the '+ name +' nominated judicial assessor.','OK','')
            .then((confirmed) => {
              if (confirmed === true) {
                that.data.ApplicationAssessor.NominatedId = null;
              }
            });
        }
      });
  }

  confirmNominated2(name:any) {
    let that = this;
    that.page15Service
      .getJudge(that.applicationId, 333, that.data.ApplicationAssessor2.NominatedId, that.data.ApplicationPractice2.ApplicationPracticeId)
      .subscribe((res: any) => {
        if (res) {
          that.confirmationDialogService
            .confirm('Warning - Message', 'You have previously chosen Judge ' + res + ' as the ' + name + ' nominated judicial assessor.', 'OK', '')
            .then((confirmed) => {
              if (confirmed === true) {
                that.data.ApplicationAssessor2.NominatedId = null;
              }
            });
        }
      });
  }

  goToPrevious(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo - 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo - 1)]);
    }
  }

  goToNext(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo + 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo + 1)]);
    }
  }

  openGuidanceNotes() {
    let that = this;
    const modalRef = this.modalService.open(GuidanceNoteFormComponent, { size: 'lg' });
    modalRef.componentInstance.groupRefId = PageReference.Page15;
    modalRef.componentInstance.groupRefId2 = PageReference.Page15a;

  }


  downloadApplicationForm() {
      let that = this;
      that.resourceService
          .get("applications/DownloadApplicationForm", that.applicationId)
          .subscribe(response => {
              var blob = this.resourceService.b64toBlob(response.Content, response.ContentType);
              saveAs(blob, response.FileName);
              that.toastr.success('File has been created successfully!', 'Success!');
          },
          (error: any) => {
              that.toastr.error('Something went wrong! Please try again.', 'Oops!');
          });
  }

  reloadForm() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  setBreadcrumbs() {
    let that = this;
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: that.heading, url: '' });
    that.breadcrumbService.set(breadcrumbs);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { LookupService } from '../../shared/lookup.service';
import { Breadcrumb } from '../../shared/breadcrumb/breadcrumb.model';
import { Page51Service } from './page51.service';
import { DateConvertor } from '../../shared/date-convertor';
import { GuidanceNoteFormComponent } from '../guidance-note-form/guidance-note-form.component';
import { PageHeadings, Pages } from '../application';
import { ResourceService } from '../../shared/resource.service';
import { PageReference } from '../../shared/constants';
declare var saveAs: any;

@Component({
  selector: 'application-page-51',
  templateUrl: './page51.html'
})
export class Page51Component {
  @ViewChild('page51Form') form! :any;
  applicationId!: string;
  heading: string = PageHeadings.page51;
  pageNo!: number;
  pages: number[] = Pages;
  data: any = {};
  IsFreeSchoolMeal:any=['IsFreeSchoolMealYes','IsFreeSchoolMealNo','IsFreeSchoolMealDontKnow','IsFreeSchoolMealPreferNotToSay'];
  IsFreeSchoolStates: any = ['IsSchoolState', 'IsSchoolIndependent', 'IsSchoolIndependentBursary', 'IsSchoolOutsideUk', 'IsSchoolDontKnow', 'IsSchoolPreferNotToSay'];
  IsOccupation: any = ['IsOccupationModern', 'IsOccupationSenior', 'IsOccupationClerical', 'IsOccupationTechnical', 'IsOccupationRoutine', 'IsOccupationUnemployed', 'IsOccupationSmallBusiness', 'IsOccupationOther', 'IsOccupationPreferNotToSay'];
  caringResponsibilities: any = ['IsCaringNone', 'IsCaringUnder18', 'IsCaringDisabledChild', 'IsCaringDisabledAdult', 'IsCaringOlder', 'IsSecondaryCarer', 'CaringPreferNotToSay'];
  genders: any[] = [];
  genderRegisteredBirth: any[] = [];
  workingPattern: any[] = [];
  EthnicOrigins: any[] = [];
  SexualOrientation: any[] = [];
  url: string = '/application/page/';
  isSaving: boolean = false;
  showMessage: boolean = false;
  dateConvertor!: DateConvertor;
  DoBDate: any = "";
  IsFreeSchoolRequired: boolean = true;
  IsSchoolStateRequired: boolean = true; 
  IsHouseholdOccupationRequired: boolean = true;
  IsCaringRequired: boolean = true;
  IsCaringDisabled: boolean = false;
  currentUser: any = JSON.parse(localStorage.getItem('currentUser')!);
  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private page51Service: Page51Service,
    private lookupService: LookupService,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private resourceService: ResourceService,
    private confirmationDialogService: ConfirmationDialogService,
    private config: NgbDatepickerConfig) {
    let today = new Date();
    config.minDate = { year: 1900, month: 1, day: 1 };
    config.maxDate = { year: today.getFullYear(), month: (today.getMonth() + 1), day: today.getDate() };
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit() {
    let that = this;
    that.applicationId = that.route.snapshot.data['applicationId'];
    that.showMessage = that.currentUser && that.currentUser.showMessage ? true : false;
    that.dateConvertor = new DateConvertor();
    that.pageNo = parseInt(that.route.snapshot.url[0].path);
    that.setBreadcrumbs();
    that.page51Service
      .getData()
      .subscribe((res: any) => {
        that.data = res;
        that.DoBDate = that.dateConvertor.fromModel(that.data.DoB);
        that.genders = that.data.vw_LookupGender;
        that.genderRegisteredBirth = that.data.vw_LookupGenderRegisteredAtBirth;
        that.workingPattern = that.data.vw_LookupWorkingPattern;
        that.EthnicOrigins = that.data.vw_LookupEthnicOriginForm;
        that.SexualOrientation = that.data.vw_LookupSexualOrientation;
        that.checkIsFreeSchoolMealRequiredFieldStatus();
        that.checkSchoolStateRequiredFieldStatus();
        that.checkOcupationRequiredFieldStatus();
        that.checkIsCaringRequiredFieldStatus();
      });
  }

  save() {
    let that = this;
    that.isSaving = true;
    that.data.IsComplete = this.form.valid;
    that.data.PageNo = that.pageNo;
    that.data.DoB = that.dateConvertor.toModel(that.DoBDate);
    that.page51Service
      .updateData(that.applicationId, that.data)
      .subscribe((res: any) => {
        that.isSaving = false;
        that.toastr.success('Data has been added successfully!', 'Success!');
        that.reloadForm();
      }, error => {
        that.isSaving = false;
      });
  }
  onDisabilityChange(){
    if(this.data.DisabilityId != 2941){
      this.data.DisabilityImpact = null;
    }
  }
  onIsFreeSchoolMealChange(fieldValue: string) {
    let that = this;
    that.IsFreeSchoolMeal.forEach((fieldKey: any) => {
      if (fieldKey !== fieldValue) {
        that.data[fieldKey] = false;
      }
    });
    setTimeout(() => {
      that.checkIsFreeSchoolMealRequiredFieldStatus()
    }, 200);
    
  }

  checkIsFreeSchoolMealRequiredFieldStatus() {
    let that = this;
    that.IsFreeSchoolRequired = that.IsFreeSchoolMeal.find(( x :any) => that.data[x] == true) ? false : true;
  }

  checkSchoolStateRequiredFieldStatus(key?:string) {
    let that = this;
    setTimeout(() => {
      that.IsSchoolStateRequired = that.IsFreeSchoolStates.find((x: string | number | any) => that.data[x] == true) ? false : true;
    }, 100);
  if(key){
    that.IsFreeSchoolStates.forEach((item :any)=>{
      if(key!==item){
        that.data[item]=false;
      }
    })
  }
    
  }
  
  
  onIsOccupationChange(fieldValue: string) {
    let that = this;
    that.IsOccupation.forEach((fieldKey: any) => {
      if (fieldKey !== fieldValue) {
        that.data[fieldKey] = false;
      }
    });

    setTimeout(() => {
      that.checkOcupationRequiredFieldStatus();
    }, 200);
  }

  checkOcupationRequiredFieldStatus() {
    let that = this;
    that.IsHouseholdOccupationRequired = that.IsOccupation.find((x :any) => that.data[x] == true) ? false : true;
  }

  checkIsCaringRequiredFieldStatus() {
    let that = this;
    that.IsCaringRequired = that.caringResponsibilities.find((x :any) => that.data[x] == true) ? false : true;
    if(that.data['IsCaringNone']==true){
      that.caringResponsibilities.forEach((item :any)=>{
        if('IsCaringNone'!==item){
          that.data[item]=false;
        }
      })
      that.IsCaringDisabled=true;
    }
    else{
      that.IsCaringDisabled=false;
    }
  }

  onPageChange(event: any, dirty: any) {
    let page = event.target.value;
    event.srcElement.blur();
    event.preventDefault();
    let that = this;
    if (dirty) {
      that.confirm(page);
    }
    else {
      that.router.navigate([that.url + page]);
    }
  }

  confirm(page: any) {
    let that = this;
    that.confirmationDialogService
      .confirm('Warning - Unsaved changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Leave Page')
      .then((confirmed) => {
        if (confirmed === true) {
          that.router.navigate([that.url + page]);
        }
        else {
          that.pageNo = parseInt(that.route.snapshot.url[0].path);
        }
      });
  }

  goToPrevious(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo - 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo - 1)]);
    }
  }

  goToNext(dirty: any) {
    let that = this;
    if (dirty) {
      that.confirm((that.pageNo + 1));
    }
    else {
      that.router.navigate([that.url + (that.pageNo + 1)]);
    }
  }

  openGuidanceNotes() {
    let that = this;
    const modalRef = this.modalService.open(GuidanceNoteFormComponent, { size: 'lg' });
    modalRef.componentInstance.groupRefId = PageReference.Page51;
  }

  downloadApplicationForm() {
      let that = this;
      that.resourceService
          .get("applications/DownloadApplicationForm", that.applicationId)
          .subscribe(response => {
              var blob = this.resourceService.b64toBlob(response.Content, response.ContentType);
              saveAs(blob, response.FileName);
              that.toastr.success('File has been created successfully!', 'Success!');
          },
          (error: any) => {
              that.toastr.error('Something went wrong! Please try again.', 'Oops!');
          });
  }

  reloadForm() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  setBreadcrumbs() {
    let that = this;
    let breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({ label: that.heading, url: '' });
    that.breadcrumbService.set(breadcrumbs);
  }
}


<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page51Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body form-card">

      <div class="form-group">
        <p>
          Please complete the below form, which will help us to monitor the diversity of King’s Counsel applicants.
          This information will not be provided to Panel members or assessors and will play no part in the decision 
          on whether you should be recommended for appointment.
        </p>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-5">
                <label>
                  Date of birth<span class="required">&nbsp;*</span>
                </label>
              </div>
              <div class="col-md-6">
                <div class="input-group" (clickOutside)="DoBPicker.close()">
                  <input class="form-control" #DoB="ngModel" #DoBPicker="ngbDatepicker" required id="DoB" name="DoB"
                    [(ngModel)]="DoBDate" ngbDatepicker [ngClass]="{'is-invalid': DoB?.invalid && showMessage}">
                  <div class="input-group-prepend height-40">
                    <div class="input-group-append">
                      <button class="btn" (click)="DoBPicker.toggle()" type="button"><i
                          class="far fa-calendar-alt"></i></button>
                    </div>
                  </div>
                  <div class="invalid-tooltip" *ngIf="DoB?.errors?.required && showMessage">This field is required.</div>
                  <div class="invalid-tooltip" *ngIf="DoBDate && DoB?.invalid">Please enter date in (dd/mm/yyyy).</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5"></div>
        </div>
      </div>
      <div class="form-group">

        <div class="row mt-3">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-5">
                <label class="label-inline">Gender<span>&nbsp;*</span></label>
              </div>
              <div class="col-md-6">
                <ng-select required [items]="genders" #GenderId="ngModel" id="GenderId" name="GenderId"
                bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.GenderId"></ng-select>
              <div class="invalid-tooltip" *ngIf="GenderId?.errors?.required && showMessage">This field is required.</div>
              </div>

            </div>
          </div>
          <div class="col-md-5" *ngIf="data.GenderId === 2950" >
         <div class="row">
          <div class="col-md-4">
            <label class="control-label mb-0">
              If you prefer to use <br> your own gender identity<small class="mb-0">(please
                state here)</small><span>&nbsp;*</span>
            </label>
          </div>
          <div class="col-md-6">
            <input class="form-control input-md" #GenderOther="ngModel" required id="GenderOther" name="GenderOther"
            type="text" [maxLength]="200" [(ngModel)]="data.GenderOther">
          <div class="invalid-tooltip" *ngIf="GenderOther?.errors?.required && showMessage">This field is
            required.</div>
          </div>
         </div>

          </div>
        </div>
       
        <div class="row mt-3">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-5">
                <label class="label-inline">Is the gender you identify with the same as your gender registered at
                  birth?<span>&nbsp;*</span></label>
              </div>
              <div class="col-md-6">
                <ng-select required [items]="genderRegisteredBirth" #GenderRegisteredAtBirthId="ngModel"
                id="GenderRegisteredAtBirthId" name="GenderRegisteredAtBirthId" bindLabel="LookupValue"
                bindValue="LookupId" [(ngModel)]="data.GenderRegisteredAtBirthId"></ng-select>
              <div class="invalid-tooltip" *ngIf="GenderRegisteredAtBirthId?.errors?.required && showMessage">This field
                is required.</div>
              </div>

            </div>
          </div>
          <div class="col-md-5"></div>
        </div>
         
        <div class="row mt-3">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-5">
                <p class="mb-0">
                  What is your ethnicity? <small class="mb-0">
                    (Ethnic origin is not about nationality, place of birth or
                    citizenship. It is about the group to which you perceive you belong, please select from the
                    list)
                  </small><span class="required">*</span>
                </p>
              </div>
              <div class="col-md-6">
                <ng-select [items]="EthnicOrigins" required id="EthnicOriginId" name="EthnicOriginId"
                           bindLabel="LookupValue" bindValue="LookupId" groupBy="LookupValueShow" [multiple]="false"
                           #EthnicOriginId="ngModel" [(ngModel)]="data.EthnicOriginId">
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{item.LookupValueShow || 'Unnamed group'}}
                  </ng-template>
                </ng-select>
                <div class="invalid-tooltip" *ngIf="EthnicOriginId?.errors?.required && showMessage">
                  This field is
                  required.
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-5" *ngIf="data.EthnicOriginId == 429 || data.EthnicOriginId == 512 || data.EthnicOriginId == 513 || data.EthnicOriginId == 514 || data.EthnicOriginId == 2629">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label mb-0">
                  Other<small class="mb-0">(please state)</small><span>&nbsp;*</span>
                </label>
              </div>
              <div class="col-md-6">
                <input class="form-control input-md" #EthnicOther="ngModel" required id="EthnicOther" name="EthnicOther"
                       type="text" [maxLength]="200" [(ngModel)]="data.EthnicOther">
                <div class="invalid-tooltip" *ngIf="EthnicOther?.errors?.required && showMessage">
                  This field is
                  required.
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="form-group mt-3">

          <div class="row">
            <div class="col-md-7">
            <div class="row">
              <div class="col-md-5">
                <p>
                  <Strong>
                    Do you consider yourself to have a disability as defined in the Equality Act 2010?
                  </Strong>
                  <span style="color: #f62d51;">&nbsp;*</span>
                  <small class="mb-0">
                    (The Equality Act defines a person with a disability as someone who has 'a physical or mental
                    impairment, which has a substantial long-term adverse effect on [their] ability to carry out normal
                    day-to-day activities'. Long term means 12 months or more)
                  </small>
                </p>
              </div>
              <div class="col-md-6">
                <ng-select required [items]="data.vw_LookupDisability" #DisabilityId="ngModel" id="DisabilityId"
                name="DisabilityId" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.DisabilityId"
                (ngModelChange)="onDisabilityChange()"></ng-select>
              <div class="invalid-tooltip" *ngIf="DisabilityId?.errors?.required && showMessage">This field
                is required.</div>
            </div>
            </div>
         
             
            </div>
  
          <div class="col-md-5"></div>
  
            </div>
  
           
          <div class="row mt-3">
            <div class="col-md-12">
              <p>
                <Strong>
                What is the effect or impact of your disability on your work? Please write below:
                </Strong>
                <span *ngIf="data.DisabilityId == 2941" style="color: #f62d51;">&nbsp;*</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-11">
                  <textarea maxlength="500" class="form-control input-sm mb-2" #DisabilityImpact="ngModel" [readOnly]="data.DisabilityId != 2941"
                  [required]="data.DisabilityId == 2941" id="DisabilityImpact" name="DisabilityImpact"
                  [(ngModel)]="data.DisabilityImpact"
                  [ngClass]="{'is-invalid': DisabilityImpact?.invalid && showMessage}" autosize
                  [minRows]="5"></textarea>
                <div class="invalid-tooltip" *ngIf="DisabilityImpact?.errors?.required && showMessage">This field is
                  required. </div>
                <span *ngIf="data.DisabilityId === 2941" class="float-right label label-default "
                  id="counterImpact">{{data.DisabilityImpact ?
                  data.DisabilityImpact.length : 0}} / 500</span>
                <div *ngIf="data?.DisabilityImpact?.length > 0">
                  <small class="label-inline">Please note there is a limit of 500 characters for this box</small>
                </div>
                <strong>
                  The information provided is for monitoring purposes only. Any applicant with particular needs should
                  contact the Secretariat, who will make every effort to assist.
                </strong>
                </div>
              </div>
            </div>
            <div class="col-md-5"></div>
  
          </div>
  
  
          <div class="row mt-3">
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-5">
                  <p>
                    What is your sexual orientation?<span style="color: #f62d51;">&nbsp;*</span>
                  </p>
                </div>
  
                <div class="col-md-6">
                  <ng-select  [items]="SexualOrientation" #SexualOrientationId="ngModel" id="SexualOrientationId"
                    name="SexualOrientationId" required bindLabel="LookupValue" bindValue="LookupId"
                    [(ngModel)]="data.SexualOrientationId"></ng-select>
                </div>
              </div>
            </div>
  
            <div class="col-md-5" *ngIf="data.SexualOrientationId == 2619">
              <div class="row">
                <div class="col-md-4">
                  <label class="control-label mb-0">
                    If you prefer to use your own identity<small class="mb-0">(please
                      state here)</small><span>&nbsp;*</span>
                  </label>
                </div>
  
                <div class="col-md-6">
                  <input class="form-control input-md" #SexualOrientationOther="ngModel" required
                    id="SexualOrientationOther" name="SexualOrientationOther" type="text" [maxLength]="50"
                    [(ngModel)]="data.SexualOrientationOther">
  
                  <div class="invalid-tooltip" *ngIf="SexualOrientationOther?.errors?.required && showMessage">This field
                    is required.</div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
  

        <div class="row mt-3">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-5">
            <label class="label-inline">What is your working pattern?<span>&nbsp;*</span></label>

              </div>
              <div class="col-md-6">
                <ng-select required [items]="workingPattern" #WorkingPatternId="ngModel" id="WorkingPatternId"
                name="WorkingPatternId" bindLabel="LookupValue" bindValue="LookupId"
                [(ngModel)]="data.WorkingPatternId"></ng-select>
              <div class="invalid-tooltip" *ngIf="WorkingPatternId?.errors?.required && showMessage">This field is
                required.</div>
              </div>

            </div>
          </div>
          <div class="col-md-5"></div>
        </div>
       

        <div class="row mt-3">
          <div class="col-md-12">
            <label class="label-inline"><strong>
              Do you have caring responsibilities? If yes, please tick all that
              apply
            </strong><span>&nbsp;*</span></label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-7">
            <ul>
              <li class="label-inline">None</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class="input-md" #IsCaringNone="ngModel"  id="IsCaringNone" name="IsCaringNone"
              type="checkbox" [required]="IsCaringRequired" [(ngModel)]="data.IsCaringNone" (change)="checkIsCaringRequiredFieldStatus()">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">Primary carer of a child/children (under 18)</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" #IsCaringUnder18="ngModel"  id="IsCaringUnder18" name="IsCaringUnder18"
              type="checkbox" [required]="IsCaringRequired" [disabled]="IsCaringDisabled" [(ngModel)]="data.IsCaringUnder18" (change)="checkIsCaringRequiredFieldStatus()">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Primary carer of disabled child/children
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class="input-md" #IsCaringDisabledChild="ngModel"  id="IsCaringDisabledChild"
              name="IsCaringDisabledChild" [required]="IsCaringRequired" [disabled]="IsCaringDisabled" type="checkbox" [(ngModel)]="data.IsCaringDisabledChild" (change)="checkIsCaringRequiredFieldStatus()">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">Primary carer of disabled adult (18 and over)</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" #IsCaringDisabledAdult="ngModel"  id="IsCaringDisabledAdult"
              name="IsCaringDisabledAdult" [required]="IsCaringRequired" [disabled]="IsCaringDisabled" type="checkbox" [(ngModel)]="data.IsCaringDisabledAdult" (change)="checkIsCaringRequiredFieldStatus()">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">Primary carer of older person</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" #IsCaringOlder="ngModel"  id="IsCaringOlder" name="IsCaringOlder"
              type="checkbox" [required]="IsCaringRequired" [disabled]="IsCaringDisabled" [(ngModel)]="data.IsCaringOlder" (change)="checkIsCaringRequiredFieldStatus()">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Secondary carer (another person carries out the main caring role)
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsCaringRequired" [disabled]="IsCaringDisabled" #IsSecondaryCarer="ngModel"  id="IsSecondaryCarer" name="IsSecondaryCarer"
              type="checkbox" [(ngModel)]="data.IsSecondaryCarer" (change)="checkIsCaringRequiredFieldStatus()">
           
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">Prefer not to say</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsCaringRequired" [disabled]="IsCaringDisabled" #CaringPreferNotToSay="ngModel"  id="CaringPreferNotToSay"
              name="CaringPreferNotToSay" type="checkbox" [(ngModel)]="data.CaringPreferNotToSay" (change)="checkIsCaringRequiredFieldStatus()">
          </div>
        </div>

        

        <div class="row mt-3">
          <div class="col-12 ">
            <label class="label-inline">
              <strong>
                What was the occupation of your main household earner when you were aged about
                14? Please indicate below:
              </strong>
              <span>&nbsp;*</span>
            </label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-7">
            <ul>
              <li class="label-inline">
                Modern professional & traditional professional occupations such as: teacher,
              nurse, physiotherapist, social worker, musician, police officer (sergeant or above), software designer,
              accountant, solicitor, medical practitioner, scientist, civil / mechanical engineer
              </li>
            </ul>
           
          </div>

          <div class="col-md-5">
            <input class="input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationModern="ngModel"  id="IsOccupationModern"
              name="IsOccupationModern" type="checkbox" [(ngModel)]="data.IsOccupationModern"
              (change)="onIsOccupationChange('IsOccupationModern')">
          
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Senior, middle, or junior managers or administrators such as: finance manager,
                chief executive, large business owner, office manager, retail manager, bank manager, restaurant manager,
                warehouse manager
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationSenior="ngModel"  id="IsOccupationSenior"
              name="IsOccupationSenior" type="checkbox" [(ngModel)]="data.IsOccupationSenior"
              (change)="onIsOccupationChange('IsOccupationSenior')">
            
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Clerical and intermediate occupations such as: secretary, personal assistant,
                call centre agent, clerical worker, nursery nurse 
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class="input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationClerical="ngModel"  id="IsOccupationClerical"
              name="IsOccupationClerical" type="checkbox" [(ngModel)]="data.IsOccupationClerical"
              (change)="onIsOccupationChange('IsOccupationClerical')">
           
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Technical and craft occupations such as: motor mechanic, plumber, printer,
                electrician, gardener, train driver
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationTechnical="ngModel"  id="IsOccupationTechnical"
              name="IsOccupationTechnical" type="checkbox" [(ngModel)]="data.IsOccupationTechnical"
              (change)="onIsOccupationChange('IsOccupationTechnical')">
            
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-7">
            <ul>
              <li class="label-inline">Routine, semi-routine manual and service occupations such as: postal worker,
                machine operative, security guard, caretaker, farm worker, catering assistant, sales assistant, HGV
                driver, cleaner, porter, packer, labourer, waiter/waitress, bar staff</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationRoutine="ngModel"  id="IsOccupationRoutine"
              name="IsOccupationRoutine" type="checkbox" [(ngModel)]="data.IsOccupationRoutine"
              (change)="onIsOccupationChange('IsOccupationRoutine')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Long-term unemployed (claimed Jobseeker’s Allowance or earlier unemployment
                benefit for more than a year)
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationUnemployed="ngModel"  id="IsOccupationUnemployed"
              name="IsOccupationUnemployed" type="checkbox" [(ngModel)]="data.IsOccupationUnemployed"
              (change)="onIsOccupationChange('IsOccupationUnemployed')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Small business owners who employed less than 25 people such as: corner shop
              owners, small plumbing companies, retail shop owner, single restaurant or cafe owner, taxi owner, garage
              owner
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationSmallBusiness="ngModel"  id="IsOccupationSmallBusiness"
              name="IsOccupationSmallBusiness" type="checkbox" [(ngModel)]="data.IsOccupationSmallBusiness"
              (change)="onIsOccupationChange('IsOccupationSmallBusiness')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Other such as: retired, this question does not apply to me, I don’t know
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationOther="ngModel"  id="IsOccupationOther"
              name="IsOccupationOther" type="checkbox" [(ngModel)]="data.IsOccupationOther"
              (change)="onIsOccupationChange('IsOccupationOther')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">Prefer not to say</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsHouseholdOccupationRequired" #IsOccupationPreferNotToSay="ngModel"  id="IsOccupationPreferNotToSay"
              name="IsOccupationPreferNotToSay" type="checkbox" [(ngModel)]="data.IsOccupationPreferNotToSay"
              (change)="onIsOccupationChange('IsOccupationPreferNotToSay')">
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-6 ">
            <label class="label-inline">
              <Strong>Which type of school did you attend for the most time between the ages of 11 and
              16?</Strong><span>&nbsp;*</span></label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">State-run or state-funded school</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class="input-md" [required]="IsSchoolStateRequired" #IsSchoolState="ngModel"  id="IsSchoolState" name="IsSchoolState"
              type="checkbox" [(ngModel)]="data.IsSchoolState" (change)="checkSchoolStateRequiredFieldStatus('IsSchoolState')">
           
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">Independent or fee-paying school</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsSchoolStateRequired" #IsSchoolIndependent="ngModel"  id="IsSchoolIndependent"
              name="IsSchoolIndependent" type="checkbox" [(ngModel)]="data.IsSchoolIndependent" (change)="checkSchoolStateRequiredFieldStatus('IsSchoolIndependent')">
          </div>
        </div>
       

        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Independent or fee-paying school, where I received a means-tested bursary
                  covering 90% or more of the overall cost of attending throughout my time there  </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class="input-md" [required]="IsSchoolStateRequired" #IsSchoolIndependentBursary="ngModel"  id="IsSchoolIndependentBursary"
              name="IsSchoolIndependentBursary" type="checkbox" [(ngModel)]="data.IsSchoolIndependentBursary" (change)="checkSchoolStateRequiredFieldStatus('IsSchoolIndependentBursary')">
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">Attended school outside the UK</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class="input-md" [required]="IsSchoolStateRequired" #IsSchoolOutsideUk="ngModel"  id="IsSchoolOutsideUk"
              name="IsSchoolOutsideUk" type="checkbox" [(ngModel)]="data.IsSchoolOutsideUk" (change)="checkSchoolStateRequiredFieldStatus('IsSchoolOutsideUk')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">I don’t know</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsSchoolStateRequired" #IsSchoolDontKnow="ngModel"  id="IsSchoolDontKnow" name="IsSchoolDontKnow"
              type="checkbox" [(ngModel)]="data.IsSchoolDontKnow" (change)="checkSchoolStateRequiredFieldStatus('IsSchoolDontKnow')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">Prefer not to say</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" [required]="IsSchoolStateRequired" #IsSchoolPreferNotToSay="ngModel"  id="IsSchoolPreferNotToSay"
              name="IsSchoolPreferNotToSay" type="checkbox" [(ngModel)]="data.IsSchoolPreferNotToSay" (change)="checkSchoolStateRequiredFieldStatus('IsSchoolPreferNotToSay')">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 ">

            <label class="label-inline"><strong>
              If you finished school after 1980, were you eligible for free school meals at
              any point during your school years?
            </strong><span>&nbsp;*</span></label>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Yes
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class="input-md" #IsFreeSchoolMealYes="ngModel"  id="IsFreeSchoolMealYes"
              name="IsFreeSchoolMealYes" [required]="IsFreeSchoolRequired" type="checkbox" [(ngModel)]="data.IsFreeSchoolMealYes"
              (change)="onIsFreeSchoolMealChange('IsFreeSchoolMealYes')">
           
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">No</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" #IsFreeSchoolMealNo="ngModel"  id="IsFreeSchoolMealNo"
              name="IsFreeSchoolMealNo" [required]="IsFreeSchoolRequired" type="checkbox" [(ngModel)]="data.IsFreeSchoolMealNo"
              (change)="onIsFreeSchoolMealChange('IsFreeSchoolMealNo')">
            
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">I don’t know</li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class="input-md" #IsFreeSchoolMealDontKnow="ngModel"  id="IsFreeSchoolMealDontKnow"
              name="IsFreeSchoolMealDontKnow" [required]="IsFreeSchoolRequired" type="checkbox" [(ngModel)]="data.IsFreeSchoolMealDontKnow"
              (change)="onIsFreeSchoolMealChange('IsFreeSchoolMealDontKnow')">
           
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-7 ">
            <ul>
              <li class="label-inline">
                Prefer not to say
              </li>
            </ul>
          </div>

          <div class="col-md-5">
            <input class=" input-md" #IsFreeSchoolMealPreferNotToSay="ngModel" 
              id="IsFreeSchoolMealPreferNotToSay" [required]="IsFreeSchoolRequired" name="IsFreeSchoolMealPreferNotToSay" type="checkbox"
              [(ngModel)]="data.IsFreeSchoolMealPreferNotToSay"
              (change)="onIsFreeSchoolMealChange('IsFreeSchoolMealPreferNotToSay')">
          </div>
        </div>

      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-md-12 pl-0">
            <p class="required">* denotes required fields </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-2 col-sm-6 col-12">
            <div class="form-group">
              <div class="row">
                <div class="col-md-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-md-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6 col-12">
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-md-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [maxLength]="50"
                    [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-auto pr-0 cardPagin">
            <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page51Form.dirty)">
              <i class="fas fa-chevron-left" style="font-size: 16px;"></i>
            </button>
            <label class="pagination-label" for="Surname">Page</label>
            <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo"
              (change)="onPageChange($event, page51Form.dirty)">
              <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
            </select>
            <p>of <span>{{pages.length}}</span></p>
            <button type="button" class="btn btn-primary float-left" (click)="goToNext(page51Form.dirty)"><i
                class="fas fa-chevron-right" style="font-size: 16px;"></i></button>
          </div>

          <div class="col-auto text-right ml-auto pl-0">
            <button type="submit" [ladda]="isSaving" [disabled]="DoBDate && DoB?.invalid"
              class="btn btn-primary save">Save</button>
            <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
            <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11=""
                class="fa fa-file-pdf"></i> Create PDF</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>

import { Routes } from '@angular/router';
import { WorldPayComponent } from './worldpay.component';
import { FinalPaymentComponent } from './final-payment/final-payment.component';

export const WorldPayRoutes: Routes = [
    {
        path: 'worldpay',
        redirectTo: '/worldpay/info',
        pathMatch: 'full'
    },
    {
        path: 'info',
        component: WorldPayComponent      
    },
    {
        path: 'final-payment',
        component: FinalPaymentComponent,
    }
];

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

@Injectable()
export class ApplicationResolver implements Resolve<any> {
  constructor() {
  }

  resolve() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    return currentUser && currentUser.applicationId;
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GuidanceNoteService } from "./guidance-note-form.service";

@Component({
  selector: 'app-guidance-note-form',
  templateUrl: './guidance-note-form.component.html'
})
export class GuidanceNoteFormComponent implements OnInit {
  notes: any[] = [];
  @Input() groupRefId!: number;
  @Input() groupRefId2!: number;
  constructor(public activeModal: NgbActiveModal,
    public guidanceNoteService: GuidanceNoteService) {
  }

  ngOnInit() {
    let that = this;
    if (that.groupRefId2) {
      that.guidanceNoteService
        .getDataByIds(that.groupRefId, that.groupRefId2)
        .subscribe((res: any) => {
          that.notes = res;
        });
    }
    else {
      that.guidanceNoteService
        .getData(that.groupRefId)
        .subscribe((res: any) => {
          that.notes = res;
        });
    }
  }
}

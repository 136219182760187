<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page52Form="ngForm" (ngSubmit)="page52Form.form.valid && confirmSubmission()">
  <div class="card custom-card">
    <div class="card-body form-card">
      <p>Please ensure you have completed all sections of the form before signing the declaration and form of authority below.</p>
      <div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label>Professional surname</label>
          </div>

          <div class="col-md-4">
            <input type="text" readonly class="form-control" #LastName="ngModel" id="LastName" name="LastName" [maxLength]="50" [(ngModel)]="data.LastName" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label>Forenames in full</label>
          </div>

          <div class="col-md-4">
            <input type="text" readonly class="form-control" #FirstName="ngModel" id="FirstName" name="FirstName" [maxLength]="50" [(ngModel)]="data.FirstName" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label>Name of chambers, firm or employer</label>
          </div>

          <div class="col-md-4">
            <input type="text" readonly class="form-control" #Chambers="ngModel" id="Chambers" name="Chambers" [maxLength]="200" [(ngModel)]="data.Chambers" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label>Your Law Society roll number/Bar Council membership number</label>
          </div>

          <div class="col-md-3">
            <input type="text" readonly class="form-control" #Reference="ngModel" id="Reference" name="Reference" [maxLength]="9" [(ngModel)]="data.Reference" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label>Date of call or admission into current branch of the profession</label>
          </div>

          <div class="col-md-3">
            <input type="text" readonly class="form-control" #BarDate="ngModel" id="BarDate" name="BarDate" [(ngModel)]="data.BarDate" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="d-block"><b>Declaration</b></label>
          <label class="d-block">
            I declare by my signature below and submitting this application online, that to the best of my knowledge, the information I have given on this form is correct
            and complete.
          </label>
          <label class="d-block">
            I further declare that there is nothing in my professional background (other than what I have disclosed in this application) which if brought into the public
            domain could affect my suitability for appointment or bring the legal profession or King's Counsel into disrepute.
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="d-block"><b>Form of authority</b></label>
          <label class="d-block">
            I authorise (the submission of the form being itself authority) King's Counsel Appointments Limited, to make such enquiries with the Bar Standards Board, the Solicitors Regulation Authority or any other professional regulatory body in England and Wales or elsewhere,
            and/or the Legal Ombudsman as they consider necessary regarding my professional disciplinary record and/or complaint information. I consent to the disclosure of any personal, special category or crime data by the Bar Standards Board and/or the Solicitors Regulation
            Authority or any other professional regulatory bodies that they hold in connection with any reports/allegations about me to King's Counsel Appointments Ltd.
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label>Signature of the applicant<span>&nbsp;*</span></label>
          </div>

          <div class="col-md-4">
            <input type="text" readonly class="form-control" #Signature="ngModel" required id="Signature" name="Signature" [maxLength]="100" [(ngModel)]="data.Signature" [ngClass]="{'is-invalid': Signature?.invalid && showMessage}" />
            <div class="invalid-tooltip" *ngIf="Signature?.errors?.required && showMessage">This field is required.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label>Date signed<span>&nbsp;*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" readonly class="form-control" #SignatureDate="ngModel" required id="SignatureDate" name="SignatureDate" [(ngModel)]="data.SignatureDate" [ngClass]="{'is-invalid': SignatureDate?.invalid && showMessage}" />
            <div class="invalid-tooltip" *ngIf="SignatureDate?.errors?.required && showMessage">This field is required.</div>
          </div>

        </div>
        <div class="row" style="margin-top:8px">
          <div class="col-md-5">

          </div>
          <div class="col-md-4" style="text-align:center">
            <button type="button" class="btn btn-primary" (click)="OnSignin()">CLICK HERE TO DIGITALLY SIGN AND DATE YOUR APPLICATION FORM</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="d-block"><b>Submission</b><br /></label>
          <label class="d-block">
            By submitting this application form you're confirming that the information provided is correct? Once submitted you will no longer be able to access the online application form.
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="d-block"><b>Fee payment</b></label>
          <label class="d-block">
            Your application must be accompanied by the fee. The fee is £2,520 (£2,100 + VAT) except for those with low incomes for whom a concessionary fee of £1,260 (£1,050 + VAT) applies.
          </label>
        </div>
      </div>

      <!--<div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label class="control-label" for="Payment method ">Payment method<span>&nbsp;*</span></label>
          </div>
          <div class="col-md-4">
            <ng-select [items]="payments" required #ApplicationPaymentTypeId="ngModel" id="ApplicationPaymentTypeId" name="ApplicationPaymentTypeId" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.ApplicationPaymentTypeId" [ngClass]="{'is-invalid': ApplicationPaymentTypeId?.invalid && showMessage}"></ng-select>
            <div class="invalid-tooltip" *ngIf="ApplicationPaymentTypeId?.errors?.required && showMessage">This field is required.</div>
          </div>
        </div>
      </div>-->

      <div class="form-group">
        <div class="form-group">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label" for="Payment amount">Payment amount<span>&nbsp;*</span></label>
            </div>
            <div class="col-md-4">
              <ng-select [items]="paymentAmounts" required #PaymentAmount="ngModel" id="PaymentAmount" name="PaymentAmount" bindLabel="PaymentAmount" bindValue="LookupExtraDecimal" [(ngModel)]="data.PaymentAmount" [ngClass]="{'is-invalid': PaymentAmount?.invalid && showMessage}"></ng-select>
              <div class="invalid-tooltip" *ngIf="PaymentAmount?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="(data.ApplicationPaymentTypeId == 2705)">
        <div class="col-md-12">
          <label class="d-block"><b>Debit card</b></label>
          <label class="d-block">
            Following submission of this application form you will be taken to the WorldPay website to complete payment.
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="d-block"><b>Electronic Bank Payment</b></label>
          <label class="d-block">
            Payment Details
          </label>
          <label class="d-block">
            Bank Name:                Child & Co  <br />
            Account Name:             KC Appointments <br />
            Sort Code:                15-80-00  <br />
            Account number:           10578135  <br /> <br />
            Please ensure that you add your surname and the Bar Membership or Law Society number you have used in your application to the payment reference.
          </label>
        </div>
      </div>
    </div>

    <div class="card-footer form-card">
      <div class="row">
        <div class="col-lg-12">
          <p class="required">* denotes required fields </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-2">
                <label for="ID">ID:</label>
              </div>

              <div class="col-md-10">
                <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="Surname">Surname:</label>
              </div>

              <div class="col-md-9">
                <input type="text" id="Surname" name="Surname" readonly class="form-control" [maxLength]="50" [(ngModel)]="data.LastName">
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-auto pr-0 cardPagin">
          <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page52Form.form.dirty)">
            <i class="fas fa-chevron-left" style="font-size: 16px;"></i>
          </button>
          <label class="pagination-label" for="Surname">Page</label>
          <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page52Form.form.dirty)">
            <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
          </select>
          <p>
            of
            <span>{{pages.length}}</span>
          </p>
          <button type="button" disabled class="btn btn-primary float-left">
            <i class="fas fa-chevron-right" style="font-size: 16px;"></i>
          </button>
        </div>

        <div class="col-auto text-right ml-auto custom-button-group pl-0">
          <button type="submit" [disabled]="!page52Form.form.valid" [ladda]="isSaving" class="btn btn-primary save">Submit application</button>
          <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
          <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
        </div>
      </div>
    </div>
  </div>
</form>

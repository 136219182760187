import { inject } from '@angular/core';
import { Router, CanMatchFn } from '@angular/router';

export const AuthGuard: CanMatchFn = () => {
  const router = inject(Router);

  if (localStorage.getItem('currentUser')) {
    // logged in so return true
    return true;
  }

  // not logged in so redirect to login page and return false
  router.navigate(['/authentication/login']);
  return false;
};

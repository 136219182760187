import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class Page14Service {
  constructor(private http: HttpClient, ) {
  }

  //get Data
  getData(id: string): Observable<any> {
    return this
      .http
      .get('applications/practices_applicationpractice/' + encodeURIComponent(id))
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }

  //update Data
  updateData(id: string, data: any): Observable<any> {
    return this
      .http
      .put('applications/practices_applicationpractice/' + encodeURIComponent(id), data)
      .flatMap((res: any) => {
        return Observable.of(res)
        .catch(error => error);
      });
  }

  //Call sp
  callSp(id: string, isActive: boolean): Observable<any> {
    return this
      .http
      .post('applications/practices_applicationpracticeactive/' + encodeURIComponent(id), isActive)
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }
} 

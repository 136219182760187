<form #form="ngForm" (ngSubmit)="save()">
  <ngx-loading [show]="(data | json) == '{}' || roles.length == 0"></ngx-loading>
  <div [hidden]="(data | json) == '{}' || roles.length == 0" class="card formDialogue card-primary">
    <div class="card-header">
      <h3 class="card-title">Case # {{data.OrderNo}}</h3>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label class="control-label" for="NameOfCase">Name of case<span>&nbsp;*</span></label>
        <input class="form-control input-md" #NameOfCase="ngModel" required id="NameOfCase" name="NameOfCase" type="text" [(ngModel)]="data.NameOfCase" [ngClass]="{'is-invalid': NameOfCase?.invalid && showMessage }">
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-12">
            <label class="control-label" for="RoleId">Role as advocate<span>&nbsp;*</span></label>
            <ng-select [items]="roles" #RoleId="ngModel" required id="RoleId" name="RoleId" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.RoleId" [ngClass]="{'is-invalid': RoleId?.invalid && showMessage }">
            </ng-select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="control-label" for="StartDate">Start Date (mm/yy)<span>&nbsp;*</span></label>
        <input class="form-control input-md" mask="00/00" [dropSpecialCharacters]="false" [showMaskTyped]="true" pattern="(1[0-2]|0[1-9])\/\d\d" #StartDate="ngModel" required id="StartDate" name="StartDate" type="text" [(ngModel)]="data.StartDate" [ngClass]="{'is-invalid': StartDate?.invalid && showMessage }">
      </div>

      <div class="form-group">
        <label class="control-label" for="EndDate">End Date (mm/yy)</label>
        <input class="form-control input-md" mask="00/00" [dropSpecialCharacters]="false" [showMaskTyped]="true" pattern="(1[0-2]|0[1-9])\/\d\d" #EndDate="ngModel" id="EndDate" name="EndDate" type="text" [(ngModel)]="data.EndDate" [ngClass]="{'is-invalid': EndDate?.invalid && showMessage }">
      </div>

      <div class="form-group">
        <label class="control-label label-inline" for="EndDate">Brief description of case<span>&nbsp;*</span></label>
        <textarea maxlength="1000" autosize [minRows]="8" class="textarea-control input-md mb-0" required #BriefOfCase="ngModel" id="BriefOfCase" name="BriefOfCase" type="text" [(ngModel)]="data.BriefOfCase" [ngClass]="{'is-invalid': BriefOfCase?.invalid && showMessage }"></textarea>
        <span class="float-right label label-default" id="counter">{{data.BriefOfCase ? data.BriefOfCase.length : 0}} / 1000</span>
        <small class="label-inline">Please note there is a limit of 1000 characters for this box</small>
      </div>

    </div>
    <div class="text-right card-footer">
      <p class="required">* denotes required fields </p>
      <button type="button" class="btn btn-default btn-primary m-0" (click)="close()">Close</button>
      <button [ladda]="isSaving" [disabled]="!form.valid" type="submit" class="btn btn-primary saveDial m-0">Save</button>
    </div>
  </div>
</form>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root',
})
export class WorldPayService {
  constructor(private http: HttpClient) {
  }

  //get Data
  getData(id: string): Observable<any> {
    return this
      .http
      .get('worldpay/' + id)
      .flatMap((res: any) => {
        return Observable.of(res);
      })
  }

  //post payment
  postPayment(obj :any, url :any) {
    var mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST";
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }
}

<div class="custom-card">
  <div class="card-header bg-primary">
    <h3 class="card-title text-white">Guidance Extract</h3>
  </div>
  <div class="card-body">
    <!-- <ngx-loading [show]="notes.length == 0"></ngx-loading> -->
    <table [hidden]="notes.length == 0" class="table table-bordered table-striped">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Note</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let note of notes">
          <th scope="row">{{note.NoteNumber}}</th>
          <td [innerHTML]="note.Note"></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-right card-footer">
    <button type="button" class="btn m-0" (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>

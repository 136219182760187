<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page14Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body form-card">
      <p class="mb-0 pl-15">
        Please list below 12 cases of substance that you have dealt with as advocate, preferably in the past three years (i.e. in 2021 or since). For each case please indicate the role you played and the dates of your involvement. This information is automatically transferred to Section I where you will be asked to provide details of one judicial, practitioner and one client assessor for each case.
      </p>
      <div class="row">
        <div class=" table-container">
          <table class="table table-bordered table-responsive-md table-primary">
            <thead>
              <tr>
                <th rowspan="2">#</th>
                <th rowspan="2">Action</th>
                <th rowspan="2">Name of case</th>
                <th rowspan="2">Role as advocate</th>
                <th class="dataCenter" colspan="2" scope="colgroup">Dates of your involvement</th>
                <th rowspan="2">Are you naming a case?</th>
              </tr>
              <tr>
                <th class="dataCenter" scope="col">Start (mm/yy)</th>
                <th class="dataCenter" scope="col">End (mm/yy)</th>
              </tr>
            </thead>
            <tbody>
              <tr [ngClass]="{'warning-bg': ApplicationPractice.IsActive == false}" *ngFor="let ApplicationPractice of data.ApplicationPractices; let i = index">
                <th scope="row"><a routerLink="/application/page/{{(pageNo + 1) + (i * 3)}}">{{i+1}}</a></th>
                <td>
                  <button *ngIf="ApplicationPractice.Role !== null && ApplicationPractice.IsActive" type="button" (click)="openApplicationPractice(ApplicationPractice.EncryptedId)" class="btn btn-primary">Edit</button>
                  <button *ngIf="ApplicationPractice.Role == null && ApplicationPractice.IsActive" type="button" (click)="openApplicationPractice(ApplicationPractice.EncryptedId)" class="btn btn-primary">Add</button>
                </td>
                <td>{{ApplicationPractice.NameOfCase}}</td>
                <td>{{ApplicationPractice.Role}}</td>
                <td class="dataCenter">{{ApplicationPractice.StartDate}}</td>
                <td class="dataCenter">{{ApplicationPractice.EndDate}}</td>
                <td class="text-center">
                  <pb-switch  class="required-denote custom-switch" (change)="onActiveChange($event, ApplicationPractice.EncryptedId, i)" [(ngModel)]="ApplicationPractice.IsActive" name="IsActive{{i+1}}" id="IsActive{{i+1}}" labelOn="Yes" labelOff="No"></pb-switch>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="textarea-form">
            <label class="control-label mb-0">If you have listed fewer than 12 cases, or if you have included cases in which your participation ended before 2021, please briefly explain why<span *ngIf="isShow.length > 0">&nbsp;*</span></label>
            <textarea maxlength="1000" autosize [minRows]="5" class="form-control mb-0 input-md" [required]="isShow.length > 0" #TwelveCaseReason="ngModel" id="TwelveCaseReason" name="TwelveCaseReason" type="text" [(ngModel)]="data.TwelveCaseReason" [ngClass]="{'is-invalid': TwelveCaseReason?.invalid && showMessage}"></textarea>
            <span class="float-right label label-default" id="counter">{{data.TwelveCaseReason ? data.TwelveCaseReason.length : 0}} / 1000</span>
            <p class="mb-0">
              <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
            </p>
            <div class="invalid-tooltip textarea-tooltip" *ngIf="TwelveCaseReason?.errors?.required && showMessage">This field is required.</div>
          </div>

        </div>
      </div>
    </div>


    <div class="card-footer form-card">
      <div class="row">
        <div class="col-md-12 pl-0">
          <p class="required">* denotes required fields </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-2">
                <label for="ID">ID:</label>
              </div>

              <div class="col-md-10">
                <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="Surname">Surname:</label>
              </div>

              <div class="col-md-9">
                <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName" [maxLength]="50">
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-auto pr-0 cardPagin">
          <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(false)">
            <i class="fas fa-chevron-left" style="font-size: 16px;"></i>
          </button>
          <label class="pagination-label" for="Surname">Page</label>
          <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, false)">
            <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
          </select>
          <p>
            of
            <span>{{pages.length}}</span>
          </p>
          <button type="button" class="btn btn-primary float-left" (click)="goToNext(false)">
            <i class="fas fa-chevron-right" style="font-size: 16px;"></i>
          </button>
        </div>

        <div class="col-auto text-right ml-auto pl-0">
          <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
          <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
          <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
        </div>
      </div>
    </div>
  </div>
</form>

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LaddaModule } from 'angular2-ladda';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AutosizeModule } from 'ngx-autosize';
import { PBSwitchModule } from '../shared/pb-switch/pb-switch.module';
import { ApplicationRoutes } from './application.routing';
import { GuidanceNoteFormComponent } from './guidance-note-form/guidance-note-form.component';
import { ApplicatonPracticeFormComponent } from './application-practice-form/application-practice-form.component';
import { Page1Component } from './page1/page1.component';
import { Page2Component } from './page2/page2.component';
import { Page3Component } from './page3/page3.component';
import { Page4Component } from './page4/page4.component';
import { Page5Component } from './page5/page5.component';
import { Page6Component } from './page6/page6.component'; 
import { Page7Component } from './page7/page7.component';
import { Page8Component } from './page8/page8.component';
import { Page9Component } from './page9/page9.component';
import { Page10Component } from './page10/page10.component';
import { Page11Component } from './page11/page11.component';
import { Page12Component } from './page12/page12.component';
import { Page13Component } from './page13/page13.component';
import { Page14Component } from './page14/page14.component';
import { Page15Component } from './page15/page15.component';
import { Page16Component } from './page16/page16.component';
import { Page17Component } from './page17/page17.component';
import { Page51Component } from './page51/page51.component';
import { Page52Component } from './page52/page52.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { AllApplicationComponent } from './all-application/all-application.component';
import { ApplicationResolver } from './application.resolver';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ApplicationRoutes),
    FormsModule,
    NgbModule,
    NgSelectModule,
    AutosizeModule,
    LaddaModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.doubleBounce,
      backdropBorderRadius: '4px',
      primaryColour: '#d7e7db',
      secondaryColour: '#57a170'
    }),
    NgxMaskDirective,
    ClickOutsideModule,
    ScrollToModule.forRoot(),
    PBSwitchModule
  ],
  declarations: [
    GuidanceNoteFormComponent,
    ApplicatonPracticeFormComponent,
    PaymentFormComponent,
    Page1Component,
    Page2Component,
    Page3Component,
    Page4Component,
    Page5Component,
    Page6Component,
    Page7Component,
    Page8Component,
    Page9Component,
    Page10Component,
    Page11Component,
    Page12Component,
    Page13Component,
    Page14Component,
    Page15Component,
    Page16Component,
    Page17Component,
    Page51Component,
    Page52Component,
    AllApplicationComponent
  ],
  providers: [
    ApplicationResolver,
    provideNgxMask({ validation: false }),
    NgbDatepickerConfig
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ApplicationModule { }

<ngx-loading [show]="(data | json) == '{}' && page15_50.length == 0"></ngx-loading>
<form [hidden]="(data | json) == '{}' && page15_50.length == 0">
  <!--Page 1-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page1}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-sm-6 col-print-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6">
                  <label class="control-label" for="TitleId">Title<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <ng-select [items]="data.TitleIds" id="TitleId" required name="TitleId" #TitleId="ngModel" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.TitleId">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="(data.TitleId == 493 && TitleId.dirty) || data.TitleId == 493">
              <div class="row">
                <div class="col-sm-6 col-print-6">
                  <label class="control-label" for="TitleOther">Title other<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #TitleOther="ngModel" required id="TitleOther" name="TitleOther" type="text" [(ngModel)]="data.TitleOther">
                  <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6 pr-0">
                  <label class="control-label" for="LastName">Professional surname<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #LastName="ngModel" required id="LastName" name="LastName" type="text" [(ngModel)]="data.LastName" [ngClass]="{'is-invalid': LastName?.invalid && showMessage}">
                  <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6">
                  <label class="control-label" for="FirstName">Forenames in full<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #FirstName="ngModel" required id="FirstName" name="FirstName" type="text" [(ngModel)]="data.FirstName" [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}">
                  <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6">
                  <label class="control-label" for="Reference">Your Law Society roll number/Bar Council membership number<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #Reference="ngModel" required id="Reference" name="Reference" type="text" [(ngModel)]="data.Reference" [ngClass]="{'is-invalid': Reference?.invalid && showMessage}">
                  <div class="invalid-tooltip" *ngIf="Reference?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6">
                  <label class="control-label" for="ProfessionalEmail">Professional email address<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" readonly="readonly" #ProfessionalEmail="ngModel" id="ProfessionalEmail" name="ProfessionalEmail" type="email" [(ngModel)]="data.ProfessionalEmail">
                  <div class="invalid-tooltip" *ngIf="ProfessionalAddress1?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6 pr-0">
                  <label class="control-label" for="MobileTelephone">Telephone or mobile number<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #MobileTelephone="ngModel" required id="MobileTelephone" name="MobileTelephone" type="text" [(ngModel)]="data.MobileTelephone" [ngClass]="{'is-invalid': MobileTelephone?.invalid && showMessage}">
                  <div class="invalid-tooltip" *ngIf="MobileTelephone?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-print-6">
            <div class="form-group ">
              <div class="row">
                <div class="col-sm-6 col-print-6 pr-0">
                  <label class="control-label mb-0" for="Chambers">Name of chambers, firm or employer<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #Chambers="ngModel" required id="Chambers" name="Chambers" type="text" [(ngModel)]="data.Chambers" [ngClass]="{'is-invalid': Chambers?.invalid && showMessage}">
                  <div class="invalid-tooltip" *ngIf="Chambers?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6 sm-mb-15">
                  <label class="control-label mb-0" for="address">Address</label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #ProfessionalAddress1="ngModel" id="ProfessionalAddress1" name="ProfessionalAddress1" type="text" [(ngModel)]="data.ProfessionalAddress1">
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 offset-sm-6 col-print-6">
                  <input class="form-control input-sm" #ProfessionalAddress2="ngModel" id="ProfessionalAddress2" name="ProfessionalAddress2" type="text" [(ngModel)]="data.ProfessionalAddress2">
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6">
                  <label class="control-label" for="ProfessionalAddress4">Post town<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #ProfessionalAddress4="ngModel" required id="ProfessionalAddress4" name="ProfessionalAddress4" type="text" [(ngModel)]="data.ProfessionalAddress4" [ngClass]="{'is-invalid': ProfessionalAddress4?.invalid && showMessage}">
                  <div class="invalid-tooltip" *ngIf="ProfessionalAddress4?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 col-print-6">
                  <label class="control-label" for="ProfessionalPostcode">Post code<span>&nbsp;*</span></label>
                </div>

                <div class="col-sm-6 col-print-6">
                  <input class="form-control input-sm" #ProfessionalPostcode="ngModel" required id="ProfessionalPostcode" name="ProfessionalPostcode" type="text" [(ngModel)]="data.ProfessionalPostcode" [ngClass]="{'is-invalid': ProfessionalPostcode?.invalid && showMessage}">
                  <div class="invalid-tooltip" *ngIf="ProfessionalPostcode?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-print-12 mh-58">
                <div class="row">
                  <div class="col-sm-4 col-print-4 pr-0">
                    <div class="form-check mb-0 mr-0">
                      <label class="control-label" for="IsUK">UK<span>&nbsp;*</span></label>
                      <pb-switch class="required-denote custom-switch" #IsUK="ngModel" required id="IsUK" [(ngModel)]="data.IsUK" name="IsUK" labelOn="Yes" labelOff="No">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsUK?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>

                  <div class="col-sm-8 col-print-8" *ngIf="data.IsUK === false">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-5 col-print-5 sm-pl-15 pl-0 pr-0">
                          <label class="control-label" for="ProfessionalCountry">Other<span>&nbsp;*</span><small class="mb-0">(please state)</small></label>
                        </div>

                        <div class="col-sm-7 col-print-7">
                          <input [required]="!data.IsUK" class="form-control input-sm" #ProfessionalCountry="ngModel" id="ProfessionalCountry" name="ProfessionalCountry" type="text" [(ngModel)]="data.ProfessionalCountry">
                          <div class="invalid-tooltip" *ngIf="ProfessionalCountry?.errors?.required && showMessage">This field is required.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 col-print-4">
            <div class="form-group mb-0">
              <label class="mb-5">Preferred Interview Location <span>&nbsp;*</span></label>
            </div>
          </div>

          <div class="col-sm-8 col-print-8">
            <div class="form-check mr-0 mb-0">
              <label>London </label>
              <pb-switch class="required-denote custom-switch mr-15" [(ngModel)]="data.IsLondonInterview" name="IsLondonInterview" id="IsLondonInterview" labelOn="Yes" labelOff="No">
              </pb-switch>
            </div>

            <div class="form-check float-right mr-0 mb-0">
              <label>Manchester</label>
              <pb-switch class="required-denote custom-switch" [(ngModel)]="data.IsManchesterInterview" name="IsManchesterInterview" id="IsManchesterInterview" labelOn="Yes" labelOff="No">
              </pb-switch>
            </div>
          </div>

          <div class="col-sm-12 col-print-12">


            <div class="required" style="text-align:center"><small>Please select a minimum of one of the above options</small></div>
            <div class="form-group">
              <small for="isLondonInterview" class="label-inline">(Please select to indicate your preferred location(s) should the Selection Panel invite you to an interview)</small>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="UnavailableDate" class="mb-0">
            Please add any dates, such as holiday commitments etc. that mean you could not attend an interview between September and October 2024
            <small class="mb-0">(Please do NOT include court, hearing dates etc, as judges normally allow time out for interview)</small>
          </label>
          <textarea maxlength="500" class="form-control input-sm mb-0" #UnavailableDate="ngModel" id="UnavailableDate" name="UnavailableDate" [(ngModel)]="data.UnavailableDate" [ngClass]="{'is-invalid': UnavailableDate?.invalid && showMessage}" autosize [minRows]="5"></textarea>
          <span class="float-right label label-default" id="counter">{{data.UnavailableDate ? data.UnavailableDate.length : 0}} / 500</span>
          <small class="label-inline">Please note there is a limit of 500 characters for this box</small>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>1</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 1-->
  <!--Page 2-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page2}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="form-group">
          <div class="row">
            <div class="col-sm-9 col-print-9">
              <label class="control-label" for="ProfessionId">What is your current branch of the profession?<span>&nbsp;*</span></label>
            </div>

            <div class='col-sm-3 col-print-3'>
              <div class="input-group">
                <ng-select required [items]="data.ProfessionIds" id="ProfessionId" name="ProfessionId" #ProfessionId="ngModel" bindLabel="LookupValue" bindValue="LookupId" [ngClass]="{'is-invalid': ProfessionId?.invalid && showMessage}" [(ngModel)]="data.ProfessionId"></ng-select>
                <div class="invalid-tooltip" *ngIf="ProfessionId?.errors?.required && showMessage">
                  This field is Required.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-9 col-print-9">
              <label class="control-label label-inline" for="BarDate">Date of call or admission into current branch of the profession<span>&nbsp;*</span></label>
            </div>

            <div class='col-sm-3 col-print-3'>
              <div class="input-group" (clickOutside)="BarDatePicker.close()">
                <input class="form-control" #BarDate="ngModel" #BarDatePicker="ngbDatepicker" required id="BarDate" name="BarDate" [(ngModel)]="BarDateField" ngbDatepicker [ngClass]="{'is-invalid': BarDate?.invalid && showMessage}">
                <div class="input-group-append">
                  <button class="btn" (click)="BarDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                </div>
                <div class="invalid-tooltip" *ngIf="BarDate?.errors?.required && showMessage">This field is Required.</div>
                <div class="invalid-tooltip" *ngIf="BarDateField && BarDate?.invalid">Please enter date in (dd/mm/yyyy).</div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-9 col-print-9">
              <label class="control-label label-inline" for="PreviousBarDate">
                If you were previously called or admitted to a
                different branch (barrister or solicitor) in England and Wales - please give the date of call or
                admission to your former profession
              </label>
            </div>

            <div class='col-sm-3 col-print-3'>
              <div class="input-group" (clickOutside)="PreviousBarDatePicker.close()">
                <input class="form-control" #PreviousBarDate="ngModel" #PreviousBarDatePicker="ngbDatepicker" id="PreviousBarDate" name="PreviousBarDate" [(ngModel)]="PreviousBarDateField" ngbDatepicker [ngClass]="{'is-invalid': PreviousBarDate?.invalid && showMessage}">
                <div class="input-group-append">
                  <button class="btn" (click)="PreviousBarDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                </div>
                <div class="invalid-tooltip" *ngIf="PreviousBarDateField && PreviousBarDate?.invalid">Please enter date in (dd/mm/yyyy).</div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <div class="row">
            <div class="col-sm-9 col-print-9">
              <label class="control-label mb-0" for="IsEmployed">
                Are you an employed barrister or in-house solicitor?
              </label>
            </div>

            <div class='col-sm-3 col-print-3'>
              <div class="form-check">
                <pb-switch class="required-denote custom-switch mb-0" #IsEmployed="ngModel" id="IsEmployed" [(ngModel)]="data.IsEmployed" name="IsEmployed" labelOn="Yes" labelOff="No"></pb-switch>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="data.ProfessionId == 326">
          <div class="row">
            <div class="col-sm-9 col-print-9">
              <label class="control-label" for="InnOfCourtId">Which Inn of Court are you a member of?<span>&nbsp;*</span></label>
            </div>

            <div class='col-sm-3 col-print-3'>
              <div class="input-group">
                <ng-select [items]="data.InnOfCourtIds" #InnOfCourtId="ngModel" id="InnOfCourtId" required name="InnOfCourtId" bindLabel="LookupValue" bindValue="LookupId" [ngClass]="{'is-invalid': InnOfCourtId?.invalid && showMessage}" [(ngModel)]="data.InnOfCourtId"></ng-select>
                <div class="invalid-tooltip" *ngIf="InnOfCourtId?.errors?.required && showMessage">
                  This field is Required.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf=false>
          <div class="form-group">
            <div class="row">
              <div class='col-sm-12 col-print-12'>
                <label class="control-label label-inline" for="HighCourtCivilDate">
                  Please give the date on which you attained each of your higher courts qualification(s) under the Courts and Legal Services Act of 1990
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-8">
                <label class="control-label" for="HighCourtCivilDate">Higher Courts (Civil)<span>&nbsp;*</span></label>
              </div>

              <div class='col-sm-4' (clickOutside)="HighCourtCivilDatePicker.close()">
                <div class="input-group">
                  <input class="form-control" #HighCourtCivilDate="ngModel" #HighCourtCivilDatePicker="ngbDatepicker" required id="HighCourtCivilDate"
                         name="HighCourtCivilDate" [(ngModel)]="HighCourtCivilDateField" ngbDatepicker [ngClass]="{'is-invalid': HighCourtCivilDate?.invalid && showMessage}">
                  <div class="input-group-append">
                    <button class="btn" (click)="HighCourtCivilDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtCivilDate?.errors?.required && showMessage">
                    This field is Required.
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtCivilDate?.invalid && showMessage">Please enter date in (dd/mm/yyyy).</div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <label class="control-label" for="HighCourtCriminalDate">Higher Courts (Criminal)<span>&nbsp;*</span></label>
              </div>

              <div class='col-sm-2'>
                <div class="input-group" (clickOutside)="HighCourtCriminalDatePicker.close()">
                  <input class="form-control" #HighCourtCriminalDate="ngModel" #HighCourtCriminalDatePicker="ngbDatepicker" required id="HighCourtCriminalDate"
                         name="HighCourtCriminalDate" [(ngModel)]="HighCourtCriminalDateField" ngbDatepicker [ngClass]="{'is-invalid': HighCourtCriminalDate?.invalid && showMessage}">
                  <div class="input-group-append">
                    <button class="btn" (click)="HighCourtCriminalDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtCriminalDate?.errors?.required && showMessage">
                    This field is Required.
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtCriminalDate?.invalid && showMessage">Please enter date in (dd/mm/yyyy).</div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <label class="control-label" for="HighCourtAllDate">Higher Courts (All)<span>&nbsp;*</span></label>
              </div>

              <div class='col-sm-2'>
                <div class="input-group" (clickOutside)="HighCourtAllDatePicker.close()">
                  <input class="form-control" #HighCourtAllDate="ngModel" #HighCourtAllDatePicker="ngbDatepicker" required id="HighCourtAllDate"
                         name="HighCourtAllDate" [(ngModel)]="HighCourtAllDateField" ngbDatepicker [ngClass]="{'is-invalid': HighCourtAllDate?.invalid && showMessage}">
                  <div class="input-group-append">
                    <button class="btn" (click)="HighCourtAllDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtAllDate?.errors?.required && showMessage">
                    This field is Required.
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtAllDate?.invalid && showMessage">Please enter date in (dd/mm/yyyy).</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-print-12">
            <label class="mb-15 d-block">
              Geographical area of practice<span class="required">&nbsp;*</span>
              <small class="d-block" for="Geographical">
                (Please enter below the approximate percentage of your working time spent in each geographical area)
              </small>
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-print-12">
            <div class="form-group">
              <div class="row">
                <div class='col-sm-4 col-print-4'>
                  <div class="row">
                    <div class="col-sm-7 col-print-7">
                      <label class="control-label" for="PracticeAreaLondon">London </label>
                    </div>

                    <div class="col-sm-5 col-print-5">
                      <div class="input-group">
                        <input class="form-control input-md" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaLondon="ngModel" id="PracticeAreaLondon" name="PracticeAreaLondon" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaLondon">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-sm-4 col-print-4'>
                  <div class="row">
                    <div class="col-sm-7 col-print-7">
                      <label class="control-label" for="PracticeAreaNorthWest">North West </label>
                    </div>

                    <div class="col-sm-5 col-print-5">
                      <div class="input-group">
                        <input class="form-control input-md" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaNorthWest="ngModel" id="PracticeAreaNorthWest" name="PracticeAreaNorthWest" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaNorthWest">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-sm-4 col-print-4'>
                  <div class="row">
                    <div class="col-sm-7 col-print-7">
                      <label class="control-label" for="PracticeAreaSouthEast">South East </label>
                    </div>

                    <div class="col-sm-5 col-print-5">
                      <div class="input-group">
                        <input class="form-control input-md" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaSouthEast="ngModel" id="PracticeAreaSouthEast" name="PracticeAreaSouthEast" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaSouthEast">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class='col-sm-4 col-print-4'>
                  <div class="row">
                    <div class="col-sm-7 col-print-7">
                      <label class="control-label" for="PracticeAreaSouthWest">South West </label>
                    </div>

                    <div class="col-sm-5 col-print-5">
                      <div class="input-group">
                        <input class="form-control input-md" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaSouthWest="ngModel" id="PracticeAreaSouthWest" name="PracticeAreaSouthWest" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaSouthWest">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-sm-4 col-print-4'>
                  <div class="row">
                    <div class="col-sm-7 col-print-7">
                      <label class="control-label" for="PracticeAreaMidlands">Midlands </label>
                    </div>

                    <div class="col-sm-5 col-print-5">
                      <div class="input-group">
                        <input class="form-control input-md" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaMidlands="ngModel" id="PracticeAreaMidlands" name="PracticeAreaMidlands" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaMidlands">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-sm-4 col-print-4'>
                  <div class="row">
                    <div class="col-sm-7 col-print-7">
                      <label class="control-label" for="PracticeAreaWales">Wales </label>
                    </div>

                    <div class="col-sm-5 col-print-5">
                      <div class="input-group">
                        <input class="form-control input-md" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaWales="ngModel" id="PracticeAreaWales" name="PracticeAreaWales" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaWales">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class='col-sm-4 col-print-4'>
                  <div class="row">
                    <div class="col-sm-7 col-print-7">
                      <label class="control-label" for="PracticeAreaNorthEast">North East </label>
                    </div>

                    <div class="col-sm-5 col-print-5">
                      <div class="input-group">
                        <input class="form-control input-md" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaNorthEast="ngModel" id="PracticeAreaNorthEast" name="PracticeAreaNorthEast" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaNorthEast">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-sm-4 col-print-4'>
                  <div class="row">
                    <div class="col-sm-7 col-print-7">
                      <label class="control-label" for="PracticeAreaEurope">Outside England and Wales </label>
                    </div>

                    <div class="col-sm-5 col-print-5">
                      <div class="input-group">
                        <input class="form-control input-md" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaEurope="ngModel" id="PracticeAreaEurope" name="PracticeAreaEurope" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaEurope">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-sm-4 col-print-4'>
                  <div class="total-page-2">
                    <div class="row">
                      <div class="col-sm-7 col-print-7">
                        <label class="control-label pt-10" for="TotalRegion">Total (must equal 100%)</label>
                      </div>

                      <div class="col-sm-5 col-print-5">
                        <div class="input-group">
                          <input class="form-control input-md" #TotalRegion="ngModel" id="TotalRegion" name="TotalRegion" type="number" min="0" max="100" [max]="100" step="1" [(ngModel)]="data.TotalRegion" [ngClass]="{'is-invalid': TotalRegion?.errors?.max}">
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                          <div class="invalid-tooltip" *ngIf="TotalRegion.errors?.max">
                            Total Must Equal 100%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tooltip-group">
          <div class="row">
            <div class="col-sm-12 col-print-12">
              <label class="mb-15">
                Broad field of practice<span class="required">&nbsp;*</span>
                <small for="broad_fields" class="d-block">
                  (Please select an answer for each broad field of practice of law in which you have demonstrated excellence)
                </small>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-print-12">
              <div class="row">
                <div class='col-sm-4 col-print-4'>
                  <div class="form-check float-right mr-0">
                    <label>Criminal Law </label>
                    <pb-switch class="required-denote custom-switch" #BroadFieldCriminal="ngModel" id="BroadFieldCriminal" [(ngModel)]="data.BroadFieldCriminal" name="BroadFieldCriminal"></pb-switch>
                  </div>
                </div>

                <div class='col-sm-4 col-print-4'>
                  <div class="form-check float-right mr-0">
                    <label>Family Law</label>
                    <pb-switch class="required-denote custom-switch" #BroadFieldFamily="ngModel" id="BroadFieldFamily" [(ngModel)]="data.BroadFieldFamily" name="BroadFieldFamily"></pb-switch>
                  </div>
                </div>

                <div class='col-sm-4 col-print-4'>
                  <div class="form-check float-right mr-0">
                    <label>Civil Law</label>
                    <pb-switch class="required-denote custom-switch" #BroadFieldCivil="ngModel" id="BroadFieldCivil" [(ngModel)]="data.BroadFieldCivil" name="BroadFieldCivil"></pb-switch>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class='col-sm-4 offset-sm-5 required'>
                  <small>Please select a minimum of one of the above options</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>2</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!--Page 2-->
  <!--Page 3-->
  <div class="page page-3">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page3}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <label>
          Please indicate here your main specialist fields of work.<span class="required">&nbsp;*</span>
          <small>
            (Do not indicate a specialism unless it represents at least 5% of your work)
          </small>
        </label>
        <div class="row">
          <div class="col-sm-4 col-print-4 order-1">
            <div class="form-group" *ngFor="let ApplicationSpecialism of data.ApplicationSpecialisms | slice:0:breakCount; let i = index;">
              <div class="row">
                <div class="col-sm-8 col-print-8">
                  <label class="control-label" for="{{i+1}}">{{$any(ApplicationSpecialism)?.FieldOfPractice}}</label>
                </div>

                <div class="col-sm-4 col-print-4">
                  <div class="input-group">
                    <input class="form-control input-md" (change)="calculateTotal()" #SpecialismPercent="ngModel" aria-label="Please do not exceed 100%" id="{{i+1}}-1" name="{{i+1}}-1" type="number" min="5" max="100" maxlength="3" step="1" digits pasteblock onkeypress="return !(event.charCode == 46)" [(ngModel)]="$any(ApplicationSpecialism).SpecialismPercent" [ngClass]="{'is-invalid': SpecialismPercent.invalid}">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4 col-print-4 order-2">
            <div class="form-group" *ngFor="let ApplicationSpecialism of data.ApplicationSpecialisms | slice:breakCount:breakCount*2; let i = index">
              <div class="row">
                <div class="col-sm-8 col-print-8">
                  <label class="control-label" for="{{i+1}}">{{$any(ApplicationSpecialism)?.FieldOfPractice}}</label>
                </div>
                <div class="col-sm-4 col-print-4">
                  <div class="input-group">
                    <input class="form-control input-md" (change)="calculateTotal()" #SpecialismPercent="ngModel" ng-pattern="/^-?\d*$/" aria-label="Please do not exceed 100%" id="{{i+1}}-2" name="{{i+1}}-2" type="number" min="5" max="100" digits pasteblock onkeypress="return !(event.charCode == 46)" maxlength="3" step="1" [(ngModel)]="$any(ApplicationSpecialism).SpecialismPercent" [ngClass]="{'is-invalid': SpecialismPercent.invalid}">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4 col-print-4 order-3">
            <div class="form-group" *ngFor="let ApplicationSpecialism of data.ApplicationSpecialisms | slice:breakCount*2:breakCount*3; let i = index">
              <div class="row">
                <div class="col-sm-8 col-print-8">
                  <label class="control-label" for="{{i+1}}">{{$any(ApplicationSpecialism)?.FieldOfPractice}}</label>
                </div>
                <div class="col-sm-4 col-print-4">
                  <div class="input-group">
                    <input class="form-control input-md" (change)="calculateTotal()" #SpecialismPercent="ngModel" ng-pattern="/^-?\d*$/" aria-label="Please do not exceed 100%" id="{{i+1}}-3" name="{{i+1}}-3" type="number" min="5" max="100" digits pasteblock onkeypress="return !(event.charCode == 46)" maxlength="3" step="1" [(ngModel)]="$any(ApplicationSpecialism).SpecialismPercent" [ngClass]="{'is-invalid': SpecialismPercent.invalid}">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="total-page-3">
                <div class="row">
                  <div class="col-sm-8 col-print-8">
                    <label class="control-label" for="Total">Total (must equal 100%)</label>
                  </div>

                  <div class="col-sm-4 col-print-4">
                    <div class="input-group">
                      <input class="form-control input-md" aria-label="Please do not exceed 100%" #Total="ngModel" ng-pattern="/^-?\d*$/" id="Total" name="Total" readonly type="number" min="0" max="100" [max]="100" maxlength="3" step="1" digits pasteblock onkeypress="return !(event.charCode == 46)" [(ngModel)]="data.Total" [ngClass]="{'is-invalid': Total?.errors?.max}">
                      <div class="input-group-prepend height-40">
                        <div class="input-group-text">%</div>
                      </div>
                      <div class="invalid-tooltip" *ngIf="Total.errors?.max">
                        Total Must Equal 100%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="showSpecialisms">
          <div class='col-sm-12 col-print-12'>
            <div class="textarea-form">
              <label class="control-label mb-0" for="admin_law">Other specialisms <span *ngIf="showSpecialisms">*</span></label>
              <textarea required maxlength="300" class="form-control input-md mb-0" #OtherSpecialism="ngModel" id="OtherSpecialism" [required]="showSpecialisms" name="OtherSpecialism" [(ngModel)]="data.OtherSpecialism" autosize [minRows]="5"></textarea>
              <div class="invalid-tooltip textarea-tooltip" *ngIf="OtherSpecialism?.errors?.required && showMessage">This field is required.</div>
              <span class="float-right label label-default" id="counter">{{data.OtherSpecialism ? data.OtherSpecialism.length : 0}} / 300</span>
              <small class="label-inline">Please note there is a limit of 300 characters for this box</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>3</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 3-->
  <!--Page 4-->
  <div class="page page-4">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page4}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <div class="form-group mb-0">
              <label for="IsEmployed" class="mb-0">
                A. Have you ever been subject to, or are you now facing, any proceedings or other circumstances which led or may lead, either in England and Wales or in another jurisdiction, to:
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class='col-sm-12 col-print-12'>
            <ul class="list-group page-4-list mb-30">
              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsCriminalOffence">
                    1. A conviction for a criminal offence (other than a minor road traffic offence)? <small class="required">*</small>
                    <br />
                    <small>(You do not need to include convictions which are “spent” by virtue of the Rehabilitation of Offenders Act 1974)</small>
                  </label>
                  <pb-switch required class="required-denote custom-switch flot-right" #IsCriminalOffence="ngModel" id="IsCriminalOffence" [(ngModel)]="data.IsCriminalOffence" name="IsCriminalOffence" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsCriminalOffence?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsProfessionalNegligence">2. A finding of professional negligence? <small class="required">*</small></label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsProfessionalNegligence="ngModel" id="IsProfessionalNegligence" [(ngModel)]="data.IsProfessionalNegligence" name="IsProfessionalNegligence" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsProfessionalNegligence?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsProfessionalDisciplinary">3. A finding of professional disciplinary fault (including inadequate professional service)? <small class="required">*</small></label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsProfessionalDisciplinary="ngModel" id="IsProfessionalDisciplinary" [(ngModel)]="data.IsProfessionalDisciplinary" name="IsProfessionalDisciplinary" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsProfessionalDisciplinary?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsWastedCosts">4. A wasted costs order? <small class="required">*</small></label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsWastedCosts="ngModel" id="IsWastedCosts" [(ngModel)]="data.IsWastedCosts" name="IsWastedCosts" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsWastedCosts?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsIntervention">5. An intervention by the Solicitors Regulation Authority? <small class="required">*</small></label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsIntervention="ngModel" id="IsIntervention" [(ngModel)]="data.IsIntervention" name="IsIntervention" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsIntervention?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsComplaint">6. A complaint which has been referred to the Legal Ombudsman? <small class="required">*</small></label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsComplaint="ngModel" id="IsComplaint" [(ngModel)]="data.IsComplaint" name="IsComplaint" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsComplaint?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsBankruptcy">7. A bankruptcy order, debt relief order or an individual or partnership voluntary arrangement? <small class="required">*</small></label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsBankruptcy="ngModel" id="IsBankruptcy" [(ngModel)]="data.IsBankruptcy" name="IsBankruptcy" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsBankruptcy?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsDirectorDisqualification">8. A director’s disqualification order? <small class="required">*</small></label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsDirectorDisqualification="ngModel" id="IsDirectorDisqualification" [(ngModel)]="data.IsDirectorDisqualification" name="IsDirectorDisqualification" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsDirectorDisqualification?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsOtherOrder">9. Any other form of order or proceeding which may be material to the Selection panel or the Lord Chancellor in considering your suitability to be appointed King's Counsel? <small class="required">*</small></label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsOtherOrder="ngModel" id="IsOtherOrder" [(ngModel)]="data.IsOtherOrder" name="IsOtherOrder" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsOtherOrder?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>

              <li>
                <div class="list-group-item d-flex justify-content-between align-items-center">
                  <label class="control-label" for="IsAnyother">
                    B. Is there anything else in your personal or professional background which could affect your suitability for appointment or bring the legal profession or Queen’s Counsel into disrepute? <small class="required">*</small>
                  </label>
                  <pb-switch required class="required-denote custom-switch float-right" #IsAnyOther="ngModel" id="IsAnyOther" [(ngModel)]="data.IsAnyOther" name="IsAnyOther" labelOn="Yes" labelOff="No"></pb-switch>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="IsAnyOther?.errors?.required && showMessage">This field is required.</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class='col-sm-12 col-print-12'>
            <div class="form-group mb-0">
              <label for="OrderNarrative" class="mb-0">
                If you answered “yes” to any of the above please provide details below.<span class="required" *ngIf="(data.IsOtherOrder || data.IsProfessionalDisciplinary || data.IsAnyOther || data.IsBankruptcy || data.IsComplaint || data.IsIntervention || data.IsWastedCosts ||  data.IsProfessionalNegligence || data.IsCriminalOffence || data.IsDirectorDisqualification)">&nbsp;*</span>
                <small class="mb-0">
                  (Please include your complaint reference number, case number, or similar.)
                </small>
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class='col-sm-12 col-print-12'>
            <div class="form-group textarea-form">
              <textarea [required]="data.IsOtherOrder || data.IsProfessionalDisciplinary || data.IsAnyOther || data.IsBankruptcy || data.IsComplaint || data.IsIntervention || data.IsWastedCosts ||  data.IsProfessionalNegligence || data.IsCriminalOffence || data.IsDirectorDisqualification" class="form-control input-md mb-0" #OrderNarrative="ngModel" id="OrderNarrative" name="OrderNarrative" [(ngModel)]="data.OrderNarrative" maxlength="700" autosize [minRows]="7"></textarea>
              <span class="float-right label label-default" id="counter">{{data.OrderNarrative ? data.OrderNarrative.length : 0}} / 700</span>
              <div class="invalid-tooltip" *ngIf="OrderNarrative?.errors?.required && showMessage">This field is required.</div>
              <small class="label-inline">Please note there is a limit of 700 characters for this box.</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>4</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 4-->
  <!--Page 5-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page5}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <div class="form-group mb-0">
              <label class="mb-0 label-inline" for="IsEmployed">
                If you consider it would be inappropriate for any Panel member(s) to assess your application and/or to interview you, please select 'Yes' below and give your reasons.
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-print-6 order-1">
            <div class="row">
              <div class="col-sm-12 col-print-12">
                <ul class="mb-30 p-0">
                  <li class='col-sm-12 col-print-12' *ngFor="let ApplicationRecusal of data.ApplicationRecusals | slice:0:breakCount1; let i = index">
                    <div class="list-group-item justify-content-between align-items-center">
                      <label>{{$any(ApplicationRecusal)?.PanelMemberName}}</label>
                      <pb-switch class="required-denote custom-switch"  [(ngModel)]="$any(ApplicationRecusal).IsApplicant" name="col-1-{{i+1}}" id="col-1-{{i+1}}" labelOn="Yes" labelOff="No">
                      </pb-switch>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-print-6 order-2">
            <div class="row">
              <div class="col-sm-12 col-print-12">
                <ul class="mb-30 p-0">
                  <li class='col-sm-12 col-print-12' *ngFor="let ApplicationRecusal of data.ApplicationRecusals | slice:breakCount1; let i = index">
                    <div class="list-group-item justify-content-between align-items-center">
                      <label>{{ $any(ApplicationRecusal)?.PanelMemberName}}</label>
                      <pb-switch class="required-denote custom-switch" [(ngModel)]="$any(ApplicationRecusal).IsApplicant"  name="col-2-{{i+1}}" id="col-2-{{i+1}}" labelOn="Yes" labelOff="No">
                      </pb-switch>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group textarea-form">
          <label class="control-label mb-0" for="IsEmployed">
            If you have marked any of the above names, please give reasons below:<span class="required" *ngIf="isAnyApplicant == true">&nbsp;*</span>
          </label>
          <textarea maxlength="700" class="form-control input-md mb-0" #RecusalNarrative="ngModel" id="RecusalNarrative" name="RecusalNarrative" [(ngModel)]="data.RecusalNarrative" autosize [required]="isAnyApplicant" [minRows]="5"></textarea>
          <span class="float-right label label-default mb-0" id="counter">{{data.RecusalNarrative ? data.RecusalNarrative.length : 0}} / 700</span>
          <div class="invalid-tooltip textarea-tooltip" *ngIf="RecusalNarrative?.errors?.required && showMessage">This field is required.</div>
          <small class="label-inline"> Please note there is a limit of 700 characters for this box.</small>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>5</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 5-->
  <!--Page 6-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page6}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="form-group mb-0">
          <label class="label-inline mb-0">
            Please give a description of your practice in the space below. This information will help the Selection Panel
            understand the nature of your practice.<span class="required">&nbsp;*</span>
          </label>
        </div>

        <div class="form-group textarea-form">
          <textarea maxlength="5000" autosize [minRows]="15" class="form-control input-md mb-0" #PracticeNarrative="ngModel" required id="PracticeNarrative" name="PracticeNarrative" [(ngModel)]="data.PracticeNarrative"></textarea>
          <span class="float-right label label-default" id="counter">{{data.PracticeNarrative ? data.PracticeNarrative.length : 0}} / 5000</span>
          <div class="invalid-tooltip textarea-tooltip" *ngIf="PracticeNarrative?.errors?.required && showMessage">This field is required.</div>
          <small class="label-inline"> Please note there is a limit of 5000 characters for this box </small>

        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>6</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 6-->
  <!--Page 7-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page7}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="form-group mb-0">
          <label class="label-inline mb-0">
            Understanding and using the law<span class="required">&nbsp;*</span>
          </label>
        </div>

        <div class="form-group textarea-form">
          <textarea maxlength="3000" autosize [minRows]="15" class="form-control input-md mb-0" #CompetencyA="ngModel" required id="CompetencyA" name="CompetencyA" type="text" [(ngModel)]="data.CompetencyA" [ngClass]="{'is-invalid': CompetencyA?.invalid && showMessage}"></textarea>
          <span class="float-right label label-default" id="counter">{{data.CompetencyA ? data.CompetencyA.length : 0}} / 3000</span>
          <div class="invalid-tooltip textarea-tooltip" *ngIf="CompetencyA?.errors?.required && showMessage">This field is required.</div>
          <small class="label-inline"> Please note there is a limit of 3000 characters for this box. </small>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>7</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 7-->
  <!--Page 8-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page8}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="form-group mb-0">
          <label class="label-inline mb-0">
            Written advocacy<span class="required">&nbsp;*</span>
          </label>
        </div>

        <div class='row'>
          <div class='col-sm-12 col-print-12'>
            <div class="form-group textarea-form">
              <textarea maxlength="3000" autosize [minRows]="15" class="form-control input-md mb-0" #CompetencyBWritten="ngModel" required id="CompetencyBWritten" name="CompetencyBWritten" type="text" [(ngModel)]="data.CompetencyBWritten" [ngClass]="{'is-invalid': CompetencyBWritten?.invalid && showMessage}"></textarea>
              <span class="float-right label label-default" id="counter">{{data.CompetencyBWritten ? data.CompetencyBWritten.length : 0}} / 3000</span>
              <div class="invalid-tooltip textarea-tooltip" *ngIf="CompetencyBWritten?.errors?.required && showMessage">This field is required.</div>
              <small class="label-inline"> Please note there is a limit of 3000 characters for this box. </small>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>8</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 8-->
  <!--Page 9-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page9}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="form-group mb-0">
          <label class="label-inline mb-0">
            Oral advocacy<span class="required">&nbsp;*</span>
          </label>
        </div>

        <div class="form-group textarea-form">
          <textarea maxlength="3000" autosize [minRows]="15" class="form-control input-md mb-0" #CompetencyBOral="ngModel" required id="CompetencyBOral" name="CompetencyBOral" type="text" [(ngModel)]="data.CompetencyBOral" [ngClass]="{'is-invalid': CompetencyBOral?.invalid && showMessage}"></textarea>
          <span class="float-right label label-default" id="counter">{{data.CompetencyBOral ? data.CompetencyBOral.length : 0}} / 3000</span>
          <div class="invalid-tooltip textarea-tooltip" *ngIf="CompetencyBOral?.errors?.required && showMessage">This field is required.</div>
          <small class="label-inline"> Please note there is a limit of 3000 characters for this box. </small>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>9</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 9-->
  <!--Page 10-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page10}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <div class="form-group mb-0">
              <label class="label-inline mb-0">
                Working with others<span class="required">&nbsp;*</span>
              </label>
            </div>
          </div>
        </div>

        <div class='row'>
          <div class='col-sm-12 col-print-12'>
            <div class="form-group textarea-form">
              <textarea maxlength="3000" autosize [minRows]="15" class="form-control input-md mb-0" #CompetencyC="ngModel" required id="CompetencyC" name="CompetencyC" type="text" [(ngModel)]="data.CompetencyC" [ngClass]="{'is-invalid': CompetencyC?.invalid && showMessage}"></textarea>
              <span class="float-right label label-default" id="counter">{{data.CompetencyC ? data.CompetencyC.length : 0}} / 3000</span>
              <small class="label-inline"> Please note there is a limit of 3000 characters for this box. </small>
              <div class="invalid-tooltip textarea-tooltip" *ngIf="CompetencyC?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>10</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 10-->
  <!--Page 11-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page11}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="form-group mb-0">
          <label class="label-inline mb-0">
            Diversity<span class="required">&nbsp;*</span>
          </label>
        </div>

        <div class="form-group textarea-form">
          <textarea maxlength="3000" autosize [minRows]="15" class="form-control input-md mb-0" #CompetencyD="ngModel" required id="CompetencyD" name="CompetencyD" type="text" [(ngModel)]="data.CompetencyD" [ngClass]="{'is-invalid': CompetencyD?.invalid && showMessage}"></textarea>
          <span class="float-right label label-default" id="counter">{{data.CompetencyD ? data.CompetencyD.length : 0}} / 3000</span>
          <div class="invalid-tooltip textarea-tooltip" *ngIf="CompetencyD?.errors?.required && showMessage">This field is required.</div>
          <small class="label-inline"> Please note there is a limit of 3000 characters for this box. </small>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>11</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 11-->
  <!--Page 12-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page12}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <div class="form-check mb-5 tooltip-right">
              <label>
                Are there any matters relating to integrity on which you wish to provide KC Appointments with further information?<span class="required ">&nbsp;*</span>
              </label>
              <pb-switch class="custom-switch" required [(ngModel)]="data.IsCompetencyE" #IsCompetencyE="ngModel" name="IsCompetencyE" id="IsCompetencyE"></pb-switch>
              <div class="invalid-tooltip float-right" *ngIf="IsCompetencyE?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>

        <div class="form-group textarea-form" *ngIf="data.IsCompetencyE">
          <textarea [required]="data.IsCompetencyE" maxlength="3000" autosize [minRows]="15" class="form-control input-md mb-0" #CompetencyE="ngModel" id="CompetencyE" name="CompetencyE" type="text" [(ngModel)]="data.CompetencyE" [ngClass]="{'is-invalid': CompetencyE?.invalid && showMessage}"></textarea>
          <span class="float-right label label-default" id="counter">{{data.CompetencyE ? data.CompetencyE.length : 0}} / 3000</span>
          <small class="label-inline"> Please note there is a limit of 3000 characters for this box. </small>
          <div class="invalid-tooltip textarea-tooltip" *ngIf="CompetencyE?.errors?.required && showMessage">This field is required.</div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>12</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 12-->
  <!--Page 13-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page13}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <div class="form-check mb-5 tooltip-right">
              <label>
                Is there any other further information you wish to provide about your personal circumstances which is relevant to your application?<span class="required">&nbsp;*</span>
              </label>
              <pb-switch class="custom-switch mt-2" required [(ngModel)]="data.IsOtherNarrative" #IsOtherNarrative="ngModel" name="IsOtherNarrative" id="IsOtherNarrative" labelOn="Yes" labelOff="No">
              </pb-switch>
              <div class="invalid-tooltip float-right" *ngIf="IsOtherNarrative?.errors?.required && showMessage">This field is required.</div>
            </div>

            <div class="form-group mb-0">
              <small class="mb-2">
                (This section should not be used to provide additional information relating to your self-assessment or Summary Description of Practice)
              </small>
            </div>
          </div>
        </div>

        <div class="form-group textarea-form" *ngIf="data.IsOtherNarrative">
          <textarea [required]="data.IsOtherNarrative" maxlength="3000" autosize [minRows]="15" class="form-control input-md mb-0" #OtherNarrative="ngModel" id="OtherNarrative" name="OtherNarrative" type="text" [(ngModel)]="data.OtherNarrative" [ngClass]="{'is-invalid': data.IsOtherNarrative?.invalid && showMessage}"></textarea>
          <span class="float-right label label-default" id="counter">{{data.OtherNarrative ? data.OtherNarrative.length : 0}} / 3000</span>
          <div class="invalid-tooltip textarea-tooltip" *ngIf="OtherNarrative?.errors?.required && showMessage">This field is required.</div>
          <small class="label-inline">
            Please note there is a limit of 3000 characters for this box.
          </small>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>13</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 13-->
  <!--Page 14-->
  <div class="page page-14">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page14}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <p class="mb-0 pl-15">
          Please list below 12 cases of substance that you have dealt with as advocate, preferably in the past four years (i.e. in 2017 or since). For each case please indicate the role you played and the dates of your involvement. This information is automatically transferred to Section I where you will be asked to provide details of one judicial, practitioner and one client assessor for each case.
        </p>

        <div class="row">
          <div class=" table-container">
            <table class="table table-bordered table-responsive-md table-primary">
              <thead>
                <tr>
                  <th rowspan="2">#</th>
                  <th rowspan="2">Name of case</th>
                  <th rowspan="2">Role as advocate</th>
                  <th class="dataCenter" colspan="2" scope="colgroup">Dates of your involvement</th>
                  <th rowspan="2">Are you naming a case?</th>
                </tr>
                <tr>
                  <th class="dataCenter" scope="col">Start (mm/yy)</th>
                  <th class="dataCenter" scope="col">End (mm/yy)</th>
                </tr>
              </thead>
              <tbody>
                <tr [ngClass]="{'warning-bg': ApplicationPractice.IsActive == false}" *ngFor="let ApplicationPractice of data.ApplicationPracticess; let i = index">
                  <td scope="row"><a href="#">{{i+1}}</a></td>
                  <td>{{ApplicationPractice.NameOfCase}}</td>
                  <td>{{ApplicationPractice.Role}}</td>
                  <td class="dataCenter">{{ApplicationPractice.StartDate}}</td>
                  <td class="dataCenter">{{ApplicationPractice.EndDate}}</td>
                  <td class="text-center">
                    <pb-switch class="required-denote custom-switch"  [(ngModel)]="ApplicationPractice.IsActive" name="IsActive{{i+1}}" id="IsActive{{i+1}}" labelOn="Yes" labelOff="No"></pb-switch>
                  </td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="isShow.length > 0" class="textarea-form">
              <label class="control-label mb-0">If you have listed fewer than 12 cases, or if you have included cases in which your participation ended before 2017, please briefly explain why<span>&nbsp;*</span></label>
              <textarea maxlength="1000" autosize [minRows]="5" class="form-control mb-0 input-md" required #TwelveCaseReason="ngModel" id="TwelveCaseReason" name="TwelveCaseReason" type="text" [(ngModel)]="data.TwelveCaseReason" [ngClass]="{'is-invalid': TwelveCaseReason?.invalid && showMessage}"></textarea>
              <span class="float-right label label-default" id="counter">{{data.TwelveCaseReason ? data.TwelveCaseReason.length : 0}} / 1000</span>
              <p class="mb-0">
                <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
              </p>
              <div class="invalid-tooltip textarea-tooltip" *ngIf="TwelveCaseReason?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>14</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 14-->
  <!--Page 15-50-->
  <div *ngFor="let page of page15_50; let i = index">
    <!--Page 15-->
    <div class="page page-15 warning-bg">
      <div class="page-breadcrumb">
        <div class="align-self-center">
          <div class="row">
            <div class="col-sm-12 col-print-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active"><span>{{heading.page15}}</span></li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="card warning-bg custom-card">
        <div class="card-body form-card">
          <div>
            <h5 class="float-left">{{page.Judge.CaseReferenceJ.CaseReference}}</h5>

            <div class="table-responsive-md custom-table">
              <table class="table table-bordered primary-table">
                <colgroup>
                  <col>
                  <col style="width: 50%;">
                  <col style="width: 30%">
                  <col style="width: 10%;">
                  <col style="width: 10%;">
                </colgroup>
                <thead>
                  <tr>
                    <th rowspan="2">#</th>
                    <th rowspan="2">Name of case</th>
                    <th rowspan="2">Role as Advocate</th>
                    <th colspan="2" scope="colgroup" class="text-center">Dates (mm/yy)</th>
                    <th rowspan="2" class="text-center">Are you naming a <br />Judge(s) for this case?</th>
                  </tr>
                  <tr>
                    <th scope="colgroup" class="text-center">Start</th>
                    <th scope="colgroup" class="text-center">End</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{page.Judge.ApplicationAssessorFormJ.OrderNo}}</td>
                    <td>{{page.Judge.ApplicationAssessorFormJ.NameOfCase}}</td>
                    <td>{{page.Judge.ApplicationAssessorFormJ.Role}}</td>
                    <td class="text-center">{{page.Judge.ApplicationAssessorFormJ.StartDate}}</td>
                    <td class="text-center">{{page.Judge.ApplicationAssessorFormJ.EndDate}}</td>
                    <td class="text-center">
                      <pb-switch  class="required-denote custom-switch" [(ngModel)]="page.Judge.ApplicationAssessorFormJ.IsAssessorActive" name="IsAssessorActive{{i+1+page.Judge.AssessorJ.AssessorReference}}" id="IsAssessorActive{{i+1+page.Judge.AssessorJ.AssessorReference}}" labelOn="Yes" labelOff="No"></pb-switch>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-12 col-print-12 mb-15">
                <label class="mb-0">Brief description of case</label>
                <textarea autosize [minRows]="4" readonly id="BriefOfCase{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="BriefOfCase{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.ApplicationAssessorFormJ.BriefOfCase" class="form-control"></textarea>
              </div>

              <div class="col-sm-12 col-print-12">
                <p *ngIf="page.Judge.ApplicationAssessorFormJ.IsAssessorActive" class="control-label mb-0">
                  Please very briefly describe your role in the case, give approximate dates of the main hearing(s), and state how much of your oral advocacy the assessor has seen.<span>&nbsp;*</span>
                </p>

                <p *ngIf="!page.Judge.ApplicationAssessorFormJ.IsAssessorActive" class="control-label mb-0">
                  Please provide a reason for marking this assessor as not applicable<span>&nbsp;*</span>
                </p>

                <div class="form-group textarea-form">
                  <textarea maxlength="1000" autosize [minRows]="12" class="form-control mb-0 input-md" required #CaseDetails="ngModel" id="CaseDetails{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="CaseDetails{{i+1+page.Judge.AssessorJ.AssessorReference}}" type="text" [(ngModel)]="page.Judge.AssessorJ.CaseDetails" [ngClass]="{'is-invalid': CaseDetails?.invalid && showMessage}"></textarea>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="CaseDetails?.errors?.required && showMessage">This field is required.</div>
                  <span class="float-right label label-default" id="counter">{{page.Judge.AssessorJ.CaseDetails ? page.Judge.AssessorJ.CaseDetails.length : 0}} / 1000</span>
                  <p class="mb-0">
                    <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
                  </p>
                </div>
              </div>
            </div>

            <div *ngIf="page.Judge.ApplicationAssessorFormJ.IsAssessorActive">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="label-inline control-label">Judicial title<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <ng-select [items]="data.TitleIds" id="TitleId{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="TitleId{{i+1+page.Judge.AssessorJ.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Judge.AssessorJ.TitleId" #TitleId="ngModel" required [ngClass]="{'is-invalid': TitleId?.invalid && showMessage}">
                        </ng-select>
                        <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Judge.AssessorJ.TitleId == 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label" for="TitleOther">Title Other<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input class="form-control input-md" id="TitleOther{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="TitleOther{{i+1+page.Judge.AssessorJ.AssessorReference}}" type="text" [(ngModel)]="page.Judge.AssessorJ.TitleOther" #TitleOther="ngModel" required [ngClass]="{'is-invalid': TitleOther?.invalid && showMessage}">
                        <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Judge.AssessorJ.TitleId != 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Surname<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="LastName{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="LastName{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.LastName" #LastName="ngModel" required [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Full professional address <small>(not required if assessor retired)</small></label>
                      </div>

                      <div class="col-sm-9">
                        <textarea autosize [minRows]="5" class="form-control" id="Address1{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="Address1{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.Address1" #Address1="ngModel" [ngClass]="{'is-invalid': Address1?.invalid && showMessage}"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Judge.ApplicationAssessorFormJ.IsAssessorActive">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Judge.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page-15 warning-bg" *ngIf="page.Judge.ApplicationAssessorFormJ.IsAssessorActive">
      <div class="card warning-bg custom-card">
        <div class="card-body form-card">
          <h5>{{page.Judge.CaseReferenceJ.CaseReference}}</h5>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group" *ngIf="page.Judge.AssessorJ.TitleId == 493">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Surname<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="LastName{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="LastName{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.LastName" #LastName="ngModel" required [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Forename<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="FirstName{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="FirstName{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Phone</label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="Telephone{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="Telephone{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.Telephone" #Telephone="ngModel" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label label-inline">Email<span>&nbsp;*</span></label>
                  </div>
                  <div class="col-sm-6">
                    <input type="email" email class="form-control" #Email="ngModel" id="Email{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="Email{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.Email" required [ngClass]="{'is-invalid': Email?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.email">Email is not a valid format</div>
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Post code</label>
                      </div>

                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="Postcode{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="Postcode{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.Postcode" #Postcode="ngModel" [ngClass]="{'is-invalid': Postcode?.invalid && showMessage}" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-3 pr-0">
                      <div class="form-check mb-0 mr-0">
                        <label>
                          UK
                        </label>
                        <pb-switch class="required-denote custom-switch" id="IsUK{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="IsUK{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.IsUK" labelOn="Yes" labelOff="No" #IsUK="ngModel" [ngClass]="{'is-invalid': IsUK?.invalid && showMessage}">
                        </pb-switch>
                      </div>
                    </div>

                    <div class="col-sm-9" *ngIf="page.Judge.AssessorJ.IsUK === false">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="control-label mb-0">
                              Other<small class="mb-0">(Please state)</small>
                            </label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="Country{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="Country{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.Country" #Country="ngModel" [ngClass]="{'is-invalid': Country?.invalid && showMessage}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6 pr-0">
                    <label class="control-label mb-0">Judicial office currently or most recently held by this assessor<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <ng-select [items]="data.JudicialOfficeIds" id="JudicialOfficeId{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="JudicialOfficeId{{i+1+page.Judge.AssessorJ.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Judge.AssessorJ.JudicialOfficeId" #JudicialOfficeId="ngModel" required [ngClass]="{'is-invalid': JudicialOfficeId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="JudicialOfficeId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6" *ngIf="page.Judge.AssessorJ.JudicialOfficeId == 505">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label" for="TitleOther">Judicial Office Other<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input class="form-control input-md" id="JudicialOfficeExtra{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="JudicialOfficeExtra{{i+1+page.Judge.AssessorJ.AssessorReference}}" type="text" [(ngModel)]="page.Judge.AssessorJ.JudicialOfficeExtra" #JudicialOfficeExtra="ngModel" required [ngClass]="{'is-invalid': JudicialOfficeExtra?.invalid && showMessage}">
                    <div class="invalid-tooltip" *ngIf="JudicialOfficeExtra?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label mb-0">
                      If assessor is a Circuit or District Judge, in which region do they currently sit?<span *ngIf="page.Judge.AssessorJ.JudicialOfficeId === 503 || page.Judge.AssessorJ.JudicialOfficeId === 391">&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <ng-select [items]="data.RegionIds" id="RegionId{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="RegionId{{i+1+page.Judge.AssessorJ.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Judge.AssessorJ.RegionId" #RegionId="ngModel" [required]="page.Judge.AssessorJ.JudicialOfficeId === 503 || page.Judge.AssessorJ.JudicialOfficeId === 391" [ngClass]="{'is-invalid': RegionId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="RegionId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6 pr-0">
                    <label class="control-label mb-0">
                      Is this assessor a retired full-time Judge? <span>&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsJudge{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="IsJudge{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.IsJudge" labelOn="Yes" labelOff="No" #IsJudge="ngModel" required [ngClass]="{'is-invalid': IsJudge?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsJudge?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Is this assessor a KC?<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsQC{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="IsQC{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.IsQC" labelOn="Yes" labelOff="No" #IsQC="ngModel" required [ngClass]="{'is-invalid': IsQC?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsQC?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label mb-0">
                      Have you or this assessor ever been in the same chambers or firm at the same time?<span>&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsSameChamber{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="IsSameChamber{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.IsSameChamber" labelOn="Yes" labelOff="No" #IsSameChamber="ngModel" required [ngClass]="{'is-invalid': IsSameChamber?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsSameChamber?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label mb-0">
                    Is this assessor one of your two nominated Judicial assessors?<span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-6">
                  <ng-select [items]="data.NominatedIds" id="NominatedId{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="NominatedId{{i+1+page.Judge.AssessorJ.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Judge.AssessorJ.NominatedId" #NominatedId="ngModel" required [ngClass]="{'is-invalid': NominatedId?.invalid && showMessage}">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="NominatedId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-9">
                  <label>
                    If you would like to add an additional Judicial assessor for this Case {{page.Judge.ApplicationAssessorFormJ.OrderNo}}, please select Yes?<span class="required">&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-3">
                  <pb-switch  class="required-denote custom-switch float-right" id="IsAdditionalDisplay{{i+1+page.Judge.AssessorJ.AssessorReference}}" name="IsAdditionalDisplay{{i+1+page.Judge.AssessorJ.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJ.IsAdditionalDisplay" labelOn="Yes" labelOff="No" #IsAdditionalDisplay="ngModel" required [ngClass]="{'is-invalid': IsAdditionalDisplay?.invalid && showMessage}">
                  </pb-switch>
                  <div class="invalid-tooltip" *ngIf="IsAdditionalDisplay?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Judge.AssessorJ.IsAdditionalDisplay">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Judge.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Page 15-->
    <!--Page 15A-->
    <div class="page page-15 warning-bg" *ngIf="page.Judge.AssessorJ.IsAdditionalDisplay">
      <div class="card custom-card">
        <div class="card-body warning-bg form-card">
          <div>
            <h5 class="float-left">{{page.Judge.CaseReferenceJA.CaseReference}}</h5>

            <div class="table-responsive-md custom-table">
              <table class="table table-bordered primary-table">
                <colgroup>
                  <col>
                  <col style="width: 50%;">
                  <col style="width: 30%">
                  <col style="width: 10%;">
                  <col style="width: 10%;">
                </colgroup>
                <thead>
                  <tr>
                    <th rowspan="2">#</th>
                    <th rowspan="2">Name of case</th>
                    <th rowspan="2">Role as Advocate</th>
                    <th colspan="2" scope="colgroup" class="text-center">Dates (mm/yy)</th>
                    <th rowspan="2" class="text-center">Are you naming a <br />Judge(s) for this case?</th>
                  </tr>
                  <tr>
                    <th scope="colgroup" class="text-center">Start</th>
                    <th scope="colgroup" class="text-center">End</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{page.Judge.ApplicationAssessorFormJA.OrderNo}}</td>
                    <td>{{page.Judge.ApplicationAssessorFormJA.NameOfCase}}</td>
                    <td>{{page.Judge.ApplicationAssessorFormJA.Role}}</td>
                    <td class="text-center">{{page.Judge.ApplicationAssessorFormJA.StartDate}}</td>
                    <td class="text-center">{{page.Judge.ApplicationAssessorFormJA.EndDate}}</td>
                    <td class="text-center">
                      <pb-switch  class="required-denote custom-switch" [(ngModel)]="page.Judge.ApplicationAssessorFormJA.IsAssessorActive" name="IsAssessorActive{{i+1+page.Judge.AssessorJA.AssessorReference}}" id="IsAssessorActive{{i+1+page.Judge.AssessorJA.AssessorReference}}" labelOn="Yes" labelOff="No"></pb-switch>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-12 col-print-12 mb-15">
                <label class="mb-0">Brief description of case</label>
                <textarea autosize [minRows]="4" readonly id="BriefOfCase{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="BriefOfCase{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.ApplicationAssessorFormJA.BriefOfCase" class="form-control"></textarea>
              </div>

              <div class="col-sm-12 col-print-12">
                <p *ngIf="page.Judge.ApplicationAssessorFormJA.IsAssessorActive" class="control-label mb-0">
                  Please very briefly describe your role in the case, give approximate dates of the main hearing(s), and state how much of your oral advocacy the assessor has seen.<span>&nbsp;*</span>
                </p>

                <p *ngIf="!page.Judge.ApplicationAssessorFormJA.IsAssessorActive" class="control-label mb-0">
                  Please provide a reason for marking this assessor as not applicable<span>&nbsp;*</span>
                </p>

                <div class="form-group textarea-form">
                  <textarea maxlength="1000" autosize [minRows]="12" class="form-control mb-0 input-md" required #CaseDetails="ngModel" id="CaseDetails{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="CaseDetails{{i+1+page.Judge.AssessorJA.AssessorReference}}" type="text" [(ngModel)]="page.Judge.AssessorJA.CaseDetails" [ngClass]="{'is-invalid': CaseDetails?.invalid && showMessage}"></textarea>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="CaseDetails?.errors?.required && showMessage">This field is required.</div>
                  <span class="float-right label label-default" id="counter">{{page.Judge.AssessorJA.CaseDetails ? page.Judge.AssessorJA.CaseDetails.length : 0}} / 1000</span>
                  <p class="mb-0">
                    <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
                  </p>
                </div>
              </div>
            </div>

            <div *ngIf="page.Judge.ApplicationAssessorFormJA.IsAssessorActive">

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="label-inline control-label">Judicial title<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <ng-select [items]="data.TitleIds" id="TitleId{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="TitleId{{i+1+page.Judge.AssessorJA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Judge.AssessorJA.TitleId" #TitleId="ngModel" required [ngClass]="{'is-invalid': TitleId?.invalid && showMessage}">
                        </ng-select>
                        <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Judge.AssessorJA.TitleId == 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label" for="TitleOther">Title Other<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input class="form-control input-md" id="TitleOther{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="TitleOther{{i+1+page.Judge.AssessorJA.AssessorReference}}" type="text" [(ngModel)]="page.Judge.AssessorJA.TitleOther" #TitleOther="ngModel" required [ngClass]="{'is-invalid': TitleOther?.invalid && showMessage}">
                        <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Surname<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="LastName{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="LastName{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.LastName" #LastName="ngModel" required [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Judge.AssessorJA.TitleId != 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Forename<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="FirstName{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="FirstName{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Full professional address <small>(not required if assessor retired)</small></label>
                      </div>

                      <div class="col-sm-9">
                        <textarea autosize [minRows]="5" class="form-control" id="Address1{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="Address1{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.Address1" #Address1="ngModel" [ngClass]="{'is-invalid': Address1?.invalid && showMessage}"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Judge.ApplicationAssessorFormJA.IsAssessorActive">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Judge.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page-15 warning-bg" *ngIf="page.Judge.AssessorJ.IsAdditionalDisplay && page.Judge.ApplicationAssessorFormJA.IsAssessorActive">
      <div class="card custom-card">
        <div class="card-body warning-bg form-card">

          <h5>{{page.Judge.CaseReferenceJA.CaseReference}}</h5>

          <div class="row" *ngIf="page.Judge.AssessorJA.TitleId == 493">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Forename<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="FirstName{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="FirstName{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row  mb-5">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Phone</label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="Telephone{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="Telephone{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.Telephone" #Telephone="ngModel" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label label-inline">Email<span>&nbsp;*</span></label>
                  </div>
                  <div class="col-sm-6">
                    <input type="email" email class="form-control" #Email="ngModel" id="Email{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="Email{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.Email" required [ngClass]="{'is-invalid': Email?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.email">Email is not a valid format</div>
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Post code</label>
                      </div>

                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="Postcode{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="Postcode{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.Postcode" #Postcode="ngModel" [ngClass]="{'is-invalid': Postcode?.invalid && showMessage}" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-3 pr-0">
                      <div class="form-check mb-0 mr-0">
                        <label>
                          UK
                        </label>
                        <pb-switch class="required-denote custom-switch" id="IsUK{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="IsUK{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.IsUK" labelOn="Yes" labelOff="No" #IsUK="ngModel" [ngClass]="{'is-invalid': IsUK?.invalid && showMessage}">
                        </pb-switch>
                      </div>
                    </div>

                    <div class="col-sm-9" *ngIf="page.Judge.AssessorJA.IsUK === false">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="control-label mb-0">
                              Other<small class="mb-0">(Please state)</small>
                            </label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="Country{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="Country{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.Country" #Country="ngModel" [ngClass]="{'is-invalid': Country?.invalid && showMessage}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6 pr-0">
                    <label class="control-label mb-0">Judicial office currently or most recently held by this assessor<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <ng-select [items]="data.JudicialOfficeIds" id="JudicialOfficeId{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="JudicialOfficeId{{i+1+page.Judge.AssessorJA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Judge.AssessorJA.JudicialOfficeId" #JudicialOfficeId="ngModel" required [ngClass]="{'is-invalid': JudicialOfficeId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="JudicialOfficeId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-6" *ngIf="page.Judge.AssessorJA.JudicialOfficeId == 505">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-6">
                      <label class="control-label" for="TitleOther">Judicial Office Other<span>&nbsp;*</span></label>
                    </div>

                    <div class="col-sm-6">
                      <input class="form-control input-md" id="JudicialOfficeExtra{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="JudicialOfficeExtra{{i+1+page.Judge.AssessorJA.AssessorReference}}" type="text" [(ngModel)]="page.Judge.AssessorJA.JudicialOfficeExtra" #JudicialOfficeExtra="ngModel" required [ngClass]="{'is-invalid': JudicialOfficeExtra?.invalid && showMessage}">
                      <div class="invalid-tooltip" *ngIf="JudicialOfficeExtra?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label mb-0">
                      If assessor is a Circuit or District Judge, in which region do they currently sit?<span *ngIf="page.Judge.AssessorJA.JudicialOfficeId === 503 || page.Judge.AssessorJA.JudicialOfficeId === 391">&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <ng-select [items]="data.RegionIds" id="RegionId{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="RegionId{{i+1+page.Judge.AssessorJA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Judge.AssessorJA.RegionId" #RegionId="ngModel" [required]="page.Judge.AssessorJA.JudicialOfficeId === 503 || page.Judge.AssessorJA.JudicialOfficeId === 391" [ngClass]="{'is-invalid': RegionId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="RegionId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6 pr-0">
                    <label class="control-label mb-0">
                      Is this assessor a retired full-time Judge? <span>&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsJudge{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="IsJudge{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.IsJudge" labelOn="Yes" labelOff="No" #IsJudge="ngModel" required [ngClass]="{'is-invalid': IsJudge?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsJudge?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Is this assessor a KC?<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsQC{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="IsQC{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.IsQC" labelOn="Yes" labelOff="No" #IsQC="ngModel" required [ngClass]="{'is-invalid': IsQC?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsQC?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label mb-0">
                      Have you or this assessor ever been in the same chambers or firm at the same time?<span>&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsSameChamber{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="IsSameChamber{{i+1+page.Judge.AssessorJA.AssessorReference}}" [(ngModel)]="page.Judge.AssessorJA.IsSameChamber" labelOn="Yes" labelOff="No" #IsSameChamber="ngModel" required [ngClass]="{'is-invalid': IsSameChamber?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsSameChamber?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label mb-0">
                    Is this assessor one of your two nominated Judicial assessors?<span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-6">
                  <ng-select [items]="data.NominatedIds" id="NominatedId{{i+1+page.Judge.AssessorJA.AssessorReference}}" name="NominatedId{{i+1+page.Judge.AssessorJA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Judge.AssessorJA.NominatedId" #NominatedId="ngModel" required [ngClass]="{'is-invalid': NominatedId?.invalid && showMessage}">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="NominatedId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Judge.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Page 15A-->
    <!--Page 16-->
    <div class="page page-15 info-bg">
      <div class="page-breadcrumb">
        <div class="align-self-center">
          <div class="row">
            <div class="col-sm-12 col-print-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active"><span>{{heading.page16}}</span></li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="card custom-card">
        <div class="card-body info-bg form-card">
          <div>
            <h5 class="float-left">{{page.Practitioner.CaseReferenceP.CaseReference}}</h5>

            <div class="table-responsive-md custom-table">
              <table class="table table-bordered primary-table">
                <colgroup>
                  <col>
                  <col style="width: 50%;">
                  <col style="width: 30%">
                  <col style="width: 10%;">
                  <col style="width: 10%;">
                </colgroup>
                <thead>
                  <tr>
                    <th rowspan="2">#</th>
                    <th rowspan="2">Name of case</th>
                    <th rowspan="2">Role as Advocate</th>
                    <th colspan="2" scope="colgroup" class="text-center">Dates (mm/yy)</th>
                    <th rowspan="2" class="text-center">Are you naming a <br />Judge(s) for this case?</th>
                  </tr>
                  <tr>
                    <th scope="colgroup" class="text-center">Start</th>
                    <th scope="colgroup" class="text-center">End</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{page.Practitioner.ApplicationAssessorFormP.OrderNo}}</td>
                    <td>{{page.Practitioner.ApplicationAssessorFormP.NameOfCase}}</td>
                    <td>{{page.Practitioner.ApplicationAssessorFormP.Role}}</td>
                    <td class="text-center">{{page.Practitioner.ApplicationAssessorFormP.StartDate}}</td>
                    <td class="text-center">{{page.Practitioner.ApplicationAssessorFormP.EndDate}}</td>
                    <td class="text-center">
                      <pb-switch  class="required-denote custom-switch" [(ngModel)]="page.Practitioner.ApplicationAssessorFormP.IsAssessorActive" name="IsAssessorActive{{i+1+page.Practitioner.AssessorP.AssessorReference}}" id="IsAssessorActive{{i+1+page.Practitioner.AssessorP.AssessorReference}}" labelOn="Yes" labelOff="No"></pb-switch>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-12 col-print-4 mb-15">
                <label class="mb-0">Brief description of case</label>
                <textarea autosize [minRows]="4" readonly id="BriefOfCase{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="BriefOfCase{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.ApplicationAssessorFormP.BriefOfCase" class="form-control"></textarea>
              </div>

              <div class="col-sm-12 col-print-8">
                <p *ngIf="page.Practitioner.ApplicationAssessorFormP.IsAssessorActive" class="control-label mb-0">
                  Please very briefly describe your role in the case, give approximate dates of the main hearing(s), and state how much of your oral advocacy the assessor has seen.<span>&nbsp;*</span>
                </p>

                <p *ngIf="!page.Practitioner.ApplicationAssessorFormP.IsAssessorActive" class="control-label mb-0">
                  Please provide a reason for marking this assessor as not applicable<span>&nbsp;*</span>
                </p>

                <div class="form-group textarea-form">
                  <textarea maxlength="1000" autosize [minRows]="12" class="form-control mb-0 input-md" required #CaseDetails="ngModel" id="CaseDetails{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="CaseDetails{{i+1+page.Practitioner.AssessorP.AssessorReference}}" type="text" [(ngModel)]="page.Practitioner.AssessorP.CaseDetails" [ngClass]="{'is-invalid': CaseDetails?.invalid && showMessage}"></textarea>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="CaseDetails?.errors?.required && showMessage">This field is required.</div>
                  <span class="float-right label label-default" id="counter">{{page.Practitioner.AssessorP.CaseDetails ? page.Practitioner.AssessorP.CaseDetails.length : 0}} / 1000</span>
                  <p class="mb-0">
                    <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
                  </p>
                </div>
              </div>
            </div>

            <div *ngIf="page.Practitioner.ApplicationAssessorFormP.IsAssessorActive">

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="label-inline control-label">Title<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <ng-select [items]="data.TitleIds" id="TitleId{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="TitleId{{i+1+page.Practitioner.AssessorP.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Practitioner.AssessorP.TitleId" #TitleId="ngModel" required [ngClass]="{'is-invalid': TitleId?.invalid && showMessage}">
                        </ng-select>
                        <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Practitioner.AssessorP.TitleId == 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label" for="TitleOther">Title Other<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input class="form-control input-md" id="TitleOther{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="TitleOther{{i+1+page.Practitioner.AssessorP.AssessorReference}}" type="text" [(ngModel)]="page.Practitioner.AssessorP.TitleOther" #TitleOther="ngModel" required [ngClass]="{'is-invalid': TitleOther?.invalid && showMessage}">
                        <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Surname<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="LastName{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="LastName{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.LastName" #LastName="ngModel" required [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Practitioner.AssessorP.TitleId != 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Forename<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="FirstName{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="FirstName{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Full professional address</label>
                      </div>

                      <div class="col-sm-9">
                        <textarea autosize [minRows]="5" class="form-control" id="Address1{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="Address1{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.Address1" #Address1="ngModel" [ngClass]="{'is-invalid': Address1?.invalid && showMessage}"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Practitioner.ApplicationAssessorFormP.IsAssessorActive">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Practitioner.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page-15 info-bg" *ngIf="page.Practitioner.ApplicationAssessorFormP.IsAssessorActive">
      <div class="card custom-card">
        <div class="card-body info-bg form-card">
          <h5>{{page.Practitioner.CaseReferenceP.CaseReference}}</h5>

          <div class="row" *ngIf="page.Practitioner.AssessorP.TitleId == 493">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Forename<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="FirstName{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="FirstName{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row  mb-5">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Phone<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="Telephone{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="Telephone{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.Telephone" #Telephone="ngModel" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label label-inline">Email<span>&nbsp;*</span></label>
                  </div>
                  <div class="col-sm-6">
                    <input type="email" email class="form-control" #Email="ngModel" id="Email{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="Email{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.Email" required [ngClass]="{'is-invalid': Email?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.email">Email is not a valid format</div>
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Post code<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="Postcode{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="Postcode{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.Postcode" #Postcode="ngModel" [ngClass]="{'is-invalid': Postcode?.invalid && showMessage}" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4 pr-0">
                      <div class="form-check mb-0 mr-0">
                        <label class="control-label">
                          UK<span>&nbsp;*</span>
                        </label>
                        <pb-switch class="required-denote custom-switch" id="IsUK{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="IsUK{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.IsUK" labelOn="Yes" labelOff="No" #IsUK="ngModel" [ngClass]="{'is-invalid': IsUK?.invalid && showMessage}">
                        </pb-switch>
                      </div>
                    </div>

                    <div class="col-sm-8" *ngIf="page.Practitioner.AssessorP.IsUK === false">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="control-label mb-0">
                              Other<span>&nbsp;*</span><small class="mb-0">(Please state)</small>
                            </label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="Country{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="Country{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.Country" #Country="ngModel" [ngClass]="{'is-invalid': Country?.invalid && showMessage}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6 pr-0">
                    <label class="control-label mb-0">If this assessor now holds a full time judicial post, what office do they hold?</label>
                  </div>

                  <div class="col-sm-6">
                    <ng-select [items]="data.JudicialOfficeIds" id="JudicialOfficeId{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="JudicialOfficeId{{i+1+page.Practitioner.AssessorP.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Practitioner.AssessorP.JudicialOfficeId" #JudicialOfficeId="ngModel" required [ngClass]="{'is-invalid': JudicialOfficeId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="JudicialOfficeId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6" *ngIf="page.Practitioner.AssessorP.JudicialOfficeId == 505">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label" for="TitleOther">Judicial Office Other<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input class="form-control input-md" id="JudicialOfficeExtra{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="JudicialOfficeExtra{{i+1+page.Practitioner.AssessorP.AssessorReference}}" type="text" [(ngModel)]="page.Practitioner.AssessorP.JudicialOfficeExtra" #JudicialOfficeExtra="ngModel" required [ngClass]="{'is-invalid': JudicialOfficeExtra?.invalid && showMessage}">
                    <div class="invalid-tooltip" *ngIf="JudicialOfficeExtra?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label mb-0">
                      If assessor is a Circuit or District Judge, in which region do they currently sit?
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <ng-select [items]="data.RegionIds" id="RegionId{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="RegionId{{i+1+page.Practitioner.AssessorP.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Practitioner.AssessorP.RegionId" #RegionId="ngModel" [required]="page.Practitioner.AssessorP.JudicialOfficeId === 503 || page.Practitioner.AssessorP.JudicialOfficeId === 391" [ngClass]="{'is-invalid': RegionId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="RegionId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Is this assessor a KC?<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsQC{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="IsQC{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.IsQC" labelOn="Yes" labelOff="No" #IsQC="ngModel" required [ngClass]="{'is-invalid': IsQC?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsQC?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label mb-0">
                      Have you or this assessor ever been in the same chambers or firm at the same time?<span>&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsSameChamber{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="IsSameChamber{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.IsSameChamber" labelOn="Yes" labelOff="No" #IsSameChamber="ngModel" required [ngClass]="{'is-invalid': IsSameChamber?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsSameChamber?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label mb-0">
                    Is this assessor one of your two nominated Practitioner assessors?<span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-6">
                  <ng-select [items]="data.NominatedIds" id="NominatedId{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="NominatedId{{i+1+page.Practitioner.AssessorP.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Practitioner.AssessorP.NominatedId" #NominatedId="ngModel" required [ngClass]="{'is-invalid': NominatedId?.invalid && showMessage}">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="NominatedId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-9">
                  <label>
                    If you would like to add an additional Practitioner assessor for this Case {{page.Practitioner.ApplicationAssessorFormP.OrderNo}}, please select Yes?<span class="required">&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-3">
                  <pb-switch  class="required-denote custom-switch float-right" id="IsAdditionalDisplay{{i+1+page.Practitioner.AssessorP.AssessorReference}}" name="IsAdditionalDisplay{{i+1+page.Practitioner.AssessorP.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorP.IsAdditionalDisplay" labelOn="Yes" labelOff="No" #IsAdditionalDisplay="ngModel" required [ngClass]="{'is-invalid': IsAdditionalDisplay?.invalid && showMessage}">
                  </pb-switch>
                  <div class="invalid-tooltip" *ngIf="IsAdditionalDisplay?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Practitioner.AssessorP.IsAdditionalDisplay">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Practitioner.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Page 16-->
    <!--Page 16A-->
    <div class="page page-15 info-bg" *ngIf="page.Practitioner.AssessorP.IsAdditionalDisplay">
      <div class="card custom-card">
        <div class="card-body info-bg form-card">
          <div>
            <h5 class="float-left">{{page.Practitioner.CaseReferencePA.CaseReference}}</h5>

            <div class="table-responsive-md custom-table">
              <table class="table table-bordered primary-table">
                <colgroup>
                  <col>
                  <col style="width: 50%;">
                  <col style="width: 30%">
                  <col style="width: 10%;">
                  <col style="width: 10%;">
                </colgroup>
                <thead>
                  <tr>
                    <th rowspan="2">#</th>
                    <th rowspan="2">Name of case</th>
                    <th rowspan="2">Role as Advocate</th>
                    <th colspan="2" scope="colgroup" class="text-center">Dates (mm/yy)</th>
                    <th rowspan="2" class="text-center">Are you naming a <br />Judge(s) for this case?</th>
                  </tr>
                  <tr>
                    <th scope="colgroup" class="text-center">Start</th>
                    <th scope="colgroup" class="text-center">End</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{page.Practitioner.ApplicationAssessorFormPA.OrderNo}}</td>
                    <td>{{page.Practitioner.ApplicationAssessorFormPA.NameOfCase}}</td>
                    <td>{{page.Practitioner.ApplicationAssessorFormPA.Role}}</td>
                    <td class="text-center">{{page.Practitioner.ApplicationAssessorFormPA.StartDate}}</td>
                    <td class="text-center">{{page.Practitioner.ApplicationAssessorFormPA.EndDate}}</td>
                    <td class="text-center">
                      <pb-switch  class="required-denote custom-switch" [(ngModel)]="page.Practitioner.ApplicationAssessorFormPA.IsAssessorActive" name="IsAssessorActive{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" id="IsAssessorActive{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" labelOn="Yes" labelOff="No"></pb-switch>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-12 col-print-12 mb-15">
                <label class="mb-0">Brief description of case</label>
                <textarea autosize [minRows]="4" readonly id="BriefOfCase{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="BriefOfCase{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.ApplicationAssessorFormPA.BriefOfCase" class="form-control"></textarea>
              </div>

              <div class="col-sm-12 col-print-12">
                <p *ngIf="page.Practitioner.ApplicationAssessorFormPA.IsAssessorActive" class="control-label mb-0">
                  Please very briefly describe your role in the case, give approximate dates of the main hearing(s), and state how much of your oral advocacy the assessor has seen.<span>&nbsp;*</span>
                </p>

                <p *ngIf="!page.Practitioner.ApplicationAssessorFormPA.IsAssessorActive" class="control-label mb-0">
                  Please provide a reason for marking this assessor as not applicable<span>&nbsp;*</span>
                </p>

                <div class="form-group textarea-form">
                  <textarea maxlength="1000" autosize [minRows]="12" class="form-control mb-0 input-md" required #CaseDetails="ngModel" id="CaseDetails{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="CaseDetails{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" type="text" [(ngModel)]="page.Practitioner.AssessorPA.CaseDetails" [ngClass]="{'is-invalid': CaseDetails?.invalid && showMessage}"></textarea>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="CaseDetails?.errors?.required && showMessage">This field is required.</div>
                  <span class="float-right label label-default" id="counter">{{page.Practitioner.AssessorPA.CaseDetails ? page.Practitioner.AssessorPA.CaseDetails.length : 0}} / 1000</span>
                  <p class="mb-0">
                    <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
                  </p>
                </div>
              </div>
            </div>

            <div *ngIf="page.Practitioner.ApplicationAssessorFormPA.IsAssessorActive">

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="label-inline control-label">Title<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <ng-select [items]="data.TitleIds" id="TitleId{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="TitleId{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Practitioner.AssessorPA.TitleId" #TitleId="ngModel" required [ngClass]="{'is-invalid': TitleId?.invalid && showMessage}">
                        </ng-select>
                        <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Practitioner.AssessorPA.TitleId == 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label" for="TitleOther">Title Other<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input class="form-control input-md" id="TitleOther{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="TitleOther{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" type="text" [(ngModel)]="page.Practitioner.AssessorPA.TitleOther" #TitleOther="ngModel" required [ngClass]="{'is-invalid': TitleOther?.invalid && showMessage}">
                        <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Surname<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="LastName{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="LastName{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.LastName" #LastName="ngModel" required [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Practitioner.AssessorPA.TitleId != 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Forename<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="FirstName{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="FirstName{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Full professional address</label>
                      </div>

                      <div class="col-sm-9">
                        <textarea autosize [minRows]="5" class="form-control" id="Address1{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="Address1{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.Address1" #Address1="ngModel" [ngClass]="{'is-invalid': Address1?.invalid && showMessage}"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Practitioner.ApplicationAssessorFormPA.IsAssessorActive">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Practitioner.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page-15 info-bg" *ngIf="page.Practitioner.AssessorP.IsAdditionalDisplay && page.Practitioner.ApplicationAssessorFormPA.IsAssessorActive">
      <div class="card custom-card">
        <div class="card-body info-bg form-card">
          <h5>{{page.Practitioner.CaseReferencePA.CaseReference}}</h5>

          <div class="row" *ngIf="page.Practitioner.AssessorPA.TitleId == 493">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Forename<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="FirstName{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="FirstName{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row  mb-5">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Phone<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="Telephone{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="Telephone{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.Telephone" #Telephone="ngModel" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label label-inline">Email<span>&nbsp;*</span></label>
                  </div>
                  <div class="col-sm-6">
                    <input type="email" email class="form-control" #Email="ngModel" id="Email{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="Email{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.Email" required [ngClass]="{'is-invalid': Email?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.email">Email is not a valid format</div>
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Post code<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="Postcode{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="Postcode{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.Postcode" #Postcode="ngModel" [ngClass]="{'is-invalid': Postcode?.invalid && showMessage}" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4 pr-0">
                      <div class="form-check mb-0 mr-0">
                        <label class="control-label">
                          UK<span>&nbsp;*</span>
                        </label>
                        <pb-switch class="required-denote custom-switch" id="IsUK{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="IsUK{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.IsUK" labelOn="Yes" labelOff="No" #IsUK="ngModel" [ngClass]="{'is-invalid': IsUK?.invalid && showMessage}">
                        </pb-switch>
                      </div>
                    </div>

                    <div class="col-sm-8" *ngIf="page.Practitioner.AssessorPA.IsUK === false">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="control-label mb-0">
                              Other<span>&nbsp;*</span><small class="mb-0">(Please state)</small>
                            </label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="Country{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="Country{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.Country" #Country="ngModel" [ngClass]="{'is-invalid': Country?.invalid && showMessage}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6 pr-0">
                    <label class="control-label mb-0">If this assessor now holds a full time judicial post, what office do they hold?</label>
                  </div>

                  <div class="col-sm-6">
                    <ng-select [items]="data.JudicialOfficeIds" id="JudicialOfficeId{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="JudicialOfficeId{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Practitioner.AssessorPA.JudicialOfficeId" #JudicialOfficeId="ngModel" required [ngClass]="{'is-invalid': JudicialOfficeId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="JudicialOfficeId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6" *ngIf="page.Practitioner.AssessorPA.JudicialOfficeId == 505">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label" for="TitleOther">Judicial Office Other<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input class="form-control input-md" id="JudicialOfficeExtra{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="JudicialOfficeExtra{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" type="text" [(ngModel)]="page.Practitioner.AssessorPA.JudicialOfficeExtra" #JudicialOfficeExtra="ngModel" required [ngClass]="{'is-invalid': JudicialOfficeExtra?.invalid && showMessage}">
                    <div class="invalid-tooltip" *ngIf="JudicialOfficeExtra?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label mb-0">
                      If assessor is a Circuit or District Judge, in which region do they currently sit?
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <ng-select [items]="data.RegionIds" id="RegionId{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="RegionId{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Practitioner.AssessorPA.RegionId" #RegionId="ngModel" [required]="page.Practitioner.AssessorPA.JudicialOfficeId === 503 || page.Practitioner.AssessorPA.JudicialOfficeId === 391" [ngClass]="{'is-invalid': RegionId?.invalid && showMessage}">
                    </ng-select>
                    <div class="invalid-tooltip" *ngIf="RegionId?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Is this assessor a KC?<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsQC{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="IsQC{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.IsQC" labelOn="Yes" labelOff="No" #IsQC="ngModel" required [ngClass]="{'is-invalid': IsQC?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsQC?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label mb-0">
                      Have you or this assessor ever been in the same chambers or firm at the same time?<span>&nbsp;*</span>
                    </label>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-check mb-0">
                      <pb-switch class="required-denote custom-switch" id="IsSameChamber{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="IsSameChamber{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" [(ngModel)]="page.Practitioner.AssessorPA.IsSameChamber" labelOn="Yes" labelOff="No" #IsSameChamber="ngModel" required [ngClass]="{'is-invalid': IsSameChamber?.invalid && showMessage}">
                      </pb-switch>
                      <div class="invalid-tooltip" *ngIf="IsSameChamber?.errors?.required && showMessage">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label mb-0">
                    Is this assessor one of your two nominated Practitioner assessors?<span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-6">
                  <ng-select [items]="data.NominatedIds" id="NominatedId{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" name="NominatedId{{i+1+page.Practitioner.AssessorPA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Practitioner.AssessorPA.NominatedId" #NominatedId="ngModel" required [ngClass]="{'is-invalid': NominatedId?.invalid && showMessage}">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="NominatedId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Practitioner.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Page 16A-->
    <!--Page 17-->
    <div class="page page-15 alert-bg">
      <div class="page-breadcrumb">
        <div class="align-self-center">
          <div class="row">
            <div class="col-sm-12 col-print-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active"><span>{{heading.page17}}</span></li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="card custom-card">
        <div class="card-body alert-bg form-card">
          <div>
            <h5 class="float-left">{{page.Client.CaseReferenceC.CaseReference}}</h5>

            <div class="table-responsive-md custom-table">
              <table class="table table-bordered primary-table">
                <colgroup>
                  <col>
                  <col style="width: 50%;">
                  <col style="width: 30%">
                  <col style="width: 10%;">
                  <col style="width: 10%;">
                </colgroup>
                <thead>
                  <tr>
                    <th rowspan="2">#</th>
                    <th rowspan="2">Name of case</th>
                    <th rowspan="2">Role as Advocate</th>
                    <th colspan="2" scope="colgroup" class="text-center">Dates (mm/yy)</th>
                    <th rowspan="2" class="text-center">Are you naming a <br />Judge(s) for this case?</th>
                  </tr>
                  <tr>
                    <th scope="colgroup" class="text-center">Start</th>
                    <th scope="colgroup" class="text-center">End</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{page.Client.ApplicationAssessorFormC.OrderNo}}</td>
                    <td>{{page.Client.ApplicationAssessorFormC.NameOfCase}}</td>
                    <td>{{page.Client.ApplicationAssessorFormC.Role}}</td>
                    <td class="text-center">{{page.Client.ApplicationAssessorFormC.StartDate}}</td>
                    <td class="text-center">{{page.Client.ApplicationAssessorFormC.EndDate}}</td>
                    <td class="text-center">
                      <pb-switch  class="required-denote custom-switch" [(ngModel)]="page.Client.ApplicationAssessorFormC.IsAssessorActive" name="IsAssessorActive{{i+1+page.Client.AssessorC.AssessorReference}}" id="IsAssessorActive{{i+1+page.Client.AssessorC.AssessorReference}}" labelOn="Yes" labelOff="No"></pb-switch>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-12 col-print-12 mb-15">
                <label class="mb-0">Brief description of case</label>
                <textarea autosize [minRows]="4" readonly id="BriefOfCase{{i+1+page.Client.AssessorC.AssessorReference}}" name="BriefOfCase{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.ApplicationAssessorFormC.BriefOfCase" class="form-control"></textarea>
              </div>

              <div class="col-sm-12 col-print-12">
                <p *ngIf="page.Client.ApplicationAssessorFormC.IsAssessorActive" class="control-label mb-0">
                  Please very briefly describe your role in the case, give approximate dates of the main hearing(s), and state how much of your oral advocacy the assessor has seen.<span>&nbsp;*</span>
                </p>

                <p *ngIf="!page.Client.ApplicationAssessorFormC.IsAssessorActive" class="control-label mb-0">
                  Please provide a reason for marking this assessor as not applicable<span>&nbsp;*</span>
                </p>

                <div class="form-group textarea-form">
                  <textarea maxlength="1000" autosize [minRows]="12" class="form-control mb-0 input-md" required #CaseDetails="ngModel" id="CaseDetails{{i+1+page.Client.AssessorC.AssessorReference}}" name="CaseDetails{{i+1+page.Client.AssessorC.AssessorReference}}" type="text" [(ngModel)]="page.Client.AssessorC.CaseDetails" [ngClass]="{'is-invalid': CaseDetails?.invalid && showMessage}"></textarea>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="CaseDetails?.errors?.required && showMessage">This field is required.</div>
                  <span class="float-right label label-default" id="counter">{{page.Client.AssessorC.CaseDetails ? page.Client.AssessorC.CaseDetails.length : 0}} / 1000</span>
                  <p class="mb-0">
                    <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
                  </p>
                </div>
              </div>
            </div>

            <div *ngIf="page.Client.ApplicationAssessorFormC.IsAssessorActive">

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="label-inline control-label">Title<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <ng-select [items]="data.TitleIds" id="TitleId{{i+1+page.Client.AssessorC.AssessorReference}}" name="TitleId{{i+1+page.Client.AssessorC.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Client.AssessorC.TitleId" #TitleId="ngModel" required [ngClass]="{'is-invalid': TitleId?.invalid && showMessage}">
                        </ng-select>
                        <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Client.AssessorC.TitleId == 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label" for="TitleOther">Title Other<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input class="form-control input-md" id="TitleOther{{i+1+page.Client.AssessorC.AssessorReference}}" name="TitleOther{{i+1+page.Client.AssessorC.AssessorReference}}" type="text" [(ngModel)]="page.Client.AssessorC.TitleOther" #TitleOther="ngModel" required [ngClass]="{'is-invalid': TitleOther?.invalid && showMessage}">
                        <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Surname<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="LastName{{i+1+page.Client.AssessorC.AssessorReference}}" name="LastName{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.LastName" #LastName="ngModel" required [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Client.AssessorC.TitleId != 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Forename<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="FirstName{{i+1+page.Client.AssessorC.AssessorReference}}" name="FirstName{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Full professional address</label>
                      </div>

                      <div class="col-sm-9">
                        <textarea autosize [minRows]="5" class="form-control" id="Address1{{i+1+page.Client.AssessorC.AssessorReference}}" name="Address1{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.Address1" #Address1="ngModel" [ngClass]="{'is-invalid': Address1?.invalid && showMessage}"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Client.ApplicationAssessorFormC.IsAssessorActive">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Client.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page-15 alert-bg" *ngIf="page.Client.ApplicationAssessorFormC.IsAssessorActive">
      <div class="card custom-card">
        <div class="card-body alert-bg form-card">
          <h5>{{page.Client.CaseReferenceC.CaseReference}}</h5>

          <div class="row" *ngIf="page.Client.AssessorC.TitleId == 493">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Forename<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="FirstName{{i+1+page.Client.AssessorC.AssessorReference}}" name="FirstName{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row  mb-5">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Phone<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="Telephone{{i+1+page.Client.AssessorC.AssessorReference}}" name="Telephone{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.Telephone" #Telephone="ngModel" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label label-inline">Email<span>&nbsp;*</span></label>
                  </div>
                  <div class="col-sm-6">
                    <input type="email" email class="form-control" #Email="ngModel" id="Email{{i+1+page.Client.AssessorC.AssessorReference}}" name="Email{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.Email" required [ngClass]="{'is-invalid': Email?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.email">Email is not a valid format</div>
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Post code<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="Postcode{{i+1+page.Client.AssessorC.AssessorReference}}" name="Postcode{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.Postcode" #Postcode="ngModel" [ngClass]="{'is-invalid': Postcode?.invalid && showMessage}" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4 pr-0">
                      <div class="form-check mb-0 mr-0">
                        <label class="control-label">
                          UK<span>&nbsp;*</span>
                        </label>
                        <pb-switch class="required-denote custom-switch" id="IsUK{{i+1+page.Client.AssessorC.AssessorReference}}" name="IsUK{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.IsUK" labelOn="Yes" labelOff="No" #IsUK="ngModel" [ngClass]="{'is-invalid': IsUK?.invalid && showMessage}">
                        </pb-switch>
                      </div>
                    </div>

                    <div class="col-sm-8" *ngIf="page.Client.AssessorC.IsUK === false">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="control-label mb-0">
                              Other<span>&nbsp;*</span><small class="mb-0">(Please state)</small>
                            </label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="Country{{i+1+page.Client.AssessorC.AssessorReference}}" name="Country{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.Country" #Country="ngModel" [ngClass]="{'is-invalid': Country?.invalid && showMessage}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label mb-0">
                    Is this assessor one of your two nominated Client assessors?<span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-6">
                  <ng-select [items]="data.NominatedIds" id="NominatedId{{i+1+page.Client.AssessorC.AssessorReference}}" name="NominatedId{{i+1+page.Client.AssessorC.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Client.AssessorC.NominatedId" #NominatedId="ngModel" required [ngClass]="{'is-invalid': NominatedId?.invalid && showMessage}">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="NominatedId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-9">
                  <label>
                    If you would like to add an additional Client assessor for this Case {{page.Client.ApplicationAssessorFormC.OrderNo}}, please select Yes?<span class="required">&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-3">
                  <pb-switch  class="required-denote custom-switch float-right" id="IsAdditionalDisplay{{i+1+page.Client.AssessorC.AssessorReference}}" name="IsAdditionalDisplay{{i+1+page.Client.AssessorC.AssessorReference}}" [(ngModel)]="page.Client.AssessorC.IsAdditionalDisplay" labelOn="Yes" labelOff="No" #IsAdditionalDisplay="ngModel" required [ngClass]="{'is-invalid': IsAdditionalDisplay?.invalid && showMessage}">
                  </pb-switch>
                  <div class="invalid-tooltip" *ngIf="IsAdditionalDisplay?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Client.AssessorC.IsAdditionalDisplay">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Client.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Page 17-->
    <!--Page 17A-->
    <div class="page page-15 alert-bg" *ngIf="page.Client.AssessorC.IsAdditionalDisplay">
      <div class="card custom-card">
        <div class="card-body alert-bg form-card">
          <div>
            <h5 class="float-left">{{page.Client.CaseReferenceCA.CaseReference}}</h5>

            <div class="table-responsive-md custom-table">
              <table class="table table-bordered primary-table">
                <colgroup>
                  <col>
                  <col style="width: 50%;">
                  <col style="width: 30%">
                  <col style="width: 10%;">
                  <col style="width: 10%;">
                </colgroup>
                <thead>
                  <tr>
                    <th rowspan="2">#</th>
                    <th rowspan="2">Name of case</th>
                    <th rowspan="2">Role as Advocate</th>
                    <th colspan="2" scope="colgroup" class="text-center">Dates (mm/yy)</th>
                    <th rowspan="2" class="text-center">Are you naming a <br />Judge(s) for this case?</th>
                  </tr>
                  <tr>
                    <th scope="colgroup" class="text-center">Start</th>
                    <th scope="colgroup" class="text-center">End</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{page.Client.ApplicationAssessorFormCA.OrderNo}}</td>
                    <td>{{page.Client.ApplicationAssessorFormCA.NameOfCase}}</td>
                    <td>{{page.Client.ApplicationAssessorFormCA.Role}}</td>
                    <td class="text-center">{{page.Client.ApplicationAssessorFormCA.StartDate}}</td>
                    <td class="text-center">{{page.Client.ApplicationAssessorFormCA.EndDate}}</td>
                    <td class="text-center">
                      <pb-switch  class="required-denote custom-switch" [(ngModel)]="page.Client.ApplicationAssessorFormCA.IsAssessorActive" name="IsAssessorActive{{i+1+page.Client.AssessorCA.AssessorReference}}" id="IsAssessorActive{{i+1+page.Client.AssessorCA.AssessorReference}}" labelOn="Yes" labelOff="No"></pb-switch>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-12 col-print-12 mb-15">
                <label class="mb-0">Brief description of case</label>
                <textarea autosize [minRows]="4" readonly id="BriefOfCase{{i+1+page.Client.AssessorCA.AssessorReference}}" name="BriefOfCase{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.ApplicationAssessorFormCA.BriefOfCase" class="form-control"></textarea>
              </div>

              <div class="col-sm-12 col-print-12">
                <p *ngIf="page.Client.ApplicationAssessorFormCA.IsAssessorActive" class="control-label mb-0">
                  Please very briefly describe your role in the case, give approximate dates of the main hearing(s), and state how much of your oral advocacy the assessor has seen.<span>&nbsp;*</span>
                </p>

                <p *ngIf="!page.Client.ApplicationAssessorFormCA.IsAssessorActive" class="control-label mb-0">
                  Please provide a reason for marking this assessor as not applicable<span>&nbsp;*</span>
                </p>

                <div class="form-group textarea-form">
                  <textarea maxlength="1000" autosize [minRows]="12" class="form-control mb-0 input-md" required #CaseDetails="ngModel" id="CaseDetails{{i+1+page.Client.AssessorCA.AssessorReference}}" name="CaseDetails{{i+1+page.Client.AssessorCA.AssessorReference}}" type="text" [(ngModel)]="page.Client.AssessorCA.CaseDetails" [ngClass]="{'is-invalid': CaseDetails?.invalid && showMessage}"></textarea>
                  <div class="invalid-tooltip textarea-tooltip" *ngIf="CaseDetails?.errors?.required && showMessage">This field is required.</div>
                  <span class="float-right label label-default" id="counter">{{page.Client.AssessorCA.CaseDetails ? page.Client.AssessorCA.CaseDetails.length : 0}} / 1000</span>
                  <p class="mb-0">
                    <small class="label-inline"> Please note there is a limit of 1000 characters for this box.</small>
                  </p>
                </div>
              </div>
            </div>

            <div *ngIf="page.Client.ApplicationAssessorFormCA.IsAssessorActive">

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="label-inline control-label">Title<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <ng-select [items]="data.TitleIds" id="TitleId{{i+1+page.Client.AssessorCA.AssessorReference}}" name="TitleId{{i+1+page.Client.AssessorCA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Client.AssessorCA.TitleId" #TitleId="ngModel" required [ngClass]="{'is-invalid': TitleId?.invalid && showMessage}">
                        </ng-select>
                        <div class="invalid-tooltip" *ngIf="TitleId?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Client.AssessorCA.TitleId == 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label" for="TitleOther">Title Other<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input class="form-control input-md" id="TitleOther{{i+1+page.Client.AssessorCA.AssessorReference}}" name="TitleOther{{i+1+page.Client.AssessorCA.AssessorReference}}" type="text" [(ngModel)]="page.Client.AssessorCA.TitleOther" #TitleOther="ngModel" required [ngClass]="{'is-invalid': TitleOther?.invalid && showMessage}">
                        <div class="invalid-tooltip" *ngIf="TitleOther?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Surname<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="LastName{{i+1+page.Client.AssessorCA.AssessorReference}}" name="LastName{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.LastName" #LastName="ngModel" required [ngClass]="{'is-invalid': LastName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="LastName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="page.Client.AssessorCA.TitleId != 493">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label">Forename<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-6">
                        <input type="text" class="form-control" id="FirstName{{i+1+page.Client.AssessorCA.AssessorReference}}" name="FirstName{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                        <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Full professional address</label>
                      </div>

                      <div class="col-sm-9">
                        <textarea autosize [minRows]="5" class="form-control" id="Address1{{i+1+page.Client.AssessorCA.AssessorReference}}" name="Address1{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.Address1" #Address1="ngModel" [ngClass]="{'is-invalid': Address1?.invalid && showMessage}"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="!page.Client.ApplicationAssessorFormCA.IsAssessorActive">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Client.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page-15 alert-bg" *ngIf="page.Client.AssessorC.IsAdditionalDisplay && page.Client.ApplicationAssessorFormCA.IsAssessorActive">
      <div class="card custom-card">
        <div class="card-body alert-bg form-card">
          <h5>{{page.Client.CaseReferenceCA.CaseReference}}</h5>

          <div class="row" *ngIf="page.Client.AssessorCA.TitleId == 493">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Forename<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="FirstName{{i+1+page.Client.AssessorCA.AssessorReference}}" name="FirstName{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.FirstName" #FirstName="ngModel" required [ngClass]="{'is-invalid': FirstName?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="FirstName?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row  mb-5">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label">Phone<span>&nbsp;*</span></label>
                  </div>

                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="Telephone{{i+1+page.Client.AssessorCA.AssessorReference}}" name="Telephone{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.Telephone" #Telephone="ngModel" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label label-inline">Email<span>&nbsp;*</span></label>
                  </div>
                  <div class="col-sm-6">
                    <input type="email" email class="form-control" #Email="ngModel" id="Email{{i+1+page.Client.AssessorCA.AssessorReference}}" name="Email{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.Email" required [ngClass]="{'is-invalid': Email?.invalid && showMessage}" />
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.email">Email is not a valid format</div>
                    <div class="invalid-tooltip" *ngIf="Email?.errors?.required && showMessage">This field is required.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label">Post code<span>&nbsp;*</span></label>
                      </div>

                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="Postcode{{i+1+page.Client.AssessorCA.AssessorReference}}" name="Postcode{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.Postcode" #Postcode="ngModel" [ngClass]="{'is-invalid': Postcode?.invalid && showMessage}" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4 pr-0">
                      <div class="form-check mb-0 mr-0">
                        <label class="control-label">
                          UK<span>&nbsp;*</span>
                        </label>
                        <pb-switch class="required-denote custom-switch" id="IsUK{{i+1+page.Client.AssessorCA.AssessorReference}}" name="IsUK{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.IsUK" labelOn="Yes" labelOff="No" #IsUK="ngModel" [ngClass]="{'is-invalid': IsUK?.invalid && showMessage}">
                        </pb-switch>
                      </div>
                    </div>

                    <div class="col-sm-8" *ngIf="page.Client.AssessorCA.IsUK === false">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="control-label mb-0">
                              Other<span>&nbsp;*</span><small class="mb-0">(Please state)</small>
                            </label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="Country{{i+1+page.Client.AssessorCA.AssessorReference}}" name="Country{{i+1+page.Client.AssessorCA.AssessorReference}}" [(ngModel)]="page.Client.AssessorCA.Country" #Country="ngModel" [ngClass]="{'is-invalid': Country?.invalid && showMessage}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label mb-0">
                    Is this assessor one of your two nominated Client assessors?<span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-6">
                  <ng-select [items]="data.NominatedIds" id="NominatedId{{i+1+page.Client.AssessorCA.AssessorReference}}" name="NominatedId{{i+1+page.Client.AssessorCA.AssessorReference}}" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="page.Client.AssessorCA.NominatedId" #NominatedId="ngModel" required [ngClass]="{'is-invalid': NominatedId?.invalid && showMessage}">
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="NominatedId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-sm-3 col-print-2 pl-0 cardPagin">
              <p class="required">* denotes required fields </p>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-2 col-print-2">
                    <label for="ID">ID:</label>
                  </div>

                  <div class="col-sm-10 col-print-10">
                    <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 col-print-3">
                    <label for="Surname">Surname:</label>
                  </div>

                  <div class="col-sm-9 col-print-9">
                    <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-3 col-print-4 cardPagin text-right">
              <label><p><span>Page </span><span>{{page.Client.PageNo}}</span> of <span>52</span></p></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Page 17A-->
  </div>
  <!--Page 15-50-->
  <!--Page 51-->
  <div class="page">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page51}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">

        <div class="form-group">
          <p>
            Please complete this questionnaire. It will help us to monitor the effectiveness of our equal opportunities policies. This page will not be provided to Panel members or assessors and the information in it will play no part in the decision whether or not you should be recommended for appointment.
          </p>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-4 col-print-4">
              <label>
                Date of birth<span class="required">&nbsp;*</span>
              </label>
            </div>

            <div class="col-sm-4 col-print-4">
              <div class="input-group" (clickOutside)="DoBPicker.close()">
                <input class="form-control" #DoB="ngModel" #DoBPicker="ngbDatepicker" required id="DoB" name="DoB" [(ngModel)]="DoBDate" ngbDatepicker [ngClass]="{'is-invalid': DoB?.invalid && showMessage}">
                <div class="input-group-prepend height-40">
                  <div class="input-group-append">
                    <button class="btn" (click)="DoBPicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                  </div>
                </div>
                <div class="invalid-tooltip" *ngIf="DoB?.errors?.required && showMessage">This field is required.</div>
                <div class="invalid-tooltip" *ngIf="DoBDate && DoB?.invalid">Please enter date in (dd/mm/yyyy).</div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-4  col-print-4">
              <label class="label-inline">Gender<span>&nbsp;*</span></label>
            </div>

            <div class="col-sm-4  col-print-4">
              <ng-select required [items]="data.GenderIds" #GenderId="ngModel" id="GenderId" name="GenderId" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.GenderId"></ng-select>
              <div class="invalid-tooltip" *ngIf="GenderId?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>

        <div class="form-group">

          <div class="row">
            <div class="col-sm-6  col-print-6 pr-0">
              <p>
                Please select 'Yes' if you consider you have a disability as defined in the Equality Act 2010<span class="required">&nbsp;*</span>
                <small class="mb-0">
                  (The Equality Act defines a person with a disability as someone who has 'a physical or mental impairment, which has a substantial long-term adverse effect on [their] ability to carry out normal day-to-day activities'. Long term means 12 months or more)
                </small>
              </p>
            </div>

            <div class="col-sm-2  col-print-2">
              <pb-switch required class="required-denote custom-switch float-right" #IsDisability="ngModel" id="IsDisability" [(ngModel)]="data.IsDisability" name="IsDisability" labelOn="Yes" labelOff="No"></pb-switch>
              <div class="invalid-tooltip" *ngIf="IsDisability?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>

        <div class="form-group">

          <div class="row">
            <div class="col-sm-6 col-print-6">
              <div class="row">
                <div class="col-sm-6  col-print-6">
                  <p class="mb-0">
                    I would describe my ethnic origin as <small class="mb-0">(Please select the one with which you most identify)</small><span class="required">*</span>
                  </p>
                </div>

                <div class="col-sm-6">
                  <ng-select [items]="data.EthnicOriginIds"
                             required
                             id="EthnicOriginId"
                             name="EthnicOriginId"
                             bindLabel="LookupValue"
                             bindValue="LookupId"
                             groupBy="LookupValueShow"
                             [multiple]="false"
                             #EthnicOriginId="ngModel"
                             [(ngModel)]="data.EthnicOriginId">
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{item.LookupValueShow || 'Unnamed group'}}
                    </ng-template>
                  </ng-select>
                  <div class="invalid-tooltip" *ngIf="EthnicOriginId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-6  col-print-6" *ngIf="data.EthnicOriginId == 429 || data.EthnicOriginId == 512 || data.EthnicOriginId == 513 || data.EthnicOriginId == 514 || data.EthnicOriginId == 2629">
              <div class="row">
                <div class="col-sm-3  col-print-3">
                  <label class="control-label mb-0">
                    Other<small class="mb-0">(please state)</small><span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-6  col-print-6">
                  <input class="form-control input-md" #EthnicOther="ngModel" required id="EthnicOther" name="EthnicOther" type="text" [(ngModel)]="data.EthnicOther">
                  <div class="invalid-tooltip" *ngIf="EthnicOther?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="form-group">

          <div class="row">
            <div class="col-sm-6  col-print-6">
              <div class="row">
                <div class="col-sm-6  col-print-6">
                  <p>
                    Sexual Orientation<span class="required">&nbsp;*</span>
                  </p>
                </div>

                <div class="col-sm-6  col-print-6">
                  <ng-select required [items]="data.SexualOrientationIds" #SexualOrientationId="ngModel" id="SexualOrientationId" name="SexualOrientationId" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.SexualOrientationId"></ng-select>
                  <div class="invalid-tooltip" *ngIf="SexualOrientationId?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-6  col-print-6" *ngIf="data.SexualOrientationId == 2619">
              <div class="row">
                <div class="col-sm-3  col-print-3">
                  <label class="control-label mb-0">
                    Other<small class="mb-0">(please state)</small><span>&nbsp;*</span>
                  </label>
                </div>

                <div class="col-sm-6  col-print-6">
                  <input class="form-control input-md" #SexualOrientationOther="ngModel" required id="SexualOrientationOther" name="SexualOrientationOther" type="text" [(ngModel)]="data.SexualOrientationOther">

                  <div class="invalid-tooltip" *ngIf="SexualOrientationOther?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>51</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 51-->
  <!--Page 52-->
  <div class="page page-52">
    <div class="page-breadcrumb">
      <div class="align-self-center">
        <div class="row">
          <div class="col-sm-12 col-print-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span>{{heading.page52}}</span></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card">
      <div class="card-body form-card">
        <p>Please ensure you have completed all sections of the form before signing the declaration and form of authority below.</p>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <label>Professional surname</label>
            </div>

            <div class="col-sm-4">
              <input type="text" readonly class="form-control" #LastName="ngModel" id="LastName" name="LastName" [(ngModel)]="data.LastName" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <label>Forenames in full</label>
            </div>

            <div class="col-sm-4">
              <input type="text" readonly class="form-control" #FirstName="ngModel" id="FirstName" name="FirstName" [(ngModel)]="data.FirstName" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <label>Name of chambers, firm or employer</label>
            </div>

            <div class="col-sm-4">
              <input type="text" readonly class="form-control" #Chambers="ngModel" id="Chambers" name="Chambers" [(ngModel)]="data.Chambers" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <label>Your Law Society roll number/Bar Council membership number</label>
            </div>

            <div class="col-sm-3">
              <input type="text" readonly class="form-control" #Reference="ngModel" id="Reference" name="Reference" [(ngModel)]="data.Reference" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <label>Date of call or admission into current branch of the profession</label>
            </div>

            <div class="col-sm-3">
              <input type="text" readonly class="form-control" #BarDate="ngModel" id="BarDate" name="BarDate" [(ngModel)]="data.BarDate" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label class="d-block mb-5"><b>Declaration</b></label>
            <label class="d-block mb-5">
              I declare by my signature below and submitting this application online, that to the best of my knowledge, the information I have given on this form is correct
              and complete.
            </label>
            <label class="d-block mb-5">
              I further declare that there is nothing in my professional background (other than what I have disclosed in this application) which if brought into the public
              domain could affect my suitability for appointment or bring the legal profession or King's Counsel into disrepute.
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label class="d-block mb-5"><b>Form of authority</b></label>
            <label class="d-block mb-5">
              I authorise (the submission of the form being itself authority) King's Counsel Appointments Limited, to make such enquiries with the Bar Standards Board, the Solicitors Regulation Authority or any other professional regulatory body in England and Wales or elsewhere,
              and/or the Legal Ombudsman as they consider necessary regarding my professional disciplinary record and/or complaint information. I consent to the disclosure of any personal, special category or crime data by the Bar Standards Board and/or the Solicitors Regulation
              Authority or any other professional regulatory bodies that they hold in connection with any reports/allegations about me to King's Counsel Appointments Ltd.
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <label>Signature of the applicant<span>&nbsp;*</span></label>
            </div>

            <div class="col-sm-4">
              <input type="text" readonly class="form-control" #Signature="ngModel" required id="Signature" name="Signature" [(ngModel)]="data.Signature" [ngClass]="{'is-invalid': Signature?.invalid && showMessage}" />
              <div class="invalid-tooltip" *ngIf="Signature?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <label>Date signed<span>&nbsp;*</span></label>
            </div>
            <div class="col-sm-4">
              <input type="text" readonly class="form-control" #SignatureDate="ngModel" required id="SignatureDate" name="SignatureDate" [(ngModel)]="data.SignatureDate" [ngClass]="{'is-invalid': SignatureDate?.invalid && showMessage}" />
              <div class="invalid-tooltip" *ngIf="SignatureDate?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label class="d-block mb-5"><b>Submission</b><br /></label>
            <label class="d-block mb-5">
              By submitting this application form you're confirming that the information provided is correct? Once submitting you will no longer be able to access the online application form.
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label class="d-block mb-5"><b>Fee payment</b></label>
            <label class="d-block mb-5">
              Your application must be accompanied by the fee. The fee is £2,160 (£1,800 + VAT) except for those with low incomes for whom a concessionary fee of £1,080 (£900 + VAT) applies.
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <label class="control-label" for="Payment method ">Payment method<span>&nbsp;*</span></label>
            </div>
            <div class="col-sm-4">
              <ng-select [items]="data.ApplicationPaymentTypeIds" required #ApplicationPaymentTypeId="ngModel" id="ApplicationPaymentTypeId" name="ApplicationPaymentTypeId" bindLabel="LookupValue" bindValue="LookupId" [(ngModel)]="data.ApplicationPaymentTypeId" [ngClass]="{'is-invalid': ApplicationPaymentTypeId?.invalid && showMessage}"></ng-select>
              <div class="invalid-tooltip" *ngIf="ApplicationPaymentTypeId?.errors?.required && showMessage">This field is required.</div>
            </div>
          </div>
        </div>

        <div *ngIf="(data.ApplicationPaymentTypeId == 2705)">
          <div class="form-group">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-5">
                  <label class="control-label" for="Payment method ">Payment Amount <span>&nbsp;*</span></label>
                </div>
                <div class="col-sm-4">
                  <ng-select [items]="data.PaymentAmountIds" required #PaymentAmount="ngModel" id="PaymentAmount" name="PaymentAmount" bindLabel="PaymentAmount" bindValue="LookupExtraDecimal" [(ngModel)]="data.PaymentAmount" [ngClass]="{'is-invalid': PaymentAmount?.invalid && showMessage}"></ng-select>
                  <div class="invalid-tooltip" *ngIf="PaymentAmount?.errors?.required && showMessage">This field is required.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <label class="d-block mb-5"><b>Debit card</b></label>
              <label class="d-block mb-0">
                Following submission of this application form you will be taken to the WorldPay website to complete payment.
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="(data.ApplicationPaymentTypeId == 2706)">
          <div class="row">
            <div class="col-sm-12">
              <label class="d-block mb-5"><b>Electronic Bank Payment</b></label>
              <label class="d-block mb-0">
                Payment Details
              </label>
              <label class="d-block mb-0">
                Bank Name:                Child & Co  <br />
                Account Name:             KC Appointments <br />
                Sort Code:                15-80-00  <br />
                Account number:           10578135  <br />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-print-2 pl-0 cardPagin">
            <p class="required">* denotes required fields </p>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-2 col-print-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-sm-10 col-print-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-3">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 col-print-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-sm-9 col-print-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName">
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3 col-print-4 cardPagin text-right">
            <label><p><span>Page </span><span>52</span> of <span>52</span></p></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Page 52-->
</form>

<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page3Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body">
      <label>
        Please indicate here your main specialist fields of work.<span class="required">&nbsp;*</span>
        <small>
          (Do not indicate a specialism unless it represents at least 5% of your work, total must equal 100%)
        </small>
      </label>

      <div class="form-card">
        <div class="row align-items-start">
          <div class="col-lg-3 col-md-6 order-1">
            <div class="form-group" *ngFor="let ApplicationSpecialism of data.ApplicationSpecialisms | slice:0:breakCount; let i = index;">
              <div class="row">
                <div class="col-lg-7 col-xl-8 pr-0">
                  <label class="control-label" for="{{i+1}}">{{ApplicationSpecialism.FieldOfPractice}}</label>
                </div>
                <div class="col-lg-5 col-xl-4">
                  <div class="input-group">
                    <input class="form-control input-md" (change)="changeValueEvent($event, ApplicationSpecialism.FieldOfPracticeId)" #SpecialismPercent="ngModel" aria-label="Please do not exceed 100%" id="{{i+1}}-1" name="{{i+1}}-1" type="number" min="5" max="100" maxlength="3" step="1" digits pasteblock onkeypress="return !(event.charCode == 46)" [(ngModel)]="ApplicationSpecialism.SpecialismPercent" [ngClass]="{'is-invalid': SpecialismPercent.invalid}">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6  order-2">
            <div class="form-group" *ngFor="let ApplicationSpecialism of data.ApplicationSpecialisms | slice:breakCount:breakCount*2; let i = index">
              <div class="row">
                <div class="col-lg-7 col-xl-8 pr-0">
                  <label class="control-label" for="{{i+1}}">{{ApplicationSpecialism.FieldOfPractice}}</label>
                </div>
                <div class="col-lg-5 col-xl-4">
                  <div class="input-group">
                    <input class="form-control input-md" (change)="changeValueEvent($event, ApplicationSpecialism.FieldOfPracticeId)" #SpecialismPercent="ngModel" ng-pattern="/^-?\d*$/" aria-label="Please do not exceed 100%" id="{{i+1}}-2" name="{{i+1}}-2" type="number" min="5" max="100" digits pasteblock onkeypress="return !(event.charCode == 46)" maxlength="3" step="1" [(ngModel)]="ApplicationSpecialism.SpecialismPercent" [ngClass]="{'is-invalid': SpecialismPercent.invalid}">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6  order-3">
            <div class="form-group" *ngFor="let ApplicationSpecialism of data.ApplicationSpecialisms | slice:breakCount*2:breakCount*3; let i = index">
              <div class="row">
                <div class="col-lg-7 col-xl-8 pr-0">
                  <label class="control-label" for="{{i+1}}">{{ApplicationSpecialism.FieldOfPractice}}</label>
                </div>
                <div class="col-lg-5 col-xl-4">
                  <div class="input-group">
                    <input class="form-control input-md" (change)="changeValueEvent($event, ApplicationSpecialism.FieldOfPracticeId)" #SpecialismPercent="ngModel" ng-pattern="/^-?\d*$/" aria-label="Please do not exceed 100%" id="{{i+1}}-3" name="{{i+1}}-3" type="number" min="5" max="100" digits pasteblock onkeypress="return !(event.charCode == 46)" maxlength="3" step="1" [(ngModel)]="ApplicationSpecialism.SpecialismPercent" [ngClass]="{'is-invalid': SpecialismPercent.invalid}">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6  order-4">
            <div class="form-group" *ngFor="let ApplicationSpecialism of data.ApplicationSpecialisms | slice:breakCount*3; let i = index">
              <div class="row">
                <div class="col-lg-7 col-xl-8 pr-0">
                  <label class="control-label" for="{{i+1}}">{{ApplicationSpecialism.FieldOfPractice}}</label>
                </div>
                <div class="col-lg-5 col-xl-4">
                  <div class="input-group">
                    <input class="form-control input-md" (change)="changeValueEvent($event, ApplicationSpecialism.FieldOfPracticeId)" (keyup)="showOtherSpecialism()" #SpecialismPercent="ngModel" aria-label="Please do not exceed 100%" id="{{i+1}}-4" ng-pattern="/^-?\d*$/" name="{{i+1}}-4" type="number" min="5" max="100" maxlength="3" step="1" digits pasteblock onkeypress="return !(event.charCode == 46)" [(ngModel)]="ApplicationSpecialism.SpecialismPercent" [ngClass]="{'is-invalid': SpecialismPercent?.invalid}">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-start">
          <div class="col-lg-3 col-md-6 offset-lg-9">
            <div class="form-group">
              <div class="total-page-3">
                <div class="row">
                  <div class="col-lg-7 col-xl-8">
                    <label class="control-label" for="Total">Total (must equal 100%)</label>
                  </div>

                  <div class="col-lg-5 col-xl-4">
                    <div class="input-group">
                      <input class="form-control input-md" aria-label="Please do not exceed 100%" #Total="ngModel" ng-pattern="/^-?\d*$/" id="Total" name="Total" readonly type="number" min="0" max="100" [max]="100" maxlength="3" step="1" digits pasteblock onkeypress="return !(event.charCode == 46)" [(ngModel)]="data.Total" [ngClass]="{'is-invalid': Total?.errors?.max}">
                      <div class="input-group-prepend height-40">
                        <div class="input-group-text">%</div>
                      </div>
                      <div class="invalid-tooltip" *ngIf="Total.errors?.max">
                        Total Must Equal 100%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="showSpecialisms">
          <div class='col-lg-12'>
            <div class="textarea-form">
              <label class="control-label mb-0" for="admin_law">Other specialisms <span *ngIf="showSpecialisms">*</span></label>
              <textarea required maxlength="300" class="form-control input-md mb-0" #OtherSpecialism="ngModel" id="OtherSpecialism" [required]="showSpecialisms" name="OtherSpecialism" [(ngModel)]="data.OtherSpecialism" autosize [minRows]="5" [maxLength]="300"></textarea>
              <div class="invalid-tooltip textarea-tooltip" *ngIf="OtherSpecialism?.errors?.required && showMessage">This field is required.</div>
              <span class="float-right label label-default" id="counter">{{data.OtherSpecialism ? data.OtherSpecialism.length : 0}} / 300</span>
              <small class="label-inline">Please note there is a limit of 300 characters for this box</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer form-card">
      <div class="row">
        <div class="col-md-12 pl-0">
          <p class="required">* denotes required fields </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-2">
                <label for="ID">ID:</label>
              </div>
              <div class="col-md-10">
                <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="Surname">Surname:</label>
              </div>
              <div class="col-md-9">
                <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName" [maxlength]="50">
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-auto pr-0 cardPagin">
          <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page3Form.dirty)"><i class="fas fa-chevron-left" style="font-size: 16px;"></i></button>
          <label class="pagination-label" for="Surname">Page</label>
          <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page3Form.dirty)">
            <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
          </select>
          <p>of <span>{{pages.length}}</span></p>
          <button type="button" class="btn btn-primary float-left" (click)="goToNext(page3Form.dirty)"><i class="fas fa-chevron-right" style="font-size: 16px;"></i></button>
        </div>
        <div class="col-auto text-right ml-auto pl-0">
          <button type="submit" [ladda]="isSaving" class="btn btn-primary save">Save</button>
          <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
        </div>
      </div>
    </div>
  </div>
</form>

import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './auth.guard';
import { SubmittedGuard } from './shared/guard/submitted.guard';
import { ClosedComponent } from '../app/closed/closed.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canMatch: [AuthGuard, SubmittedGuard],
        data: {
          applicationSubmitted: false
        }
      },
       {
        path: 'application',
        loadChildren: () =>
           import('./application/application.module').then(
             (m) => m.ApplicationModule
           ),
         canMatch: [AuthGuard, SubmittedGuard],
         data: {
           applicationSubmitted: false,
         },
       },
       {
         path: 'worldpay',
         loadChildren: () =>
           import('./worldpay/worldpay.module').then(
             (m) => m.WorldPayModule
           ),
         canMatch: [AuthGuard, SubmittedGuard],
         data: {
           applicationSubmitted: true,
         },
       },
       {
         path: 'user',
         loadChildren: () =>
           import('./user/user.module').then((m) => m.UserModule),
         canMatch: [AuthGuard],
       },
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/authentication/404',
  },
];

export const ClosedRoute: Routes = [
  {
    path: '',
    component: ClosedComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

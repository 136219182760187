import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Progress',
    class:'',
    icon: 'fa fa-tachometer-alt',
    extralink: false,
    submitted: false,
    submenu: []
  },
  {
    path: '/application',
    title: 'Open Application',
    class: '',
    icon: 'fa fa-copy',
    extralink: false,
    submitted: false,
    submenu: []
  },
  {
    path: '/worldpay',
    title: 'Payment',
    class: '',
    icon: 'fa fa-pound-sign',
    extralink: false,
    submitted: true,
    submenu: []
  },
  {
    path: '/worldpay/final-payment',
    title: 'Final Payment',
    class: '',
    icon: 'fa fa-pound-sign',
    extralink: false,
    submitted: null,
    submenu: []
  }
];

import { Injectable, PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: 'search'
})
@Injectable()
export class SearchPipe implements PipeTransform {
  transform(items: any[], field: string, value: string) {
    if (!items) return [];
    if (value && field) {
      return items.filter(item => item[field] === value);
    }
    else {
      return items;
    } 
  }
}

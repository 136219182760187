<ngx-loading [show]="(data | json) == '{}'"></ngx-loading>
<form [hidden]="(data | json) == '{}'" #page2Form="ngForm" (ngSubmit)="save()">
  <div class="card custom-card">
    <div class="card-body form-card">
      <div class="form-group">
        <div class="row">
          <div class="col-md-8 col-xl-6">
            <label class="control-label" for="ProfessionId">What is your current branch of the profession?<span>&nbsp;*</span></label>
          </div>

          <div class='col-md-4 col-xl-2'>
            <div class="input-group">
              <ng-select required [items]="professions" id="ProfessionId" name="ProfessionId" #ProfessionId="ngModel" bindLabel="LookupValue" bindValue="LookupId" [ngClass]="{'is-invalid': ProfessionId?.invalid && showMessage}" [(ngModel)]="data.ProfessionId"></ng-select>
              <div class="invalid-tooltip" *ngIf="ProfessionId?.errors?.required && showMessage"> 
                This field is Required.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-8 col-xl-6">
            <label class="control-label label-inline" for="BarDate">Date of call or admission into current branch of the profession<span>&nbsp;*</span></label>
          </div>

          <div class='col-md-4 col-xl-2'>
            <div class="input-group" (clickOutside)="BarDatePicker.close()">
              <input class="form-control" #BarDate="ngModel" #BarDatePicker="ngbDatepicker" required id="BarDate" name="BarDate" [(ngModel)]="BarDateField" ngbDatepicker [ngClass]="{'is-invalid': BarDate?.invalid && showMessage}">
              <div class="input-group-append">
                <button class="btn" (click)="BarDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
              </div>
              <div class="invalid-tooltip" *ngIf="BarDate?.errors?.required && showMessage">This field is Required.</div>
              <div class="invalid-tooltip" *ngIf="BarDateField && BarDate?.invalid">Please enter date in (dd/mm/yyyy).</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-8 col-xl-6">
            <label class="control-label label-inline" for="PreviousBarDate">
              If you were previously called or admitted to a
              different branch (barrister or solicitor) in England and Wales - please give the date of call or
              admission to your former profession
            </label>
          </div>

            <div class='col-md-4 col-xl-2'>
              <div class="input-group" (clickOutside)="PreviousBarDatePicker.close()">
                <input class="form-control" #PreviousBarDate="ngModel" #PreviousBarDatePicker="ngbDatepicker" id="PreviousBarDate" name="PreviousBarDate" [(ngModel)]="PreviousBarDateField" ngbDatepicker  [ngClass]="{'is-invalid': PreviousBarDate?.invalid && showMessage}">
                <div class="input-group-append">
                  <button class="btn" (click)="PreviousBarDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                </div>
                <div class="invalid-tooltip" *ngIf="PreviousBarDateField && PreviousBarDate?.invalid">Please enter date in (dd/mm/yyyy).</div>
              </div>
          </div>
        </div>
      </div>

      <div class="form-group mb-0">
        <div class="row">
          <div class="col-md-8 col-xl-6">
            <label class="control-label mb-0" for="IsEmployed">
              Are you an employed barrister or in-house solicitor?
            </label>
          </div>

          <div class='col-md-4 col-xl-2'>
            <div class="form-check">
              <pb-switch class="required-denote custom-switch mb-0" #IsEmployed="ngModel" id="IsEmployed" [(ngModel)]="data.IsEmployed" name="IsEmployed" labelOn="Yes" labelOff="No"></pb-switch>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="data.ProfessionId == 326">
        <div class="row">
          <div class="col-md-8 col-xl-6">
            <label class="control-label" for="InnOfCourtId">Which Inn of Court are you a member of?<span>&nbsp;*</span></label>
          </div>

          <div class='col-md-4 col-xl-2'>
            <div class="input-group">
              <ng-select [items]="InnCourts" #InnOfCourtId="ngModel" id="InnOfCourtId" required name="InnOfCourtId" bindLabel="LookupValue" bindValue="LookupId" [ngClass]="{'is-invalid': InnOfCourtId?.invalid && showMessage}" [(ngModel)]="data.InnOfCourtId"></ng-select>
              <div class="invalid-tooltip" *ngIf="InnOfCourtId?.errors?.required && showMessage">
                This field is Required.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf=false >

          <div class="form-group">
            <div class="row">
              <div class='col-lg-12'>
                <label class="control-label label-inline" for="HighCourtCivilDate">
                  Please give the date on which you attained each of your higher courts qualification(s) under the Courts and Legal Services Act of 1990
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-8 col-xl-6">
                <label class="control-label" for="HighCourtCivilDate">Higher Courts (Civil)<span>&nbsp;*</span></label>
              </div>

              <div class='col-md-4 col-xl-2' (clickOutside)="HighCourtCivilDatePicker.close()">
                <div class="input-group">
                  <input class="form-control" #HighCourtCivilDate="ngModel" #HighCourtCivilDatePicker="ngbDatepicker" required id="HighCourtCivilDate"
                         name="HighCourtCivilDate" [(ngModel)]="HighCourtCivilDateField" ngbDatepicker [ngClass]="{'is-invalid': HighCourtCivilDate?.invalid && showMessage}">
                  <div class="input-group-append">
                    <button class="btn" (click)="HighCourtCivilDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtCivilDate?.errors?.required && showMessage">
                    This field is Required.
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtCivilDate?.invalid && showMessage">Please enter date in (dd/mm/yyyy).</div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label class="control-label" for="HighCourtCriminalDate">Higher Courts (Criminal)<span>&nbsp;*</span></label>
              </div>

              <div class='col-md-2'>
                <div class="input-group" (clickOutside)="HighCourtCriminalDatePicker.close()">
                  <input class="form-control" #HighCourtCriminalDate="ngModel" #HighCourtCriminalDatePicker="ngbDatepicker" required id="HighCourtCriminalDate"
                         name="HighCourtCriminalDate" [(ngModel)]="HighCourtCriminalDateField" ngbDatepicker [ngClass]="{'is-invalid': HighCourtCriminalDate?.invalid && showMessage}">
                  <div class="input-group-append">
                    <button class="btn" (click)="HighCourtCriminalDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtCriminalDate?.errors?.required && showMessage">
                    This field is Required.
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtCriminalDate?.invalid && showMessage">Please enter date in (dd/mm/yyyy).</div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label class="control-label" for="HighCourtAllDate">Higher Courts (All)<span>&nbsp;*</span></label>
              </div>

              <div class='col-md-2'>
                <div class="input-group" (clickOutside)="HighCourtAllDatePicker.close()">
                  <input class="form-control" #HighCourtAllDate="ngModel" #HighCourtAllDatePicker="ngbDatepicker" required id="HighCourtAllDate"
                         name="HighCourtAllDate" [(ngModel)]="HighCourtAllDateField" ngbDatepicker [ngClass]="{'is-invalid': HighCourtAllDate?.invalid && showMessage}">
                  <div class="input-group-append">
                    <button class="btn" (click)="HighCourtAllDatePicker.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtAllDate?.errors?.required && showMessage">
                    This field is Required.
                  </div>
                  <div class="invalid-tooltip" *ngIf="HighCourtAllDate?.invalid && showMessage">Please enter date in (dd/mm/yyyy).</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label class="mb-15 d-block">
              Geographical area of practice<span class="required">&nbsp;*</span>
              <small class="d-block" for="Geographical">
                (Please enter below the approximate percentages of 5% or over of your working time spent in each geographical area.)
              </small>
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-8">
            <div class="form-group">
              <div class="row">
                <div class='col-md-4 col-lg-4'>
                  <div class="row">
                    <div class="col-md-7 col-xl-6 ">
                      <label class="control-label" for="PracticeAreaLondon">London </label>
                    </div>

                    <div class="col-md-5">
                      <div class="input-group">
                        <input class="form-control input-md" (focusout)="onKeyup($event, 'London', 'PracticeAreaLondon')" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaLondon="ngModel" id="PracticeAreaLondon" name="PracticeAreaLondon" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaLondon">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-md-4 col-lg-4'>
                  <div class="row">
                    <div class="col-md-7 col-xl-6 offset-xl-1">
                      <label class="control-label" for="PracticeAreaNorthWest">North West </label>
                    </div>

                    <div class="col-md-5">
                      <div class="input-group">
                        <input class="form-control input-md" (focusout)="onKeyup($event, 'North West', 'PracticeAreaNorthWest')" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaNorthWest="ngModel" id="PracticeAreaNorthWest" name="PracticeAreaNorthWest" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaNorthWest">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-md-4 col-lg-4'>
                  <div class="row">
                    <div class="col-md-7 col-xl-6 offset-xl-1">
                      <label class="control-label" for="PracticeAreaSouthEast">South East </label>
                    </div>

                    <div class="col-md-5">
                      <div class="input-group">
                        <input class="form-control input-md" (focusout)="onKeyup($event, 'South East', 'PracticeAreaSouthEast')" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaSouthEast="ngModel" id="PracticeAreaSouthEast" name="PracticeAreaSouthEast" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaSouthEast">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class='col-md-4 col-lg-4'>
                  <div class="row">
                    <div class="col-md-7 col-xl-6">
                      <label class="control-label" for="PracticeAreaSouthWest">South West </label>
                    </div>

                    <div class="col-md-5">
                      <div class="input-group">
                        <input class="form-control input-md" (focusout)="onKeyup($event, 'South West', 'PracticeAreaSouthWest')" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaSouthWest="ngModel" id="PracticeAreaSouthWest" name="PracticeAreaSouthWest" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaSouthWest">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-md-4 col-lg-4'>
                  <div class="row">
                    <div class="col-md-7 col-xl-6 offset-xl-1">
                      <label class="control-label" for="PracticeAreaMidlands">Midlands </label>
                    </div>

                    <div class="col-md-5">
                      <div class="input-group">
                        <input class="form-control input-md" (focusout)="onKeyup($event, 'Midlands', 'PracticeAreaMidlands')" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaMidlands="ngModel" id="PracticeAreaMidlands" name="PracticeAreaMidlands" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaMidlands">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-md-4 col-lg-4'>
                  <div class="row">
                    <div class="col-md-7 col-xl-6 offset-xl-1">
                      <label class="control-label" for="PracticeAreaWales">Wales </label>
                    </div>

                    <div class="col-md-5">
                      <div class="input-group">
                        <input class="form-control input-md" (focusout)="onKeyup($event, 'Wales', 'PracticeAreaWales')" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaWales="ngModel" id="PracticeAreaWales" name="PracticeAreaWales" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaWales">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class='col-md-4 col-lg-4'>
                  <div class="row">
                    <div class="col-md-7 col-xl-6">
                      <label class="control-label" for="PracticeAreaNorthEast">North East </label>
                    </div>

                    <div class="col-md-5">
                      <div class="input-group">
                        <input class="form-control input-md" (focusout)="onKeyup($event, 'North East', 'PracticeAreaNorthEast')" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaNorthEast="ngModel" id="PracticeAreaNorthEast" name="PracticeAreaNorthEast" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaNorthEast">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-md-4 col-lg-4'>
                  <div class="row">
                    <div class="col-md-7 col-xl-6 offset-xl-1">
                      <label class="control-label" for="PracticeAreaEurope">Outside England and Wales </label>
                    </div>

                    <div class="col-md-5">
                      <div class="input-group">
                        <input class="form-control input-md" (focusout)="onKeyup($event, 'Outside England and Wales', 'PracticeAreaEurope')" (change)="sumTotalRegion()" digits pasteblock onkeypress="return !(event.charCode == 46)" #PracticeAreaEurope="ngModel" id="PracticeAreaEurope" name="PracticeAreaEurope" type="number" min="5" max="100" step="1" [(ngModel)]="data.PracticeAreaEurope">
                        <div class="input-group-prepend">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class='col-md-4 col-lg-4'>
                  <div class="">
                    <div class="row">
                      <div class="col-md-7 col-xl-6 offset-xl-1">
                        <label class="control-label pt-10" for="TotalRegion">Total (must equal 100%)</label>
                      </div>

                      <div class="col-md-5">
                        <div class="input-group">
                          <input class="form-control input-md" readonly digits pasteblock onkeypress="return !(event.charCode == 46)" #TotalRegion="ngModel" id="TotalRegion" name="TotalRegion" type="number" min="0" max="100" [max]="100" step="1" [(ngModel)]="data.TotalRegion" [ngClass]="{'is-invalid': TotalRegion?.errors?.max}">
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                          <div class="invalid-tooltip" *ngIf="TotalRegion.errors?.max">
                            Total Must Equal 100%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tooltip-group">

          <div class="row">
            <div class="col-md-12">
              <label class="mb-15">
                Broad field of practice<span class="required">&nbsp;*</span>
                  <small for="broad_fields" class="d-block">
                    (Please select an answer for each broad field of practice of law in which you have demonstrated excellence)
                  </small>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-8">
              <div class="row">
                <div class='col-md-4'>
                  <div class="form-check float-right mr-0">
                    <label>Criminal Law </label>
                    <pb-switch class="required-denote custom-switch" #BroadFieldCriminal="ngModel" id="BroadFieldCriminal" [(ngModel)]="data.BroadFieldCriminal" name="BroadFieldCriminal"></pb-switch>
                  </div>
                </div>

                <div class='col-md-4'>
                  <div class="form-check float-right mr-0">
                    <label>Family Law</label>
                    <pb-switch class="required-denote custom-switch" #BroadFieldFamily="ngModel" id="BroadFieldFamily" [(ngModel)]="data.BroadFieldFamily" name="BroadFieldFamily"></pb-switch>
                  </div>
                </div>

                <div class='col-md-4'>
                  <div class="form-check float-right mr-0">
                    <label>Civil Law</label>
                    <pb-switch class="required-denote custom-switch" #BroadFieldCivil="ngModel" id="BroadFieldCivil" [(ngModel)]="data.BroadFieldCivil" name="BroadFieldCivil"></pb-switch>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class='col-md-4 offset-md-5 required'>
                  <small>Please select a minimum of one of the above options</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer form-card">
        <div class="row">
          <div class="col-md-12 pl-0">
            <p class="required">* denotes required fields </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-2 col-sm-6 col-12">
            <div class="form-group">
              <div class="row">
                <div class="col-md-2">
                  <label for="ID">ID:</label>
                </div>

                <div class="col-md-10">
                  <input type="text" id="ID" name="ID" readonly class="form-control" [(ngModel)]="data.Reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6 col-12">
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label for="Surname">Surname:</label>
                </div>

                <div class="col-md-9">
                  <input type="text" id="Surname" name="Surname" readonly class="form-control" [(ngModel)]="data.LastName" [maxlength]="50">
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-auto pr-0 cardPagin">
            <button type="button" class="btn btn-primary float-left" (click)="goToPrevious(page2Form.dirty)">
              <i class="fas fa-chevron-left" style="font-size: 16px;"></i>
            </button>
            <label class="pagination-label" for="Surname">Page</label>
            <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageNo" (change)="onPageChange($event, page2Form.dirty)">
              <option *ngFor="let page of pages" value="{{page}}">{{page}}</option>
            </select>
            <p>
              of
              <span>{{pages.length}}</span>
            </p>
            <button type="button" class="btn btn-primary float-left" (click)="goToNext(page2Form.dirty)">
              <i class="fas fa-chevron-right" style="font-size: 16px;"></i>
            </button>
          </div>

          <div class="col-auto text-right ml-auto pl-0">
            <button type="submit" [ladda]="isSaving" [disabled]="(BarDateField && BarDate?.invalid) || (PreviousBarDateField && PreviousBarDate?.invalid)" class="btn btn-primary save">Save</button>
            <button type="button" (click)="openGuidanceNotes()" class="btn btn-primary">Guidance Extract</button>
            <button class="btn btn-info" type="button" (click)="downloadApplicationForm()"><i _ngcontent-c11="" class="fa fa-file-pdf"></i> Create PDF</button>
          </div>
        </div>
      </div>
    </div>
</form>


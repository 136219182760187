export enum PageHeadings {
  page1 = 'Section A: Personal Details',
  page2 = 'Section B: Professional Details',
  page3 = 'Section B: Professional Details (continued)',
  page4 = 'Section C: Character and Conduct',
  page5 = 'Section D: Information on Selection Panel members',
  page6 = 'Section E: Summary description of practice',
  page7 = 'Section F: Self-assessment: Competency A, Understanding and using the Law',
  page8 = 'Section F: Self-assessment: Competency B1, Written Advocacy',
  page9 = 'Section F: Self-assessment: Competency B2, Oral Advocacy',
  page10 = 'Section F: Self-assessment: Competency C, Working with Others',
  page11 = 'Section F: Self-assessment: Competency D, Diversity Action & Understanding',
  page12 = 'Section F: Self-assessment: Competency E, Integrity',
  page13 = 'Section G: Other information',
  page14 = 'Section H: 12 Important cases since 2021',
  page15 = 'Section I: Assessor - Judge',
  page16 = 'Section I: Assessor - Practitioner',
  page17 = 'Section I: Assessor - Client',
  page51 = 'Section J: Monitoring information',
  page52 = 'Section K: Forms of authority and declaration'
};

export let Pages: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];

class Cases {
  Case1J = {
    AssessorReference: 'J01',
    OrderNo: 1
  };
  Case2J = {
    AssessorReference: 'J02',
    OrderNo: 2
  };
  Case3J = {
    AssessorReference: 'J03',
    OrderNo: 3
  };
  Case4J = {
    AssessorReference: 'J04',
    OrderNo: 4
  };
  Case5J = {
    AssessorReference: 'J05',
    OrderNo: 5
  };
  Case6J = {
    AssessorReference: 'J06',
    OrderNo: 6
  };
  Case7J = {
    AssessorReference: 'J07',
    OrderNo: 7
  };
  Case8J = {
    AssessorReference: 'J08',
    OrderNo: 8
  };
  Case9J = {
    AssessorReference: 'J09',
    OrderNo: 9
  };
  Case10J = {
    AssessorReference: 'J10',
    OrderNo: 10
  };
  Case11J = {
    AssessorReference: 'J11',
    OrderNo: 11
  };
  Case12J = {
    AssessorReference: 'J12',
    OrderNo: 12
  };
  Case1P = {
    AssessorReference: 'P01',
    OrderNo: 1
  };
  Case2P = {
    AssessorReference: 'P02',
    OrderNo: 2
  };
  Case3P = {
    AssessorReference: 'P03',
    OrderNo: 3
  };
  Case4P = {
    AssessorReference: 'P04',
    OrderNo: 4
  };
  Case5P = {
    AssessorReference: 'P05',
    OrderNo: 5
  };
  Case6P = {
    AssessorReference: 'P06',
    OrderNo: 6
  };
  Case7P = {
    AssessorReference: 'P07',
    OrderNo: 7
  };
  Case8P = {
    AssessorReference: 'P08',
    OrderNo: 8
  };
  Case9P = {
    AssessorReference: 'P09',
    OrderNo: 9
  };
  Case10P = {
    AssessorReference: 'P10',
    OrderNo: 10
  };
  Case11P = {
    AssessorReference: 'P11',
    OrderNo: 11
  };
  Case12P = {
    AssessorReference: 'P12',
    OrderNo: 12
  };
  Case1C = {
    AssessorReference: 'C01',
    OrderNo: 1
  };
  Case2C = {
    AssessorReference: 'C02',
    OrderNo: 2
  };

  Case3C = {
    AssessorReference: 'C03',
    OrderNo: 3
  };
  Case4C = {
    AssessorReference: 'C04',
    OrderNo: 4
  };
  Case5C = {
    AssessorReference: 'C05',
    OrderNo: 5
  };

  Case6C = {
    AssessorReference: 'C06',
    OrderNo: 6
  };
  Case7C = {
    AssessorReference: 'C07',
    OrderNo: 7
  };
  Case8C = {
    AssessorReference: 'C08',
    OrderNo: 8
  };
  Case9C = {
    AssessorReference: 'C09',
    OrderNo: 9
  };
  Case10C = {
    AssessorReference: 'C10',
    OrderNo: 10
  };
  Case11C = {
    AssessorReference: 'C11',
    OrderNo: 11
  };
  Case12C = {
    AssessorReference: 'C12',
    OrderNo: 12
  };
}
export let ListOfCases = new Cases();



